import React from 'react'

import {
  Row,
  Col,
} from 'react-bootstrap'

import {
  Normal,
  Bold,
} from '../atoms/Fonts'
import {
  Link,
} from '../atoms/Links'
import strings from '../../lib/i18n'
import styling from '../../lib/styling'
import utils from '../../lib/utils'
import { Longbutton } from '../atoms/Buttons'


const LabelValuePair = ({
  label,
  value,
  fallbackValue = strings.na,
  isLink = false,
  labelColor = styling.colors.grey,
  isDate = false,
  editAction,
  actionButton,
}) => {
  const text = isDate ? utils.dateTime.formatDateTime(utils.dateTime.cleanDate(value)) : value
  return <Row>
    <Col sm={4}>
      <Bold color={labelColor}>{label}</Bold>
    </Col>
    {
      actionButton ?
        <Col>
          <Longbutton
            narrowButton
            onClick={actionButton.action}
            style={{
              width: '100px'
            }}
          >
            {actionButton.label}
          </Longbutton>
        </Col>
        :
        <>
          <Col
            style={{
              overflowWrap: 'break-word',
            }}
          >
            {
              isLink ?
                <Link to={value}>{text}</Link>
                :
                <Normal>{text || fallbackValue}</Normal>
            }
          </Col>
          {
            editAction && <Col
              sm={1}
            >
              <Link
                onClick={editAction}
              >
                {strings.edit}
              </Link>
            </Col>
          }
        </>
    }
  </Row>
}

export default LabelValuePair
