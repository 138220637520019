import React from 'react'

import {
  Carousel,
} from 'react-bootstrap'

import img1 from '../../assets/images/emme-home-1.jpg'
import img2 from '../../assets/images/emme-home-2.jpg'
import img3 from '../../assets/images/emme-home-3.jpg'



const Fallback = () => {

  return <Carousel>
    <Carousel.Item interval={1000}>
      <img
        className="d-block w-100"
        src={img1}
        alt="First slide"
      />
    </Carousel.Item>
    <Carousel.Item interval={1000}>
      <img
        className="d-block w-100"
        src={img2}
        alt="Third slide"
      />
    </Carousel.Item>
    <Carousel.Item interval={1000}>
      <img
        className="d-block w-100"
        src={img3}
        alt="Third slide"
      />
    </Carousel.Item>
  </Carousel>
}

export default Fallback
