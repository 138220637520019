import React from 'react'

import styled, { css } from 'styled-components'

import selectedSrc from '../../assets/images/simpleIcons/icon-radio-selected.png'
import unselectedSrc from '../../assets/images/simpleIcons/icon-radio-unselected.png'
import moon1Src from '../../assets/images/icon-moon-1.png'
import moon2Src from '../../assets/images/icon-moon-2.png'
import moon3Src from '../../assets/images/icon-moon-3.png'
import moon4Src from '../../assets/images/icon-moon-4.png'

import i18n from '../../lib/i18n'
import styling from '../../lib/styling'
import { Normal } from './Fonts'

const Container = styled.div`
  margin-top: 15px;
  width: 100%;
`
const Item = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 3px;
  ${props => props.isError && css`
    border: 1px solid ${styling.colors.wine};
  `}
`

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
  }
`

const RadioButtons = ({
  onSelect,
  options = [],
  selectedKey,
  style = {},
  useMoons = false,
  errorColor,
}) => {
  const moonSrcs = [moon1Src, moon2Src, moon4Src, moon3Src]
  return <Container
    style={style}
  >
    {
      options.map((option, idx) => {
        const {
          text,
          value,
          key,
          isError,
        } = option || {}
        const isSelected = selectedKey === value
        const moonSrc = moonSrcs[idx % moonSrcs.length]
        return <Item
          key={key || text}
          onClick={() => onSelect(value || option)}
          isError={isError}
          style={{
            ...isError && errorColor ?
            { borderColor: errorColor } : {}
          }}
        >
          {
            isSelected ?
              <Icon src={useMoons ? moonSrc : selectedSrc} alt={i18n.image.radio_selected} />
              :
              <Icon src={unselectedSrc} alt={i18n.image.radio_unselected} />
          }

          <Normal>{text || options}</Normal>
        </Item>
      })
    }
  </Container>
}

export default RadioButtons
