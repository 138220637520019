import constants from '../constants'

const {
  TAB_EMME_RX,
  TAB_INTERNAL_USER,
} = constants.owner
const {
  RIGHTS_OWNER,
  RIGHTS_CUSTOMER_SUPPORT,
  // RIGHTS_MARKETING,
} = constants.support

export default {
  owner_tools: 'Owner Tools',
  internal_user: 'Internal User Management',
  emme_rx: 'EmmeRx Management',
  [`tab_${TAB_INTERNAL_USER}`]: 'Internal User',
  [`tab_${TAB_EMME_RX}`]: 'EmmeRX',
  [`right-${RIGHTS_OWNER}`]: 'Owner',
  [`right-${RIGHTS_CUSTOMER_SUPPORT}`]: 'Customer Support',
  // [`right-${RIGHTS_MARKETING}`]: 'Marketing',
  [`right-${RIGHTS_OWNER}-descr`]: 'Sees all tabs and can manage internal users.',
  [`right-${RIGHTS_CUSTOMER_SUPPORT}-descr`]: 'Sees Customer data and RX Delivery tasks and reports',
  // [`right-${RIGHTS_MARKETING}-descr`]: 'Sees Marketing tools and RX Delivery tasks and reports',
}
