export default {
  EMAIL: 'email',
  UID: 'uid',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL_VERIFIED: 'emailVerified',
  DAYS: 'days',
  PACKS: 'packs',
  RX_DELIVERY: 'rx_delivery',
  USER_STATE: 'userState',
  SETTINGS: 'settings',
  USER_MANAGEMENT: 'userManagement',
  TROUBLE_SHOOTING: 'troubleShooting',
  SUBSCRIPTION_HISTORY: 'subscriptionHistory',

  RIGHTS_OWNER: 'owner',
  RIGHTS_CUSTOMER_SUPPORT: 'customersupport',
  // RIGHTS_MARKETING: 'marketing',
  USER_RIGHTS: 'userRights',
  MIXPANEL: 'mixpanel',
  SHARE_LINK: 'shareLink',

  MODAL_CONFIRM_CHANGES: 'confirmChanges',
  RX_MODAL_CREDIT_CARD: 'creditCard',
  RX_MODAL_BILLING_ADDRESS: 'billingAddress',
  RX_MODAL_HOME_ADDRESS: 'homeAddress',
  RX_MODAL_SHIPPING_ADDRESS: 'shippingAddress',

  MODAL_MOVE_NEXT_ORDER: 'moveNextOrder',
  MODAL_FIX_SETTINGS: 'fixSettings',
  MODAL_CONFIRM_MEMBERSHIP_CANCELATION: 'confirmCancelMembership',
  MODAL_FETCH_PRESCRIPTION: 'fetchPrescription',
  MODAL_DELETE_ACCOUNT: 'deleteAccount',
  MODAL_CHANGE_EMAIL: 'changeEmail',


  // Delete account reason
  DELETE_REASON_DEFAULT: 'not shared',
  DELETE_REASON_SWITCH: 'switching apps',
  DELETE_REASON_NO_PILL: 'not on pill anymore',
  DELETE_REASON_DISSATISFIED: 'dissatisfied with app',
  DELETE_REASON_OTHER: 'other',


  SUBSCR_HIST_TYPE: 'type',
  SUBSCR_HIST_STORE: 'store',
  SUBSCR_HIST_EVENT_TIME: 'event_timestamp_ms',
  SUBSCR_HIST_PURCHASE_TIME: 'purchased_at_ms',
  SUBSCR_HIST_TRANSACTION: 'transaction_id',
  SUBSCR_HIST_PERIOD: 'period_type',
  SUBSCR_HIST_PRICE: 'price',
  SUBSCR_HIST_TAKEHOME: 'takehome_percentage',
  SUBSCR_HIST_COMMISSION: 'commission_percentage',
  SUBSCR_HIST_TAX: 'tax_percentage',
  SUBSCR_HIST_OFFER_CODE: 'offer_code',
  SUBSCR_HIST_CURRENCY: 'currency',
  SUBSCR_HIST_COUNTRY: 'country_code',
}
