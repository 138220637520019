import React, { useState } from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap'
import TaskDetails, { UserProvider } from './TaskDetails'

const TaskCard = ({ task }) => {
	const [showDetails, setShowDetails] = useState(false);
	
	var performAt = new Date(task.performAt.seconds * 1000);
	var DetailsComponent = TaskDetails[task.type] || TaskDetails.unrecognizedType;
	return (
		<Card style={{width: '65rem'}}>
			<Card.Header>
				<Row>
					<Col>
						<Button variant="outline-primary" size="sm" onClick={() => { setShowDetails(!showDetails) }}>{showDetails ? "V" : ">"}</Button>
					</Col>
					<Col>
						{task.type}
					</Col>
					<Col>
						{task.status} {task.id}
					</Col>
					<Col className="text-right">
						{performAt.toLocaleString()}
					</Col>
				</Row>
			</Card.Header>
			{showDetails &&
				<Card.Body>
					<Card>
						<Card.Body>
							{ task.userID
								?
								<UserProvider userID={task.userID}>
									<DetailsComponent task={task} />
								</UserProvider>
								:
								<DetailsComponent task={task} />
							}
						</Card.Body>
					</Card>
				</Card.Body>
			}
		</Card>
	)
}

export default TaskCard
