import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Longbutton } from '../../atoms/Buttons'
import { actions } from '../../../store'
import i18n from '../../../lib/i18n'

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`

const oAuthUI = ({
  useGoogleOAuth,
  buttonText,
  meta,
}) => {

  return <Container>
    <Longbutton
      onClick={() => useGoogleOAuth(meta)}
    >
      {buttonText} {i18n.account.with_google}
    </Longbutton>
  </Container>
};

const oAuth = connect(
  state => ({

  }),
  dispatch => ({
    useGoogleOAuth: (meta) => actions.useGoogleOAuth(dispatch, meta),
  }),
)(oAuthUI)

export default oAuth
