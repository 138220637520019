import i18n from '../i18n'

const errorMsg = i18n.errors

export default {
  isRequired: {
    isRequired: true,
    error: errorMsg.required_field,
  },
  regex: (regex, msg) => ({
    regex,
    error: msg || errorMsg.incorrect_format,
  }),
  length: ({min, max, exact}, msg) => ({
    length: { min, max, exact },
    error: msg || errorMsg.error_length({min, max, exact})
  }),
  isType: (type, msg) => ({
    type,
    error: msg || errorMsg.type_error(type),
  }),
  isPhone: (msg) => ({
    phone: true,
    error: msg || errorMsg.phone,
  }),
  compareStringValue: (expected, msg) => ({
    compareString: expected,
    error: msg || errorMsg.string_compare,
  }),
  customCheck: (callback, msg) => ({
    customValidation: callback,
    error: msg || errorMsg.default_error,
  }),
}