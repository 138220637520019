export default {
  LOWERCASE: 'lowercase',
  UPPERCASE: 'uppercase',
  SPECIAL_CHAR: 'specialCharacter',
  DIGIT: 'digit',
  PW_MATCH: 'pwMatch',
  PW_CHANGED: 'pwChanged',
  LENGTH: 'length',
  MIN_LENGTH: 10,
  MAX_LENGTH: 64,
}
