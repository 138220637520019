import React, { useEffect } from 'react';
import './services/firebase'
import firebase from 'firebase/app'
import 'firebase/auth'
import RootRouter from './routes/RootRouter'
import { useSelector, useDispatch } from 'react-redux'
import { isLoaded, ReactReduxFirebaseProvider, isEmpty } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import { Provider } from 'react-redux'
import { Container, Spinner } from "react-bootstrap"
import { store, selectors } from './store'
import { listenToFirebaseChanges } from './store/actions/listener'
import {
	BrowserRouter,
	Switch,
	Route,
	Redirect,
	useLocation,
} from 'react-router-dom'

import MainContainer from './pages/MainContainer'
import Login from './pages/Login'
import AuthActions from './pages/AuthActions';

import utils from './lib/utils'
import routes from './routes/routes'
import './assets/fonts/Roboto-Regular.ttf'
import './App.css'

// react-redux-firebase config
const rrfConfig = {
	userProfile: 'rx/root/adminProfiles',
	useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
	enableClaims: true, // Get custom claims along with the profile
}

const rrfProps = {
	firebase,
	config: rrfConfig,
	dispatch: store.dispatch,
	createFirestoreInstance,
}

function AuthIsLoaded({ children }) {
	const auth = useSelector(state => state.firebase.auth)
	if (!isLoaded(auth)) {
		return (
			<Container>
				<div style={{ height: 120 }} />
				<div style={{ textAlign: "center" }}>
					<Spinner animation="border" />
				</div>
			</Container>
		)
	};
	return children
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
const PrivateRoute = ({ children, ...rest }) => {
	const auth = useSelector(selectors.auth)
	const authClaims = useSelector(selectors.authClaims)
	const dispatch = useDispatch()

	useEffect(() => {
		if (auth && auth.uid) {
			listenToFirebaseChanges(dispatch, authClaims)
		}
	}, [auth, dispatch, authClaims])

	let params = new URLSearchParams(useLocation().search);
	const allowedQueryParams = ['purchaseId', 'customer', 'source']

	allowedQueryParams.forEach(key => {
		const value = params.get(key)
		const old = utils.cookies.get(key)
		if ((!old || old !== value)) {
			utils.cookies.set(key, value)
		}
	})

	return (
		<Route
			{...rest}
			render={({ location }) =>
				isLoaded(auth) && !isEmpty(auth) && auth.emailVerified ? (
					children
				) : (
					<Redirect
						to={{
							pathname: routes.login,
							state: { from: location },
							search: params.toString(),
						}}
					/>
				)
			}
		/>
	);
}


function App() {
	return (
		<Provider store={store}>
			<ReactReduxFirebaseProvider {...rrfProps}>
				<AuthIsLoaded>
					<BrowserRouter>
						<Switch>
							<Route path={routes.login}>
								<Login />
							</Route>
							<Route path={routes.authActions}>
								<AuthActions />
							</Route>
							<PrivateRoute path={routes.home}>
								<MainContainer>
									<RootRouter />
								</MainContainer>
							</PrivateRoute>
						</Switch>
					</BrowserRouter>
				</AuthIsLoaded>
			</ReactReduxFirebaseProvider>
		</Provider>
	);
}

export default App;
