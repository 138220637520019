import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import i18n from '../../../lib/i18n'
import styling from '../../../lib/styling'
import constants from '../../../lib/constants'

import Table from '../SharedComponents/Table'
import RxOrderUpdates from '../SharedComponents/RxOrderUpdates'
import YearMonthSelector from '../SharedComponents/YearMonthSelector'

const {
  RX_STATUS_SUCCESS,
  RX_STATUS_FAILED,
  RX_STATUS_CANCELED,
} = constants.rx_dashboard

const Container = styled.div`
  padding: 15px;
`
const HeaderBox = styled.div`
  background: ${styling.colors.pink};
  color: ${styling.colors.white};
  padding: 2px 5px;
  border-radius: 5px;
`

const RxOrders = ({
  rxUpdates,
}) => {
  const today = new Date()
  const rxStrings = i18n.rx_support
  const [showRxUpdate, setShowRxUpdate] = useState(RX_STATUS_FAILED)
  const [yearMonth, setYearMonth] = useState({ year: today.getFullYear(), month: today.getMonth() + 1 })
  const [rxUpdateOverview, setRxUpdateData] = useState([])

  useEffect(() => {
    const getDateString = () => {
      return `${yearMonth.year}-${yearMonth.month < 10 ? `0${yearMonth.month}` : yearMonth.month}`
    }
    const overview = rxUpdates?.overview?.filter(({ eventDateKey }) => {
      return eventDateKey.includes(getDateString())
    })
    setRxUpdateData(overview)
  }, [yearMonth, rxUpdates])

  if (!rxUpdateOverview || !rxUpdates) return <></>

  const handleRxUpdateOverview = () => {

    return rxUpdateOverview.map(({
      total,
      id,
      successful,
      failed,
      canceled,
      eventDateKey,
    }) => {
      const keyMatch = id.match(/.+(\w{3})-?(\d)?$/)
      const manuallyTriggered = Boolean(keyMatch[1] === 'man')
      const triggered = manuallyTriggered ? `manual ${keyMatch[2]}` : 'automatic'
      return {
        data: [eventDateKey, triggered, successful, failed, canceled, total],
        id,
      }
    })
  }

  const changeShownRxUpdate = (headerText) => {
    const getKey = () => {
      if (headerText === rxStrings.rx_order_success) return RX_STATUS_SUCCESS
      if (headerText === rxStrings.rx_order_canceled) return RX_STATUS_CANCELED
      return RX_STATUS_FAILED
    }
    setShowRxUpdate(getKey())
  }

  return <Container>
    <HeaderBox>
      {rxStrings.hp_rx_updates}
    </HeaderBox>
    <YearMonthSelector
      selected={yearMonth}
      onSelect={(val) => setYearMonth(val)}
      startYearMonth={{ year: 2021, month: 5 }}
    />
    <Table
      header={[rxStrings.date, rxStrings.triggered, rxStrings.rx_order_success, rxStrings.rx_order_fail, rxStrings.rx_order_canceled, rxStrings.total]}
      rows={handleRxUpdateOverview()}
      onHeaderClick={(headerText) => changeShownRxUpdate(headerText)}
      noClick={[rxStrings.date, rxStrings.total]}
      style={{
        maxHeight: '200px',
        overflow: 'scroll',
      }}
    />
    <RxOrderUpdates
      updates={rxUpdates[showRxUpdate]}
      title={rxStrings[`rx_update_${showRxUpdate}`]}
    />

  </Container>
}

export default RxOrders