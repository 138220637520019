import _ from 'lodash'

const isString = value => {
	return _.isString(value)
}

const isNumber = value => {
	return _.isNumber(value)
}

const isDate = value => {
	return true;
}

const isEmail = value => {
	return true;
}

const isPhone = value => {
	return true;
}

const rxSignupSchema = {
	sections: [
		{
			key: "profile",
			label: "Profile",
			fields: [
				{
					key: "firstName",
					label: "First Name",
					validator: isString,
				},
				{
					key: "lastName",
					label: "Last Name",
					validator: isString,
				},
				{
					key: "birthdate",
					label: "Birthdate",
					validator: isDate,
				},
				{
					key: "gender",
					label: "Gender",
					validator: isString,
				},
				{
					key: "email",
					label: "Email",
					validator: isEmail,
				},
				{
					key: "phone",
					label: "Phone",
					validator: isPhone,
				},
			]
		},
		{
			key: "medication",
			label: "Medication",
			fields: [
				{
					key: "doctor",
					label: "Doctor",
					validator: isString,
				},
				{
					key: "name",
					label: "Medication Name",
					validator: isString,
				},
				{
					key: "numberOfPacks",
					label: "# of Packs",
					validator: isNumber,
				},
			]
		},
		{
			key: "pharmacy",
			label: "Pharmacy",
			fields: [
				{
					key: "name",
					label: "Name",
					validator: isString,
				},
				{
					key: "phone",
					label: "Phone",
					validator: isString,
				},
			]
		},
		{
			key: "insurance",
			label: "Insurance",
			fields: [
				{
					key: "groupNumber",
					label: "Group Number",
					validator: isString,
				},
				{
					key: "memberId",
					label: "Member ID",
					validator: isString,
				},
				{
					key: "rxBin",
					label: "Rx Bin",
					validator: isString,
				},
				{
					key: "rxPcn",
					label: "Rx Pcn",
					validator: isString,
				},
				{
					key: "insurancePhone",
					label: "Insurance Phone",
					validator: isString,
				},
			]
		},
		{
			key: "shipping",
			label: "Shipping",
			fields: [
				{
					key: "street",
					label: "Street Address",
					validator: isString,
				},
				{
					key: "city",
					label: "City",
					validator: isString,
				},
				{
					key: "state",
					label: "State",
					validator: isString,
				},
				{
					key: "zip",
					label: "Zipcode",
					validator: isString,
				},
			]
		},

	]
}

export default rxSignupSchema