import { useEffect, useRef } from 'react'
import { isEmpty as RisEmpty } from 'ramda'

import * as dateTime from './dateTime'
import * as cookies from './cookies'
import testData from './testData'
import emmeRx from './emmeRx'

export default {
  dateTime,
  cookies,
  testData,
  emmeRx,
  useEffectOnce: (effect) => useEffect(effect, []),
  usePrevious: (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  },
}

export const isEmpty = (data) => {
  return RisEmpty(data) || data === undefined || data === null
}