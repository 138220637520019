import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { store } from '../store'
import { Card, Spinner, InputGroup, FormControl, Button } from 'react-bootstrap'
import { useFirestore } from 'react-redux-firebase'

import rxSignupSchema from '../data/rxSignupSchema'

const TaskDetails = {};

export const UserProvider = ({ userID, children }) => {
	const user = useSelector(state => (state && state.firestore && state.firestore.data) ? state.firestore.data[userID] : null)
	const firestore = useFirestore()

	const listenerSettings = {
		collection: '/rx/root/users',
		doc: userID,
		storeAs: userID,
	}

	useEffect(() => {
		firestore.setListener(listenerSettings)
		return function cleanup() {
			firestore.unsetListener(listenerSettings)
		}
	}, [firestore, listenerSettings])

	return (
		React.cloneElement(children, { user, userID })
	)
}

const EditableField = ({ label, fieldKey, value, onChange }) => {
	const [canEdit, setCanEdit] = useState(false)
	const [changedValue, setChangedValue] = useState(value)
	const inputRef = useRef(null);

	const onSave = () => { 
		onChange(changedValue); 
		setCanEdit(false);
	}

	const onCancel = () => { 
		setChangedValue(value); 
		setCanEdit(false); 
	}

	const onEdit = () => { 
		setCanEdit(true);
		setTimeout(() => {
			inputRef.current.focus();
		}, 100)
	}

	return (
		<InputGroup className="mb-3">
			<InputGroup.Prepend>
				<InputGroup.Text>Verified</InputGroup.Text>
				<InputGroup.Checkbox aria-label="Checkbox for following text input" />
				<InputGroup.Text style={{ width: 200 }}>{label}</InputGroup.Text>
			</InputGroup.Prepend>
			<FormControl
				ref={inputRef}
				value={changedValue}
				disabled={!canEdit}
				onChange={event => {
					setChangedValue(event.target.value)
				}}
				onKeyPress={event => {
					if (event.key === "Enter") {
						onSave();
					}
				}}

			/>
			<InputGroup.Append>
				{ canEdit
					?
					<>
						<Button variant="primary" onClick={onSave}>Save</Button>
						<Button variant="warning" onClick={onCancel}>Cancel</Button>
					</>
					:
					<Button variant="outline-secondary" onClick={onEdit}>Edit</Button>
				}
			</InputGroup.Append>
		</InputGroup>
	)
}


const VerifySignup = ({ task, user, userID }) => {
	// const firestore = useFirestore();

	if (!user) {
		return (
			<div style={{ textAlign: "center" }}>
				<Spinner animation="border" />
			</div>
		)
	}

	const onBeginTransfer = () => {
		console.log("transfer");
		console.log("store state\n", store.getState());
	}

	const onWaitForUpdates = () => {
		store.firestore.update({
			collection: '/rx/root/tasks',
			doc: task.id
		}, {
			status: "inProgress",
			performAt: new Date(Date.now() + 24 * 60 * 60 * 1000),
		})
	}

	const onUpdateField = (key, value) => {
		store.firestore.update({ 
			collection: '/rx/root/users', 
			doc: userID 
		}, { 
			[key]: value, 
			updated: store.firestore.FieldValue.serverTimestamp(),
		})
	}

	var sections = rxSignupSchema.sections.map(section => {
		return (
			<Card key={section.key}>
				<Card.Header>
					{section.label}
				</Card.Header>
				<Card.Body>
					{section.fields.map(field => {
						const key = `${section.key}.${field.key}`
						return (
							<EditableField label={field.label} fieldKey={key} value={user[section.key] && user[section.key][field.key]} key={key}
								onChange={newValue => {
									onUpdateField(key, newValue);
								}}
							/>
						)
					})}
				</Card.Body>
			</Card>
		)
	})

	return (
		<>
			<p>{task.id}</p>
			{sections}
			<Button variant="primary" onClick={onBeginTransfer}>Begin Transfer</Button>{' '}
			<Button variant="secondary" onClick={onWaitForUpdates}>Wait For Updates</Button>
		</>
	)
}
TaskDetails.verifySignup = VerifySignup;

TaskDetails.unrecognizedType = ({ task }) => {
	return (
		<p>ERROR: Unrecognized task type</p>
	)
}

export default TaskDetails;