import { createStore, combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import { firebaseReducer } from 'react-redux-firebase'
import { reduxFirestore, firestoreReducer } from 'redux-firestore'
import { composeWithDevTools } from 'redux-devtools-extension'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'

import * as storeActions from './actions'
import storeSelectors from './selectors'
import actionType from './actions/types'
import { isEmpty } from 'lodash'
import i18n from '../lib/i18n'

export const selectors = storeSelectors
export const actions = storeActions

const rfConfig = {}; // optional redux-firestore Config Options
const createStoreWithFirebase = composeWithDevTools(
	reduxFirestore(firebase, rfConfig), // firebase instance as first argument, rfConfig as optional second
)(createStore);

export const store = createStoreWithFirebase(
	combineReducers({
		// Add firebase to reducers
		firebase: firebaseReducer,
		firestore: firestoreReducer,
		pageLogic: handleActions(
			{
				[actionType.loading]: (state = {}, action) => {
					return {
						...state, loading: {
							...state.loading,
							...action.payload,
						}
					}
				},
				[actionType.setBanner]: (state = {}, action) => {
					return {
						...state,
						banner: action.payload ? {
							...state.banner,
							...action.payload,
						} : null
					}
				},
			},
			{}
		),
		errors: handleActions(
			{
				[actionType.error]: (state = {}, action) => {
					const { errorType, payload, payloadAction } = action
					if (payloadAction === 'remove') {
						return {
							...state,
							[errorType]: null
						}
					}
					return {
						...state,
						[errorType]: {
							...state[errorType],
							...payload.error,
						}
					}
				},
			},
			{}
		),
		successes: handleActions(
			{
				[actionType.success]: (state = {}, action) => {
					const { successType, payload, payloadAction } = action
					if (payloadAction === 'remove') {
						return {
							...state,
							[successType]: null
						}
					}
					return {
						...state,
						[successType]: {
							...state[successType],
							...payload,
						}
					}
				},
			},
			{}
		),
		// users: handleActions(
		// 	{
		// 		[actionType.updateUsers]: (state, action) => ({ ...state, all: action.payload }),
		// 	},
		// 	{
		// 		all: [],
		// 	}
		// ),
		// tasks: handleActions(
		// 	{
		// 		[actionType.updateTasks]: (state, action) => ({ ...state, all: action.payload }),
		// 	},
		// 	{
		// 		all: [],
		// 	}
		// ),
		user: handleActions(
			{
				[actionType.account]: (state = {}, action) => {
					if (action.error) {
						return { ...state, ...action.payload }
					}
					return {
						...state,
						account: {
							...(state.account || {}),
							...action.payload,
						}
					}
				},
				[actionType.userSettings]: (state = {}, action) => {
					return {
						...state,
						userSettings: {
							...(state.userSettings || {}),
							...action.payload,
						}
					}
				},
				[actionType.twoFactor]: (state = {}, action) => {
					return {
						...state,
						twoFactor: action.payload.twoFactor,
					}
				},
			},
			{}
		),
		universalSettings: handleActions(
			{
				[actionType.getUniversalSettings]: (state = {}, action) => {
					return { ...state, ...action.payload }
				},
				[actionType.getUniversalOpenSettings]: (state = {}, action) => {
					return { ...state, ...action.payload }
				},
				[actionType.getFeatureSettings]: (state = {}, action) => {
					return { ...state, featureSettings: { ...state.featureSettings, ...action.payload } }
				},
			},
			{}
		),
		customers: handleActions(
			{
				[actionType.getCustomer]: (state = {}, action) => {
					const { data, error, email } = action.payload
					if (error) {
						const newCustomerData = {
							...state[email],
							alert: action.payload,
						}
						return {
							...state,
							alert: action.payload,
							[email]: newCustomerData
						}
					}
					return { ...state, [email]: data || action.payload }
				},
				[actionType.updateCustomer]: (state = {}, action) => {
					return {
						...state,
						[action.payload.email]: {
							...state[action.payload.email],
							...action.payload,
						}
					}
				},
				[actionType.updateEmail]: (state = {}, action) => {
					if (!action.payload.error) {
						const { newUser, oldUser } = action.payload
						const newData = {
							...state[oldUser.email],
							email: newUser.email,
							deleted: [],
							alert: {
								success: {
									message: `Successfully changed the Email from ${oldUser.email} to ${newUser.email}. Please refresh!`,
								}
							}
						}
						return {
							...state,
							alert: action.payload,
							[oldUser.email]: newData,
						}
					}
				},
				[actionType.setUserRights]: (state = {}, action) => {
					if (action.payload.error) {
						const newCustomerData = {
							...state[action.payload.email],
							alert: action.payload,
						}
						return {
							...state,
							alert: action.payload,
							[action.payload.email]: newCustomerData
						}
					}
					const newCustomerData = {
						...state[action.payload.email],
						claims: {
							...state[action.payload.email].claims || {},
							...action.payload.claims
						},
					}
					return {
						...state,
						alert: action.payload,
						[action.payload.email]: newCustomerData
					}
				},
				[actionType.customerLookup]: (state = {}, action) => {
					return {
						...state,
						lookup: {
							...state.lookup,
							...action.payload,
						}
					}
				},
				[actionType.deleteAccount]: (state = {}, action) => {
					if (!isEmpty(action.payload.error)) {
						return {
							...state,
							[action.payload.email]: {
								...state[action.payload.email],
								deleted: false,
								alert: {
									error: action.payload.error
								}
							}
						}
					}
					return {
						...state,
						[action.payload.email]: {
							deleted: true,
							alert: {
								success: {
									message: i18n.support.delete_success,
								}
							}
						}
					}
				},
			},
			{}
		),
		reports: handleActions(
			{
				[actionType.reports]: (state = {}, action) => {
					const { key, data, meta } = action.payload
					let updateData = data
					if (key === 'monthlyRxReport') updateData = { [meta.yearMonth]: data }
					return {
						...state,
						[key]: {
							...state[key] || {},
							...updateData,
						}
					}
				},
			},
			{}
		),
		comments: handleActions(
			{
				[actionType.comments]: (state = {}, action) => {
					return { ...state, ...action.payload }
				},
			},
			{}
		),
		owner: handleActions(
			{
				[actionType.internalUser]: (state = {}, action) => {
					return {
						...state,
						internal: {
							...state.internal || {},
							...action.payload,
						}
					}
				},
			},
			{}
		),
	}),
	{},
)
