import React from 'react'
import TaskCard from './TaskCard'

const TaskList = ({ tasks }) => {

	if (!tasks) {
		return null;
	}
	return (
		<div>
			{tasks.map((task, i) => {
				return (
					<TaskCard task={task} key={i + task.id} />
				)
			})}
		</div>
	)
}

export default TaskList