import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'
import _ from 'lodash'
import {
	isEmpty,
} from 'ramda'
import constants from '../lib/constants'
import { cleanDate, today } from '../lib/utils/dateTime'

const {
	DAY,
	MONTH,
} = constants
const {
	START_DATE,
	NUM_ACTIVE_PILLS,
	NUM_PLACEBO_PILLS,
} = constants.pillPack

const selectors = {
	auth: state => state.firebase.auth,
	authClaims: state => _.get(state, "firebase.profile.token.claims", {}),
	// users: state => state.users.all,
	// tasks: state => state.tasks.all,
	user: state => state.user,
	userError: state => state.user?.error,
	userAccount: state => state.user?.account,
	userSettings: state => state.user?.userSettings,
	userKlaviyoId: state => state.user?.userSettings?.klaviyo_id,
	strongPasswordSetAt: state => state.user?.userSettings?.strongPasswordSetAt,
	rxUser: state => state.user?.rxUser,
	addresses: state => state.user?.rxUser?.addresses || {},
	address: (state, type) => (state.user?.rxUser?.addresses || {})[type] || {},
	paymentInfo: (state) => state.user?.rxUser?.paymentInfo,
	errors: state => state.errors,
	successes: state => state.successes,
	error: (state, type) => state.errors && state.errors[type],
	success: (state, type) => state.successes && state.successes[type],
	universalSettings: state => state.universalSettings,
	featureSettings: state => state.universalSettings?.featureSettings,
	pricing: state => state.universalSettings?.pricing,
	constants: state => state.universalSettings?.constants,
	constant: (state, key) => state.universalSettings?.constants && state.universalSettings?.constants[key],
	customers: state => state.customers,
	customer: (state, email) => state.customers[email],
	loading: state => state.pageLogic.loading && state.pageLogic.loading.general,
	loadingStates: state => state.pageLogic.loading,
	banner: state => state.pageLogic.banner,
	customerLookup: state => state.customers.lookup,
	prescriptions: state => state.customers.prescriptions,
	customerPacks: (state, email) => {
		const customerData = state.customers[email]
		if (!customerData || !customerData.packs) return {}

		const packs = customerData.packs
		const rawPacks = Object.values(packs)
		return rawPacks.map(pack => {
			const {
				numActivePills,
				numPlaceboPills,
				pillType,
				id,
			} = pack
			const startDate = cleanDate(pack.startDate._convertedPureDate)
			const nextPack = packs[id + 1]
			const nextStartDate = nextPack && cleanDate(nextPack.startDate._convertedPureDate)
			const expectedEndDate = startDate.plus({ day: numActivePills + numPlaceboPills - 1 })
			const endDate = nextStartDate <= expectedEndDate ? nextStartDate.minus({ day: 1 }) : expectedEndDate
			return {
				pillType,
				startDate,
				endDate,
				id,
			}
		})
	},
	getDaysForSpan: (state, email, first, last) => {
		const customerData = state.customers[email]
		if (!customerData || !customerData.days) return {}

		const days = customerData.days

		const packData = selectors.customerPacks(state, email)

		const cleanExperiences = (experiences) => {
			const result = {}
			const pill = experiences.pill
			const other = experiences.all
			if (pill) {
				result.pill = {
					...pill,
					date: cleanDate(pill.date._convertedPureDate),
					pillTargetDate: cleanDate(pill.pillTargetDate._convertedDateTime),
					pillTakenDate: pill.pillTakenDate && cleanDate(pill.pillTakenDate._convertedDateTime),
				}
			}
			if (other) {
				result.experiencesRecorded = !isEmpty(other)
			}
			return result
		}

		const computeDays = () => {
			const cleanedDays = [[]]
			const lastDay = last.get(DAY)
			const startInWeek = first.weekday - 1
			let currentWeek = 0
			for (let day = 0; day <= startInWeek; day++) {
				cleanedDays[currentWeek].push({
					key: `${day}-before`,
				})
			}

			for (let dayOffset = 0; dayOffset < lastDay; dayOffset++) {
				const dayKey = first.plus({ [DAY]: dayOffset }).toFormat('yyyy-MM-dd');
				const day = days[dayKey]
				if (cleanedDays[currentWeek].length === 7) {
					currentWeek++
					cleanedDays[currentWeek] = []
				}
				const cleanDay = day ? {
					...day,
					date: cleanDate(day.date && day.date._convertedPureDate),
					...cleanExperiences(day.experiences),
				} : {
					date: cleanDate(dayKey),
					key: dayKey,
					experiences: {},
				}
				cleanedDays[currentWeek].push({
					...cleanDay,
					packStarted: packData && packData.find(pack => {
						return pack.startDate.equals(cleanDay.date)
					}),
					packEnded: packData && packData.find(pack => {
						return pack.endDate.equals(cleanDay.date)
					}),
				})
			}

			const numMissing = 7 - cleanedDays[currentWeek].length
			for (let day = 0; day < numMissing; day++) {
				cleanedDays[currentWeek].push({
					key: `${day}-after`,
				})
			}

			return cleanedDays
		}

		return computeDays()
	},
	customerDataForCalendar: (state, email, dayInMonth) => {
		if (!dayInMonth || !email) return {}
		const deleted = state.customers[email].deleted

		if (deleted) return {}

		const firstOfMonth = dayInMonth.startOf(MONTH)
		const lastOfMonth = dayInMonth.endOf(MONTH)

		const weeks = selectors.getDaysForSpan(state, email, firstOfMonth, lastOfMonth)

		return {
			start: firstOfMonth,
			end: lastOfMonth,
			weeks,
		}
	},
	getActivePack: (state, email) => {
		const customers = state.customers
		const customer = customers[email]

		const result = {
			pack: null,
			days: null,
		}

		if (!customer) return result

		const totalNumPacks = Object.keys(customer.packs).length
		const lastPack = customer.packs[totalNumPacks - 1]
		const lastPackSpan = lastPack[NUM_ACTIVE_PILLS] + lastPack[NUM_PLACEBO_PILLS]
		const lastPackStart = cleanDate(lastPack[START_DATE])
		const nextPackStart = lastPackStart.plus(lastPackSpan)

		const hasCurrentPack = today < nextPackStart

		if (!hasCurrentPack) return {
			...result,
			lastPack: {
				...lastPack,
				endDate: lastPackStart.plus(lastPackSpan - 1)
			}
		}

		result.pack = lastPack

		return result
	},
	batteryPercent: (state, email) => {
		const customers = state.customers
		const customer = customers && customers[email]
		const settings = customer && customer.settings && customer.settings.app
		if (!settings || !settings.hardware || !settings.hardware.batteryLevel) {
			return undefined;
		} else {
			const batteryLevel = settings.hardware.batteryLevel;
			const levels = [
				{ percent: 1.0, level: 3940 },
				{ percent: 0.75, level: 3820 },
				{ percent: 0.50, level: 3710 },
				{ percent: 0.20, level: 3640 },
				{ percent: 0, level: 3200 },
				{ percent: 0, level: 0 },
			];
			let percent = 1;
			for (let i = 0; i < levels.length - 2; i++) {
				const o1 = levels[i];
				const o2 = levels[i + 1];
				if (batteryLevel < o1.level && batteryLevel >= o2.level) {
					const p = (batteryLevel - o2.level) / (o1.level - o2.level);
					percent = p * (o1.percent - o2.percent) + o2.percent;
					break;
				}
			}
			return percent;
		}
	},
	purchases: state => state.purchases,
	purchaseId: state => state.purchases?.user?.purchase?.purchaseId,
	purchaseEvents: state => state.purchases?.events,
	purchaseLatestEventKey: state => state.purchases?.latestEvent?.key,
	agreeSubscription: state => !!state.rxDelivery?.purchase?.agreedToSubscription,
	subscriptionType: state => state.rxDelivery?.purchase?.packsPerShipment,
	eligibility: state => ({
		eligible: state.purchases?.eligible,
		rejectReason: state.purchases?.rejectReason,
	}),
	purchaseNav: state => state.rxDelivery?.setupNavigation,
	purchaseStep: state => state.rxDelivery?.setupNavigation?.step,
	checkoutStep: state => state.rxDelivery?.setupNavigation?.checkoutStep,
	purchaseType: state => state.rxDelivery?.setupNavigation?.type || state.rxDelivery?.purchaseType,
	purchaseSetup: state => state.rxDelivery?.setupNavigation?.data || { notFound: true },
	invoices: state => state.rxDelivery?.invoices,
	invoice: (state, type) => state.rxDelivery?.invoices && state.rxDelivery?.invoices[type],
	invoiceId: state => state.user?.rxUser?.purchase?.invoiceId,
	purchaseFinishedAt: state => state.user?.rxUser?.purchaseFinishedAt,
	canPurchase: state => {
		const rxUser = state.user?.rxUser
		if (!rxUser) return false
		const purchase = rxUser.purchase

		return purchase && purchase.eligible && (
			!rxUser.purchasedAt || (rxUser.purchasedAt && !rxUser.purchasedFinishedAt)) &&
			purchase.invoiceId
	},
	rxNotes: state => state.rxDelivery?.notes,
	rxUpdates: state => state.rxDelivery?.rxUpdates,
	rxCaseMembership: state => state.rxDelivery?.rxCaseMembership,
	rxErrorTasks: state => state.rxDelivery?.errorTasks,
	reports: state => state.reports,
	monthlyRxReports: state => state.reports?.monthlyRxReport,
	comments: state => state.comments,
	owner: state => state.owner,
}

export default selectors
