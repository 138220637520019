import React from 'react'
import { CheckCircle, XCircle } from 'react-bootstrap-icons'
import styled from 'styled-components'

const Text = styled.span`
  padding-right: 5px;
`

const BooleanValue = ({
  text,
  value,
}) => {
  const Icon = value ? CheckCircle : XCircle
  const iconColor = value ? 'green' : 'red'
  if (text) return <><Text>{text}</Text><Icon color={iconColor}/></>
  return <Icon color={iconColor}/>
}

export default BooleanValue