export default {
  HARDWARE: 'hardware',
  FL33T_DEVICE_TOKEN: 'FL33T_DEVICE_TOKEN',
  PERIPHERAL_FW_VERSION: 'PERIPHERAL_FW_VERSION',
  PERIPHERAL_NAME: 'PERIPHERAL_NAME',
  PERIPHERAL_SERIAL_NUMBER: 'PERIPHERAL_SERIAL_NUMBER',
  PERIPHERAL_SHOULD_FETCH_DETAILS: 'PERIPHERAL_SHOULD_FETCH_DETAILS',
  PERIPHERAL_UUID: 'PERIPHERAL_UUID',
  BATTERY_LEVEL: 'batteryLevel',
  LAST_BATTERY_READ: 'timestamp_lastBatteryRead',
  GENERAL: 'general',
  APP_START_DATE: 'appStartDate',
  FIRST_PACK_CREATION: 'firstPackCreationDate',
  REMINDERS: 'reminders',
  PILL_TIME: 'pillTime',
  PILL_TYPE: 'pillType',
  PILL_PACK: 'pillPack',
  FIRST_REMINDER: 'reminder1Offset',
  FIRST_REMINDER_ON: 'reminder1On',
  SECOND_REMINDER: 'reminder2Offset',
  SECOND_REMINDER_ON: 'reminder2On',
  THIRD_REMINDER: 'reminder3Offset',
  THIRD_REMINDER_ON: 'reminder3On',
  PLACEBO_REMINDER: 'reminderPlaceboOffset',
  PLACEBO_REMINDER_ON: 'reminderPlaceboOn',
  HOMEZONE: 'homeTimezone',
  REMINDER_METHOD: 'reminderMethod',
  REMINDER_NOTIFICATION: 'notification',
  REMINDER_TEXT: 'text',
  REMINDER_BOTH: 'both',
  OS: 'phoneOS',
  OS_VERSION: 'phoneOSVersion',
  COMPLETED_SETUP: 'completedSetupWizard',
  EMAIL: 'email',
  PREFERRED_NAME: 'preferredName',
  SKIPS_PACEBO: 'skipsPlacebo',
  TWO_FACTOR: 'twoFactor',
  TWO_FACTOR_MISSING_PHONE: 'phoneMissing',
  INTERNAL_PHONE: 'internalPhone',

  // emmeRX
  ONBOARDING_FINISHED: 'onboardingFinished',
  HAS_PURCHASED: 'hasPurchased',
  MEMBERSHIP_TYPE: 'membershipType',
  IS_CANCELED: 'isCanceled',
  CANCELED_REASON: 'cancelReason',
  CANCELED_AT: 'canceledAt',

  // Subscription
  SUBSCRIPTION: 'subscription',
  SUBSCRIPTION_USER_TYPE: 'subscriptionUserType',
  SUBSCRIPTION_RELEASE: 'subscriptionRelease',
  SUBSCRIPTION_DATA: 'subscrData',
  SUBSCRIPTION_LEGACY: 'isLegacyUser',
  SUBSCRIPTION_LIFETIME_FREE: 'isLifetimeFree',
  SUBSCRIPTION_APP_STORE: 'APP_STORE',
  HAS_SUBSCRIPTION: 'hasSubscription',
  ACCOUNT_CREATED_AT: 'accountCreation',
  // SUBSCRIPTION_TYPE: 'productId',
  SUBSCRIPTION_START: 'subscrStart', // TODO: change to field name
  SUBSCRIPTION_END: 'subscrEnd', // TODO: change to field name
  SUBSCRIPTION_MONTHLY_KEY: 'com.emme.access.sub.monthly',
  SUBSCRIPTION_CURRENT: 'current',
  SUBSCRIPTION_TYPE: 'type',
  SUBSCRIPTION_UPDATE: 'event_timestamp_ms',
  SUBSCRIPTION_STORE: 'store',
  SUBSCRIPTION_PURCHASED_AT: 'purchased_at_ms',
}
