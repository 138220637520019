export default {
  logo_alt: 'Emme logo',
  emme_ladies_alt: 'Drawings of different women',
  menu_alt: 'Button to the page navigation',
  close_alt: 'Button to close',
  back_alt: 'Button to go one step back',
  instagram_alt: 'Button to Instagram',
  facebook_alt: 'Button to Facebook',
  key_alt: 'Illustration of a flying key',
  app_store_alt: 'Apple App Store',
  app_google_alt: 'Google Play Store',
  loading_alt: 'Loading Spinner',
  info_box_img_alt: 'Header image',
  pack_alt: 'Illustration of a pill pack',
  bullet_point_alt: 'Bullet point',
  steps_alt: 'Drawing of steps',
  map_alt: 'Drawing of map of the USA',
  email_sent_alt: 'Drawing of a box with wings holding a key.',
  radio_selected: 'Selected radio button',
  radio_unselected: 'Unselected radio button',
  pill_alt: 'Image of pills',
  alt_visa: 'Visa card',
  alt_mc: 'Mastercard',
  alt_discover: 'Discover card',
  alt_amex: 'American Express card',
  alt_fsa_hsa: 'FSA/HSA card',
  alt_chevron_open: 'Click to close',
  alt_chevron_closed: 'Click to open',
  alt_membership_pill_only: 'Image of 3 pill packs.',
  alt_membership_pill_case_combo: 'Image of a pill packs, the smart case and a phone with the open Emme app.',
}