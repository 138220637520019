import constants from '../constants'

const {
  PW_LENGTH,
  PW_LOWERCASE,
  PW_UPPERCASE,
  PW_SPECIAL_CHAR,
  PW_DIGIT,
  MAX_PW_LENGTH,
  MIN_PW_LENGTH,
} = constants.auth;

export default {
  [`password_tip_${PW_LENGTH}`]: `Between ${MIN_PW_LENGTH} and ${MAX_PW_LENGTH} characters.`,
  [`password_tip_${PW_LOWERCASE}`]: 'At least one lowercase character.',
  [`password_tip_${PW_UPPERCASE}`]: 'At least one uppercase character.',
  [`password_tip_${PW_SPECIAL_CHAR}`]: 'At least one special character.',
  [`password_tip_${PW_DIGIT}`]: 'At least one digit.',
  password_too_long: 'Password is too long.',
  password_strength_1: 'Weak Password',
  password_strength_2: 'Fair Password',
  password_strength_3: 'Strong Password',

  // Forgot password
  password_changed: 'Password changed',
  password_reset: 'Reset password',
  for: 'for',
  password_reset_timeout: 'Password reset request has timed out.',
  password_reset_done: 'You can now return to the Emme app and sign in using your new password.',
  new_password: 'New Password',

  // Verify email
  verify_email_success_header: 'Thank you!',
  verify_email_success_info: 'This email has been verified.',
  verify_email_issue_header: 'Verify email',
  verify_email_timeout: 'Email verification request has timed out. Please go back to the Emme app to resend the request.',
  verify_email_done: 'This email has been verified.',

  // Recover email
  recover_email_success_header: (email) => email ? `Recover ${email}` : 'Recover email',
  recovered_email_success_header: (email) => email ? `Recovered ${email}` : 'Recovered email',
  recover_email_success_info: 'Please confirm that you want to recover your old email address.',
  recover_email_issue_header: 'Recover email',
  recover_email_timeout: 'Email recovery request timed out. In case you did not change the account data, please reach out to ',
  recover_email_done: 'Your email address was successfully recovered. If your account was compromised, we recommend changing your password as soon as possible.',
  recover_email_button: 'Recover my email',
  
  customer_service: 'Customer Service',
}
