import constants from '../constants'
const {
  TAB_TASKS,
  TAB_NOTES,
  TAB_AUTH,
  TAB_GENERAL,
  TAB_WHEEL,
  TAB_GGM,
  TAB_INFO,
  TAB_REPORTS,
  TAB_GGM_ORDERS,
  TAB_PURCHASE_ACTIVITIES,
  TAB_PURCHASE_SUMMARY,
} = constants.rx_dashboard

export default {
  header: 'RX Dashboard',
  tasks: 'Tasks',
  [`tab_${TAB_TASKS}`]: 'Tasks',
  [`tab_${TAB_NOTES}`]: 'GGM Notes',
  [`tab_${TAB_INFO}`]: 'Info',
  [`tab_${TAB_REPORTS}`]: 'Reports',
  [`tab_${TAB_GGM_ORDERS}`]: 'Refill orders',
  [`tab_${TAB_AUTH}`]: 'Billing',
  [`tab_${TAB_GENERAL}`]: 'General',
  [`tab_${TAB_GGM}`]: 'Pharmacy',
  [`tab_${TAB_WHEEL}`]: 'Telemed',
  [`tab_${TAB_PURCHASE_ACTIVITIES}`]: 'Purchase Activities',
  [`tab_${TAB_PURCHASE_SUMMARY}`]: 'Purchase Summary',
  notes: 'Notes',
  hp_notes: 'High Priority Notes',
  hp_rx_updates: 'RX updates',
  order_id: 'Order ID',
  author: 'Author',
  no_messages: 'Currently no messages available.',
  quick_info: 'Find here some helpful info and links.',
  ggm_info: 'GogoMeds is our Pharmacy partner.',
  ggm_dashboard: 'Check order status in the GGM Dashboard',
  ggm_communication: 'To communicate with GGM use either Slack (#emme-collaboration), check the GGM Notes tab, or call at 1-888-795-5826.',
  wheel_info: 'Wheel is our Telemed partner.',
  wheel_status: 'Check here if Wheel is up and running.',
  wheel_communication: 'To communicate with Wheel use either Slack (#ext-wheel-emme), or send an email to support@wheel.com.',
  authorize_info: 'Authorize.net is our e-commerce platform.',
  authorize_net: 'Check here for payment statuses and reports.',
  billing_invoice: 'The invoice numbers are set together like this: xxxxxxxx-yyyyyyyy-[TM|RX]. The first characters are part of the user\'s unique identifier in the database, the characters in the middle are unique to the current purchase, and the last part identifies what was the user charged for: telemed or Rx.',
  general_purchase: 'To purchase the member needs to follow the links to app.emme.com/purchase/?type=rx-with-telemed&step=0, to see their status update, the member can use app.emme.com.',
  date: 'Date',
  rx_order_success: 'Successful',
  rx_order_fail: 'Failed',
  rx_order_canceled: 'Canceled',
  total: 'Total',
  triggered: 'Run method',
  rx_update_successful: 'Successful orders',
  rx_update_failed: 'Failed orders',
  rx_update_canceled: 'Canceled orders',
  activities_header_member: 'Member',
  activities_header_tm: 'Telemed',
  activities_header_rx: 'Rx Delivery',
  activities_uid: 'Member Id',
  activities_state: 'State',
  activities_subscription: 'Subscription Type',
  activities_internal: 'Emme employee',
  activities_tm_paid_at: 'Paid At',
  activities_tm_invoice: 'Invoice',
  activities_tm_vendor_id: 'Vendor Id',
  activities_tm_intakeform_done: 'Intake form finished',
  activities_tm_picked_up: 'Clinician started',
  activities_tm_finished: 'Finished',
  activities_tm_prescription: 'Prescription',
  activities_tm_result: 'Result',
  activities_rx_id: 'RX Id',
  activities_rx_paid_at: 'Paid At',
  activities_rx_invoice: 'Invoice',
  activities_rx_picked_up: 'Pharmacist started',
  activities_rx_finished: 'Finished',
  activities_rx_tracking: 'Tracking',
  summary_header_tm: 'Telemed',
  summary_header_rx: 'RX Delivery',
  summary_row_paid: 'Members charged',
  summary_row_refund: 'Members refunded',
  summary_row_canceled: 'Members canceled',
  summary_row_total_sum: 'Total',
  summary_row_total_internal: 'Total spent by Emme staff',
  tasks_new_case_memberships: 'Case Memberships',
  tasks_rx_order_errors: 'RX Order issues',
  tasks_rx_purchase_errors: 'RX Purchase issues',
}