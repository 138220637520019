import constants from '../constants'

const {
  CITY,
  STATE,
  ZIP,
} = constants.purchase

export default {
  // LOB address validation
  deliverable: 'The address is deliverable by the USPS.',
  deliverable_unnecessary_unit: 'The address is deliverable, but the secondary unit information is unnecessary.',
  deliverable_incorrect_unit: 'Incorrect apartment or unit number.',
  deliverable_missing_unit: 'Missing apartment or unit number.',
  undeliverable: 'Please enter a valid address.',
  [CITY]: 'Incorrect city.',
  [STATE]: 'Incorrect state.',
  [ZIP]: 'Incorrect zip code.',
}