import React, { useState } from 'react'
import styled from 'styled-components'

import {
  Tabs as BSTabs,
  Tab,
} from 'react-bootstrap'

const Container = styled.div``

const Tabs = ({
  allTabs = [],
  tabData = {},
  tabId = 'tab-data',
  defaultTab = 0,
  strings,
}) => {
  const [currentTab, setCurrentTab] = useState(allTabs[defaultTab])

  const {
    Component = <></>,
    props = {},
  } = tabData[currentTab]

  return <Container>
    <BSTabs
      id={tabId}
      transition={false} // this is needed to not throw an ref error
      activeKey={currentTab}
      onSelect={tab => setCurrentTab(tab)}
    >
      {
        allTabs.map(tab => {
          return <Tab
            eventKey={tab}
            title={strings[`tab_${tab}`]}
            key={tab}
            tabClassName={`tab-${tab === currentTab ? 'selected' : 'unselected'}`}
          >       
            <Component {...props} />
          </Tab>
        })
      }
    </BSTabs>
  </Container>
}


export default Tabs