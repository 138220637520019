import React from 'react'
import styled from 'styled-components'

import i18n from '../../../lib/i18n'
import styling from '../../../lib/styling'

import {
  FootNoteLink,
  FootNote,
} from '../../atoms/Fonts'

const LinkText = styled.a`
	font-weight: 700;
	text-decoration: underline;
  color: ${styling.colors.blue};
  background: none;
  outline: none;
  border: none;
  font-size: 14px;
  margin-bottom: 5px;
`

export const LinkButton = styled.button`
  font-weight: 700;
  text-decoration: underline;
  color: ${styling.colors.blue};
  background: none;
  outline: none;
  border: none;
  font-size: 14px;
  margin-bottom: 5px;
  &:hover, &:active, &:focus {
    outline: none;
  }
`


export const AgreeToTerms = () => {
  return <FootNote>
    {i18n.account.agree_terms}
    <FootNoteLink
      href="https://emme.com/terms-of-service-and-sale"
      target="_blank"
    >
      {i18n.account.terms}
    </FootNoteLink>
    {i18n.account.and}
    <FootNoteLink
      href="https://emme.com/privacy"
      target="_blank"
    >
      {i18n.account.privacy}
    </FootNoteLink>.
  </FootNote>
}

const HelpContainer = styled.div`
	font-size: 14px;
	color: ${styling.colors.blue};
`


export const Help = () => {
  return <HelpContainer>{i18n.account.need_help} <LinkText
    href={`mailto:${i18n.account.support_email_address}`}
    target="_blank"
  >{i18n.account.contact_us}</LinkText>
  </HelpContainer>
}