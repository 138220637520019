import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'

import constants from '../../lib/constants'
import i18n from '../../lib/i18n'

import actionType from './types'
import * as action from './index'

import {
  handleError,
  initAnalytics,
  callFn,
} from './utils'


const {
  LOGIN_SIGNUP,
  AUTH_ACCOUNT_EXISTS_DIFF_CRED,
} = constants.errors
const {
  RIGHTS_OWNER,
  RIGHTS_CUSTOMER_SUPPORT,
} = constants.support

const httpFunctions = {
  setUserRights: 'setUserRights',
  initTwoFactor: 'initTwoFactor',
  removeTwoFactor: 'removeTwoFactor',
}

const fetchInitialData = async (dispatch, user) => {
  const { uid } = user
  await action.getUserSettings(dispatch, uid)
}

const setupUser = async (dispatch, user, optInMarketing) => {
  const homezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  await action.initUserSettings(dispatch, user.uid, { optInMarketing, homezone })
  return true
}

const setupRxUser = async (dispatch, user, isNewUser = false) => {
  return true
}

const getClaims = async () => {
  const idTokenResult = await firebase.auth().currentUser.getIdTokenResult()

  if (idTokenResult) return idTokenResult.claims
  return false
}

export const initTwoFactor = async (dispatch, uid) => {
  const claims = await getClaims()
  if (claims && (claims[RIGHTS_OWNER] || claims[RIGHTS_CUSTOMER_SUPPORT])) {
    callFn(httpFunctions.initTwoFactor, { uid })
      .then(response => {
        if (response.success) {
          dispatch({
            type: actionType.twoFactor,
            payload: response.twoFactor,
          })
        } else {
          handleError(dispatch, LOGIN_SIGNUP, response.error, 'initTwoFactor')
        }
      })
      .catch(error => {
        handleError(dispatch, LOGIN_SIGNUP, error, 'initTwoFactor')
      })
  }
}

export const removeTwoFactor = (dispatch, uid) => {
  callFn(httpFunctions.removeTwoFactor, { uid })
    .then(response => {
      if (response.success) {
        dispatch({
          type: actionType.twoFactor,
          payload: null,
        })
      } else {
        handleError(dispatch, LOGIN_SIGNUP, response.error, 'removeTwoFactor')
      }
    })
    .catch(error => {
      handleError(dispatch, LOGIN_SIGNUP, error, 'removeTwoFactor')
    })
}

const afterLogin = async (dispatch, user, data) => {
  const { inRXOnboarding, verifyEmail } = data
  if (verifyEmail && !user.emailVerified) {
    user.sendEmailVerification()
  }
  inRXOnboarding && await setupRxUser(dispatch, user)
  await fetchInitialData(dispatch, user)
  await initTwoFactor(dispatch, user.uid)

  await initAnalytics(user.uid, data)
  return dispatch({
    type: actionType.account,
    payload: user,
  })
}

const afterSignup = async (dispatch, user, data) => {
  const { inRXOnboarding, optInMarketing } = data
  await setupUser(dispatch, user, optInMarketing)
  inRXOnboarding && await setupRxUser(dispatch, user, true)
  await initAnalytics(user.uid, data, true)
  dispatch({
    type: actionType.account,
    payload: user,
  })
  await fetchInitialData(dispatch, user)
}

const promptUserForPassword = (email) => {
  const pw = window.prompt(`What passwsord did you use for your account ${email}?`)
  return pw
}
const getProviderForProviderId = (providerName) => {
  if (providerName === 'google.com') return new firebase.auth.GoogleAuthProvider()
}

const handleConnectAccount = (dispatch, error, data) => {
  const email = error.email

  return firebase.auth().fetchSignInMethodsForEmail(email)
    .then((methods) => {
      if (methods[0] === 'password') {
        const password = promptUserForPassword(email)
        return firebase.auth().signInWithEmailAndPassword(email, password)
          .then((result) => {
            result.user.linkWithCredential(error.credential)
            return result.user
          })
      }
      const provider = getProviderForProviderId(methods[0])

      return firebase.auth().signInWithPopup(provider)
        .then(function (result) {
          result.user.linkWithCredential(error.credential)
          return afterLogin(dispatch, result.user, data)
        });
    });
}

export const login = (dispatch, credentials, data = {}) => {
  const { email, password } = credentials
  const auth = firebase.auth()
  auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
      return firebase.auth().signInWithEmailAndPassword(email, password)
    })
    .then((userCredentials) => {
      const user = userCredentials.user
      return afterLogin(dispatch, user, data)
    })
    .catch((error) => {
      return handleError(dispatch, LOGIN_SIGNUP, error, 'login')
    })
}

const setPasswordUpdatedAt = async (dispatch, uid) => {
  try {
    const update = await action.updateUserSettings(dispatch, uid, {
      strongPasswordSetAt: new Date().toISOString(),
    })
    return update
  } catch (error) {
    return handleError(dispatch, LOGIN_SIGNUP, error, 'setPasswordUpdatedAt')
  }
}

export const signUp = (dispatch, credentials, data = {}) => {
  const { email, password } = credentials
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
      return firebase.auth().createUserWithEmailAndPassword(email, password)
    })
    .then(userCredentials => {
      setPasswordUpdatedAt(dispatch, userCredentials.user.uid)
      userCredentials.user.sendEmailVerification()
      return afterSignup(dispatch, userCredentials.user, data)
    })
    .catch((error) => {
      return handleError(dispatch, LOGIN_SIGNUP, error, 'signup')
    })
}

const signInWithPopup = async (dispatch, provider, meta = {}) => {
  const data = {
    ...meta,
    oAuth: true,
  }
  return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
      return firebase.auth().signInWithPopup(provider)
    })
    .then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      const user = result.user;
      const isNewUser = result.additionalUserInfo.isNewUser
      if (isNewUser) return afterSignup(dispatch, user, data)
      return afterLogin(dispatch, user, data)
    }).catch((error) => {
      if (error.code === AUTH_ACCOUNT_EXISTS_DIFF_CRED) {
        return handleConnectAccount(dispatch, error, data)
      } else {
        return handleError(dispatch, LOGIN_SIGNUP, error, 'signInWithPopup')
      }
    });
}

export const useGoogleOAuth = async (dispatch, meta) => {
  const provider = new firebase.auth.GoogleAuthProvider()
  return await signInWithPopup(dispatch, provider, meta)
}

export const changePassword = async (dispatch, { newPassword, oldPassword }) => {
  const user = firebase.auth().currentUser

  try {
    if (user) {
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        oldPassword
      );
      await user.reauthenticateWithCredential(credential)
      const passwordChange = await user.updatePassword(newPassword)
      await setPasswordUpdatedAt(dispatch, user.uid)
      return { success: true, data: passwordChange }
    }
  } catch (error) {
    handleError(dispatch, LOGIN_SIGNUP, error, 'changePassword')
    return { success: false, error }
  }

}


export const forgotPassword = (dispatch, email) => {
  firebase.auth().sendPasswordResetEmail(email)
    .then(() => { })
    .catch(error => {
      dispatch({
        type: actionType.account,
        payload: { error },
      })
    })
}
export const resendVerify = (dispatch, email) => {
  firebase.auth().currentUser.sendEmailVerification()
    .then((r) => { })
    .catch(error => {
      dispatch({
        type: actionType.account,
        payload: { error },
      })
    })
}

export const setUserRights = (dispatch, uid, rights, email) => {
  callFn(httpFunctions.setUserRights, { uid, rights })
    .then(response => {
      dispatch({
        type: actionType.setUserRights,
        payload: {
          claims: rights,
          email,
          success: {
            message: i18n.store.user_right_success(email, rights)
          },
        },
      })
    })
    .catch(error => {
      dispatch({
        type: actionType.setUserRights,
        payload: { error },
      })
    })
}
