import Mixpanel from 'mixpanel-browser'
import config from '../../config'

Mixpanel.init(config.MIXPANEL_TOKEN)

export const track = async (event, data = {}) => {
  return await Mixpanel.track(event, data)
}

export const set = async (data) => {
  return await Mixpanel.people.set(data)
}

export const identify = async (unique_id) => {
  return await Mixpanel.identify(unique_id)
}

export const setOnce = async (data) => {
  return await Mixpanel.people.set_once(data)
}