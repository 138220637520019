import Cookies from 'universal-cookie'
const cookies = new Cookies();


export const set = (key, val) => {
  return cookies.set(key, val, { path: '/' })
}

export const get = (key) => {
  return cookies.get(key)
}

export const remove = (key) => {
  return cookies.remove(key)
}