import {
  isEmpty,
} from 'ramda'
import { DateTime, Duration } from 'luxon'

import i18n from '../../lib/i18n'
import constants from '../../lib/constants'
import utils from '../../lib/utils'

const {
  RIGHTS_OWNER,
  RIGHTS_CUSTOMER_SUPPORT,
  // RIGHTS_MARKETING,
  USER_RIGHTS,
  RX_DELIVERY,
} = constants.support

const {
  START_DATE,
  NUM_ACTIVE_PILLS,
  NUM_PLACEBO_PILLS,
} = constants.pillPack

const {
  cleanDate,
  today,
  formateDate,
  formatTime,
  formatDateTime,
} = utils.dateTime

const {
  HARDWARE,
  PERIPHERAL_NAME,
  PERIPHERAL_SERIAL_NUMBER,
  PERIPHERAL_FW_VERSION,
  PERIPHERAL_UUID,
  BATTERY_LEVEL,
  LAST_BATTERY_READ,
  GENERAL,
  APP_START_DATE,
  FIRST_PACK_CREATION,
  REMINDERS,
  FIRST_REMINDER,
  SECOND_REMINDER,
  THIRD_REMINDER,
  PLACEBO_REMINDER,
  PILL_TIME,
  FIRST_REMINDER_ON,
  SECOND_REMINDER_ON,
  THIRD_REMINDER_ON,
  PLACEBO_REMINDER_ON,
  HOMEZONE,
  REMINDER_METHOD,
  PILL_TYPE,
  PILL_PACK,
  OS,
  OS_VERSION,
  ONBOARDING_FINISHED,
  HAS_PURCHASED,
  IS_CANCELED,
  MEMBERSHIP_TYPE,
  CANCELED_AT,
  CANCELED_REASON,
  SUBSCRIPTION,
  SUBSCRIPTION_USER_TYPE,
  SUBSCRIPTION_LEGACY,
  SUBSCRIPTION_LIFETIME_FREE,
  // SUBSCRIPTION_APP_STORE,
  ACCOUNT_CREATED_AT,
  HAS_SUBSCRIPTION,
  // SUBSCRIPTION_END,
  // SUBSCRIPTION_START,
  SUBSCRIPTION_TYPE,
  SUBSCRIPTION_DATA,
  SUBSCRIPTION_RELEASE,
  SUBSCRIPTION_CURRENT,
  SUBSCRIPTION_UPDATE,
  SUBSCRIPTION_STORE,
  SUBSCRIPTION_PURCHASED_AT,
} = constants.settings

export const ownerSettings = {
  [USER_RIGHTS]: {
    hasData: true,
    data: [
      {
        text: i18n.support.right_owner,
        key: RIGHTS_OWNER,
        info: i18n.support.right_owner_info,
      },
      {
        text: i18n.support.right_customer_support,
        key: RIGHTS_CUSTOMER_SUPPORT,
        info: i18n.support.right_customer_support_info,
      },
      // {
      //   text: i18n.support.right_marketing,
      //   key: RIGHTS_MARKETING,
      //   info: i18n.support.right_marketing_info,
      // },
    ]
  }
}

const dateString = (date, homezone, toTime) => {
  const homeDate = date.setZone(homezone)
  if (toTime) {
    const homeString = formatTime(homeDate)
    const timeString = formatTime(date)
    if (homeString === timeString) return homeString
    return `${homeString} (You: ${timeString})`
  }
  return formateDate(date)
}

const convertPureDate = (date, homezone, toTime = false) => {
  if (!date) return i18n.na
  date = DateTime.fromISO(date._convertedPureDate)
  return dateString(date, homezone, toTime)
}

const convertDateTime = (date, homezone, toTime = false) => {
  if (!date) return i18n.na
  date = DateTime.fromISO(date._convertedDateTime)
  return dateString(date, homezone, toTime)
}

const getReminderObject = (pillTime, reminderOffset, reminderOn, homezone) => {
  const offset = Duration.fromISO(reminderOffset)
  const time = DateTime.fromISO(pillTime._convertedDateTime).plus(offset)

  return reminderOn ? dateString(time, homezone, true) : i18n.support.reminder_off
}

const getPillType = (packs) => {
  if (!packs || !packs[0]) return i18n.support.no_packs
  const totalNumPacks = Object.keys(packs).length
  const lastPack = packs[totalNumPacks - 1]
  const lastPackSpan = lastPack[NUM_ACTIVE_PILLS] + lastPack[NUM_PLACEBO_PILLS]
  const lastPackStart = cleanDate(lastPack[START_DATE])
  const lastPackEnd = lastPackStart.plus({ day: lastPackSpan - 1 })
  const hasCurrentPack = today <= lastPackEnd
  const dateString = formateDate(lastPackEnd)
  if (!hasCurrentPack) return i18n.support.no_current_pack(dateString)

  const pillType = i18n.support[lastPack[PILL_TYPE]]
  return i18n.support.pill_type(pillType, lastPack[NUM_ACTIVE_PILLS], lastPack[NUM_PLACEBO_PILLS])
}

export const getSettingsData = ({ data, universalHardwareSettings, packs, batteryPercent, featureStartDates }, rxUser) => {
  if (isEmpty(data) || isEmpty(universalHardwareSettings)) return {}
  const { app: settings, subscription } = data || {}
  const { currentFirmwareVersion } = universalHardwareSettings
  const {
    hardware,
  } = settings || {}
  if (!settings) return {}

  const homezone = settings[HOMEZONE]
  return {
    [GENERAL]: {
      hasData: true,
      data: [
        {
          key: APP_START_DATE,
          value: convertPureDate(settings[APP_START_DATE], homezone),
        },
        {
          key: FIRST_PACK_CREATION,
          value: convertPureDate(settings[FIRST_PACK_CREATION], homezone),
        },
        {
          key: HOMEZONE,
          value: homezone,
        },
        {
          key: PILL_TIME,
          value: convertDateTime(settings[PILL_TIME], homezone, true),
        },
        {
          key: OS,
          value: settings[OS] || i18n.na,
        },
        {
          key: OS_VERSION,
          value: settings[OS_VERSION] || i18n.na,
        },
      ],
    },
    [HARDWARE]: {
      hasData: !!hardware && !!hardware[PERIPHERAL_UUID],
      data: hardware ? [
        {
          key: PERIPHERAL_NAME,
          value: hardware[PERIPHERAL_NAME],
        },
        {
          key: PERIPHERAL_UUID,
          value: hardware[PERIPHERAL_UUID],
        },
        {
          key: PERIPHERAL_FW_VERSION,
          value: hardware[PERIPHERAL_FW_VERSION] === currentFirmwareVersion ?
            hardware[PERIPHERAL_FW_VERSION]
            :
            `${i18n.support.installed}: ${hardware[PERIPHERAL_FW_VERSION]}, ${i18n.support.available}: ${currentFirmwareVersion}`,
        },
        {
          key: PERIPHERAL_SERIAL_NUMBER,
          value: hardware[PERIPHERAL_SERIAL_NUMBER],
        },
        {
          key: BATTERY_LEVEL,
          value: batteryPercent ? `${parseInt(batteryPercent, 10)}% (${hardware[BATTERY_LEVEL]})` : hardware[BATTERY_LEVEL],
        },
        {
          key: LAST_BATTERY_READ,
          value: convertDateTime(hardware[LAST_BATTERY_READ], homezone),
        },
      ] : [],
    },
    [PILL_PACK]: {
      hasData: !isEmpty(packs),
      data: [
        {
          key: PILL_TYPE,
          value: getPillType(packs)
        },
      ]
    },
    [SUBSCRIPTION]: {
      hasData: !isEmpty(subscription),
      data: [
        {
          key: SUBSCRIPTION_RELEASE,
          value: dateString(DateTime.fromISO(featureStartDates.subscription)),
        },
        ...isEmpty(subscription) || !subscription ? [
          {
            key: SUBSCRIPTION_DATA,
            value: 'not set yet',
          }
        ] : [
          {
            key: SUBSCRIPTION_USER_TYPE,
            value: subscription[SUBSCRIPTION_LEGACY] ? 'Legacy' : subscription[HAS_SUBSCRIPTION] ? 'Montly subscription' : subscription[SUBSCRIPTION_LIFETIME_FREE] ? 'Free lifetime access' : 'No access'
          },
          // {
          //   key: SUBSCRIPTION_LEGACY,
          //   value: subscription[SUBSCRIPTION_LEGACY] ? 'yes' : 'no',
          // },
          // {
          //   key: SUBSCRIPTION_LIFETIME_FREE,
          //   value: subscription[SUBSCRIPTION_LIFETIME_FREE] ? 'yes' : 'no',
          // },
          {
            key: ACCOUNT_CREATED_AT,
            value: convertPureDate(subscription[ACCOUNT_CREATED_AT], true),
          },
          ...subscription[SUBSCRIPTION_LEGACY] ? [] : [
            // {
            //   key: HAS_SUBSCRIPTION,
            //   value: subscription[HAS_SUBSCRIPTION] ? 'yes' : 'no',
            // },
            ...subscription[HAS_SUBSCRIPTION] ? [
              ...isEmpty(subscription[SUBSCRIPTION_CURRENT]) ? [] : [
                {
                  key: SUBSCRIPTION_TYPE,
                  value: subscription[SUBSCRIPTION_CURRENT][SUBSCRIPTION_TYPE],
                },
                {
                  key: SUBSCRIPTION_UPDATE,
                  value:formatDateTime(new Date(subscription[SUBSCRIPTION_CURRENT][SUBSCRIPTION_UPDATE])),
                },
                {
                  key: SUBSCRIPTION_PURCHASED_AT,
                  value: formatDateTime(new Date(subscription[SUBSCRIPTION_CURRENT][SUBSCRIPTION_PURCHASED_AT])),
                },
                {
                  key: SUBSCRIPTION_STORE,
                  value: i18n.support[subscription[SUBSCRIPTION_CURRENT][SUBSCRIPTION_STORE]],
                },
              ],
            ] : [],
          ],
        ]
      ],
    },
    [REMINDERS]: {
      hasData: settings[PILL_TIME] && settings[FIRST_REMINDER],
      data: settings[PILL_TIME] && settings[FIRST_REMINDER] ? [
        {
          key: REMINDER_METHOD,
          value: i18n.support[settings[REMINDER_METHOD]],
        },
        {
          key: FIRST_REMINDER,
          value: getReminderObject(settings[PILL_TIME], settings[FIRST_REMINDER], settings[FIRST_REMINDER_ON], homezone),
        },
        {
          key: SECOND_REMINDER,
          value: getReminderObject(settings[PILL_TIME], settings[SECOND_REMINDER], settings[SECOND_REMINDER_ON], homezone),
        },
        {
          key: THIRD_REMINDER,
          value: getReminderObject(settings[PILL_TIME], settings[THIRD_REMINDER], settings[THIRD_REMINDER_ON], homezone),
        },
        {
          key: PLACEBO_REMINDER,
          value: getReminderObject(settings[PILL_TIME], settings[PLACEBO_REMINDER], settings[PLACEBO_REMINDER_ON], homezone),
        },
      ] : [],
    },
    [RX_DELIVERY]: {
      hasData: !isEmpty(rxUser),
      data: !isEmpty(rxUser) ? [
        {
          key: ONBOARDING_FINISHED,
          value: rxUser[ONBOARDING_FINISHED] ? 'yes' : 'no',
        },
        ...rxUser[ONBOARDING_FINISHED] ? [
          {
            key: HAS_PURCHASED,
            value: rxUser[HAS_PURCHASED] ? 'yes' : 'no',
          },
          {
            key: MEMBERSHIP_TYPE,
            value: rxUser[MEMBERSHIP_TYPE],
          },
          {
            key: IS_CANCELED,
            value: rxUser[IS_CANCELED],
          },
          ...rxUser[IS_CANCELED] ? [
            {
              key: CANCELED_AT,
              value: rxUser[CANCELED_AT],
            },
            {
              key: CANCELED_REASON,
              value: rxUser[CANCELED_REASON],
            },
          ] : [],
        ] : [],
      ] : []
    },
  }
}
