import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'

import actionType from '../types'
import {
  callFn,
  stopLoading,
  startLoading,
} from '../utils'


// backend function paths
const httpFunctions = {
  getMonthlyRxReport: 'getMonthlyRxReport',
}

export const getMonthlyRxReport = async (dispatch, yearMonth) => {
  startLoading(dispatch)
  callFn(httpFunctions.getMonthlyRxReport, { yearMonth })
    .then(response => {
      if (response.success) {
        dispatch({
          type: actionType.reports,
          payload: {
            key: 'monthlyRxReport',
            data: response.data,
            meta: { yearMonth },
          },
        })
      } else {
        dispatch({
          type: actionType.error,
          payload: {
            error: response.error,
            source: 'getMonthlyRxReport'
          },
        })
      }
      stopLoading(dispatch)
    })
    .catch(error => {
      stopLoading(dispatch)
      dispatch({
        type: actionType.error,
        payload: { error, source: 'getMonthlyRxReport' },
      })
    })
}

