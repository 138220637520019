import React from 'react'
import styled from 'styled-components'

import i18n from '../../../lib/i18n'
import styling from '../../../lib/styling'
import constants from '../../../lib/constants'

import { Normal } from '../../../components/atoms/Fonts'
import { Link } from '../../../components/atoms/Links'
import UnorderedList from '../../../components/molecules/UnorderedList'
import Tabs from '../../../components/atoms/Tabs'

const {
  TAB_AUTH,
  TAB_GENERAL,
  TAB_GGM,
  TAB_WHEEL,
} = constants.rx_dashboard

const strings = i18n.rx_support

const Container = styled.div`
  padding: 10px 0 ;
`
const InfoContainer = styled.div`
  padding-top: 15px;
`
const ListItem = styled.div`
  color: ${styling.colors.wine};
`

const General = () => <InfoContainer>
  <UnorderedList
    list={[
      {
        render: <ListItem>{strings.general_purchase}</ListItem>,
        key: 'general_purchase',
      },
    ]}
  />
</InfoContainer>
const Wheel = () => <InfoContainer>
  <UnorderedList
    list={[
      {
        render: <ListItem>{strings.wheel_info}</ListItem>,
        key: 'wheel_info',
      },
      {
        render: <ListItem>
          <Link to="https://wheelhealth.statuspage.io/#" >
            {strings.wheel_status}
          </Link>
        </ListItem>,
        key: 'wheel_status',
      },
      {
        render: <ListItem>{strings.wheel_communication}</ListItem>,
        key: 'wheel_communication',
      },
    ]}
  />
</InfoContainer>
const GogoMeds = () => <InfoContainer>
  <UnorderedList
    list={[
      {
        render: <ListItem>{strings.ggm_info}</ListItem>,
        key: 'ggm_info',
      },
      {
        render: <ListItem>
          <Link to="https://www.gogomeds.com/GoGoClient/#/affiliate-dashboard" >
            {strings.ggm_dashboard}
          </Link>
        </ListItem>,
        key: 'ggm_dashboard',
      },
      {
        render: <ListItem>{strings.ggm_communication}</ListItem>,
        key: 'ggm_communication',
      },
    ]}
  />
</InfoContainer>
const AuthorizeNet = () => <InfoContainer>
  <UnorderedList
    list={[
      {
        render: <ListItem>{strings.authorize_info}</ListItem>,
        key: 'authorize_info',
      },
      {
        render: <ListItem>
          <Link to="https://login.authorize.net/" >
            {strings.authorize_net}
          </Link>
        </ListItem>,
        key: 'authorize_net',
      },
      {
        render: <ListItem>{strings.billing_invoice}</ListItem>,
        key: 'billing_invoice',
      },
    ]}
  />
</InfoContainer>

const QuickInfo = () => {
  const allTabs = [TAB_GENERAL, TAB_WHEEL, TAB_GGM, TAB_AUTH]

  return <Container>
    <Normal style={{ margin: '15px 0' }}>
      {strings.quick_info}
    </Normal>
    <Tabs
      allTabs={allTabs}
      strings={i18n.rx_support}
      tabData={{
        [TAB_GENERAL]: {
          Component: General,
        },
        [TAB_WHEEL]: {
          Component: Wheel,
        },
        [TAB_GGM]: {
          Component: GogoMeds,
        },
        [TAB_AUTH]: {
          Component: AuthorizeNet,
        },
      }}
      tabId="quick-info"
    />
  </Container>
}

export default QuickInfo