import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'

import actionType from './types'
import { callFn, stopLoading, startLoading } from './utils'

const httpFunctions = {
  getAllInternalUser: 'getAllInternalUser',
}

export const getAllInternalUser = async (dispatch) => {
  startLoading(dispatch)
  callFn(httpFunctions.getAllInternalUser, { wait: 7000 })
    .then(response => {
      if (response.success) {
        dispatch({
          type: actionType.internalUser,
          payload: response.data,
        })
      } else {
        dispatch({
          type: actionType.error,
          payload: {
            error: response.error,
            source: 'getAllInternalUser'
          },
        })
      }
      stopLoading(dispatch)
    })
    .catch(error => {
      stopLoading(dispatch)
      dispatch({
        type: actionType.error,
        payload: { error, source: 'getAllInternalUser' },
      })
    })
}
