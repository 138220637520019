import React from 'react'

import styled from 'styled-components'

import {
	Container,
	Row,
	Col,
} from 'react-bootstrap'

import ladiesLeftSrc from '../assets/images/ladies-left.png'
import ladiesRightSrc from '../assets/images/ladies-right.png'

import routes from '../routes/routes'

import styling from '../lib/styling'
import i18n from '../lib/i18n'

import LoginSignUp from '../components/organisms/LoginSignUp'
import Logo from '../components/atoms/Logo'


const CenterContainer = styled(Container)`
	align-items: center;
	justify-content: center;
	height: 100%;
	position: absolute;
	overflow: scroll;
	display: flex;
`

const PageRow = styled(Row)`
	width: 100%;
	align-items: center;
	justify-content: center;
`
const LadiesContainer = styled(Col)`
	padding: 0 5px;
`
const LadiesImg = styled.img`
	width: 100%;
`
const LoginContainer = styled(Col)`
	background: ${styling.colors.white};
	width: 100%;
	box-shadow: 0px 2px 10px rgba(196, 196, 196, 0.7);
	border-radius: 32px;
	padding-top: 84px;
	padding-bottom: 40px;
	text-align: center;
	position: initial;
`

const Login = ({
	successUrl = routes.home,
	verifyEmail = true,
}) => {

	const Ladies = ({ src, alt }) => {
		const classNameSm = 'd-md-block d-none'
		return <LadiesContainer md={3} lg={4} className={classNameSm}>
			<LadiesImg src={src} alt={alt} />
		</LadiesContainer>
	}


	return <CenterContainer fluid>
		<PageRow>
			<Ladies src={ladiesLeftSrc} alt={i18n.image.emme_ladies_alt} />
			<LoginContainer lg={4} md={6} sm={10} xs={12}>
				
				<Logo />

				<LoginSignUp successRoute={successUrl} verifyEmail={verifyEmail} />

			</LoginContainer>
			<Ladies src={ladiesRightSrc} alt={i18n.image.emme_ladies_alt} />
		</PageRow>

	</CenterContainer>
}

export default Login
