export default {
  CHECKOUT_1: 'purchase_started',
  CHECKOUT_2: 'subscription_selected',
  CHECKOUT_DONE: 'purchase_paid',
  WEB_SIGNUP: 'web_sign_up',
  TM_STATE: 'telemed_state',
  WANT_INSURANCE: 'rx_insurance_updates',
  IS_DEV: 'isDeveloper',

  TAB_PROMO_CODE: 'tab_promo_code',
}