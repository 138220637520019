export default {
  LOGIN_SIGNUP: 'login-signup',

  // RX Payment errors:
  ERROR_PAYMENT_PROFILE_UPDATE: 'payment_profile_update',
  ERROR_PAYMENT_CARD_CHARGE: 'payment_card_charge',
  ERROR_PAYMENT_PROFILE_CHARGE: 'payment_profile_charge',
  ERROR_PAYMENT_PROFILE_CREATE: 'payment_profile_create',
  ERROR_RX_ORDER: 'order_issue_rx',
  ERROR_TM_ORDER: 'order_issue_tm',

  // Google Auth errors
  AUTH_ACCOUNT_EXISTS_DIFF_CRED: 'auth/account-exists-with-different-credential',
  AUTH_EMAIL_IN_USE: 'auth/email-already-in-use',
}