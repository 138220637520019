import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import PhoneInput from 'react-phone-number-input/input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import DatePicker from 'react-date-picker'
import {
  EyeFill,
  EyeSlashFill,
} from 'react-bootstrap-icons'

import i18n from '../../lib/i18n'
import styling from '../../lib/styling'
import constants from '../../lib/constants'

import { Longbutton } from './Buttons'
import Dropdown from './Dropdown'
import { Normal } from './Fonts'
import utils, { isEmpty } from '../../lib/utils'

const CORNER_RADIUS_4 = 'cornerRadius4'

const InputWithButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${props => props.width && css`
    width: ${props.width};
  `}
  border: 1px solid ${styling.colors.blue};
  ${props => props.borderColor && css`
    border: 1px solid ${props.borderColor};
  `}
  
  border-radius: 0 10px;
  ${props => props.cornerStyle === CORNER_RADIUS_4 && css`
    border-radius: 10px;
  `}
`
const CurvedTextInput = styled.input`
  width: calc(100% - 150px);
  ${props => props.width && css`
    width: calc(${props.width} - 20%);
  `}
  border: none;
  border-radius: 0 10px;
  ${props => props.cornerStyle === CORNER_RADIUS_4 && css`
    border-radius: 10px;
  `}
  padding: 11px 18px;
  &::placeholder {
    color: ${styling.colors.slate};
    font-size: 14px;
  }
  &:focus {
    outline: none;
  }
  color: ${styling.colors.blue};
  height: 40px;
`
const CurvedLongbutton = styled(Longbutton)`
  ${props => props.width && css`
    width: calc(${props.width} - 70%);
  `}
  width: 150px;
  height: 40px;
`
const CurvedDiv = styled.div`
  display: flex ;
  align-items: center;
  justify-content: end;
  padding-right: 10px;
  width: 150px;
  ${props => props.width && css`
    width: calc(${props.width} - 80%);
  `}
  height: 40px;
`

export const InputWithButton = ({
  buttonText = i18n.done,
  placeholder = '',
  width = '100%',
  value,
  onSubmit,
}) => {
  const [currentValue, setCurrentValue] = useState(value || '')
  const handleChange = (input) => {
    setCurrentValue(input.target.value)
  }
  return <InputWithButtonContainer
    width={width}
  >
    <CurvedTextInput
      width={width}
      value={currentValue}
      placeholder={placeholder}
      onChange={handleChange}
    />
    <CurvedLongbutton
      width={width}
      onClick={() => onSubmit(currentValue)}
    >
      {buttonText}
    </CurvedLongbutton>
  </InputWithButtonContainer>
}

export const PasswordInput = ({
  value,
  error,
  placeholder = '',
  onChange,
  width = '100%',
}) => {
  const [showPassword, setShowPassword] = useState(false)

  return <>
    <InputWithButtonContainer
      width={width}
      borderColor={error ? styling.colors.error_red : styling.colors.lightslate}
      cornerStyle={CORNER_RADIUS_4}
    >
      <CurvedTextInput
        width={width}
        value={value}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value)}
        cornerStyle={CORNER_RADIUS_4}
        type={showPassword ? constants.form.TEXT : constants.form.PASSWORD}
      />
      <CurvedDiv
        onClick={() => setShowPassword(!showPassword)}
      >
        {
          showPassword ? 
            <EyeFill color={styling.colors.lightslate} /> 
            :
            <EyeSlashFill color={styling.colors.lightslate} />
        }
      </CurvedDiv>
    </InputWithButtonContainer>
    {
      error && <Error>{error}</Error>
    }
  </>
}

const StyledInput = styled.input`
  width: 100%;
  border: ${styling.colors.blue} 1px solid;
  border-radius: 5px;
  height: 40px;
  padding: 5px 15px;
  color: ${styling.colors.blue};
  ${props => props.hasError && css`
    border: ${styling.colors.wine} 1px solid;
  `}
  &:focus {
    outline: none;
  }
`
const inputStyleObj = {
  width: '100%',
  height: '40px',
  border: `${styling.colors.blue} 1px solid`,
  borderRadius: '5px',
  padding: '5px 15px',
  color: styling.colors.blue,
  background: styling.colors.white,
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  ${props => props.inputColor && css`
    & input {
      color: ${props.inputColor};
    }
  `}
`
const Label = styled(Normal)`
  color: ${styling.colors.blue};
`
const Error = styled(Normal)`
  font-size: 12px;
  color: ${styling.colors.error_red};
  text-align: left;
`

export const InputWithValidation = ({
  value,
  placeholder = '',
  validations = [], // utils.validation
  label,
  onValidation,
  onChange,
  type = 'text',
  style = {},
  inputStyle = {},
  labelStyle = {},
  withAsterisk = false,
  disabled = false,
  // Dropdown only
  list,
  mainColor,
  fontColor,
}) => {
  const [foundIssue, setFoundIssue] = useState(null)
  const oldIssue = utils.usePrevious(foundIssue)

  useEffect(() => {
    oldIssue !== foundIssue && onValidation && onValidation(foundIssue)
  }, [foundIssue, onValidation, oldIssue])

  const validate = () => {
    const issue = validations.find(({
      regex,
      length,
      isRequired,
      type,
      phone,
      compareString,
      customValidation,
    }) => {
      const hasNoValue = !value || isEmpty(value) || value.length < 1
      const matchesNotRegex = regex && !value.match(regex)
      const incorrectLength = () => {
        const { min, max, exact } = length
        const charNum = value.length
        if (exact && exact !== charNum) return true
        if (min && charNum < min) return true
        if (max && charNum > max) return true
        return false
      }
      const isNotType = () => {
        if (type === 'number') {
          return typeof (parseInt(value)) !== type
        }
        if (type === 'array') {
          return !Array.isArray(value)
        }
        return typeof (value) !== type
      }
      const hasInvalidPhone = phone && !isValidPhoneNumber(value)
      const stringsNotSame = compareString && value.toString() === compareString

      if (
        (compareString && stringsNotSame) ||
        (type && isNotType()) ||
        (isRequired && hasNoValue) ||
        (regex && matchesNotRegex) ||
        (length && incorrectLength()) ||
        (phone && hasInvalidPhone) ||
        (customValidation && customValidation())
      ) {
        return true
      }

      return false
    })
    setFoundIssue(issue && issue.error)
  }

  const isRequiredField = validations.find(validation => validation?.isRequired)

  const specialInputTypes = ['tel']

  return <InputContainer style={style}>
    {
      label && <Label style={labelStyle}>
        {label} {withAsterisk && isRequiredField && '*'}
      </Label>
    }
    {
      type === 'tel' && <PhoneInput
        value={value}
        country="US"
        onChange={(input) => onChange(input)}
        style={inputStyleObj}
        disabled={disabled}
        onBlur={validate}
      />
    }
    {
      list && <Dropdown
        list={list}
        selected={value}
        onChange={onChange}
        width="100%"
        mainColor={mainColor}
        fontColor={fontColor}
        altStyle={{
          control: {
            width: '100%',
            background: 'transparent',
            borderColor: mainColor,
          },
          input: {
            ...inputStyle,
            width: '100%',
          },
        }}
      />
    }
    {
      !specialInputTypes.includes(type) && !list && <StyledInput
        type={type}
        value={value}
        placeholder={placeholder}
        hasError={!!foundIssue}
        disabled={disabled}
        style={inputStyle}
        onChange={(input) => {
          if (foundIssue) {
            validate()
          }
          onChange(input.target.value)
        }}
        onBlur={validate}
      />
    }
    {
      foundIssue && <Error>
        {foundIssue}
      </Error>
    }
  </InputContainer>
}

export const DateInput = ({
  value,
  onChange,
  withCalendar = false,
  withClear = null,
  isRequired = false,
  disabled = false,
  className = '',
  style = {},
  labelStyle = {},
  withAsterisk = false,
  label,
  minYearSpan,
  minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
  maxDate = new Date(),
}) => {
  const currentMinDate = minYearSpan ? new Date(new Date().setFullYear(new Date().getFullYear() - minYearSpan)) : minDate

  return <InputContainer style={style} inputColor={styling.colors.blue}>
    {
      label && <Label style={labelStyle}>
        {label} {withAsterisk && isRequired && '*'}
      </Label>
    }
    <DatePicker
      value={value}
      onChange={onChange}
      disableCalendar={!withCalendar}
      clearIcon={withClear}
      required={isRequired}
      disabled={disabled}
      className={className}
      maxDate={maxDate}
      minDate={currentMinDate}
    />
  </InputContainer>
}

export const InputWithLabel = ({
  label,
  value,
  onChange,
  labelStyle = {},
  placeholder = '',
  type = 'text',
  inputStyle = {},
  disabled = false,
  style = {},
  error,
}) => {
  return <InputContainer style={style}>
    {
      label && <Label style={labelStyle}>
        {label}
      </Label>
    }
    <StyledInput
      type={type}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      style={inputStyle}
      onChange={(input) => {
        onChange(input.target.value)
      }}
    />
    {
      error && <Error>{error}</Error>
    }
  </InputContainer>
}
