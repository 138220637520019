import constants from '../constants'
const {
  IN_PROGRESS,
  DONE,
  CANCELED,
} = constants
const {
  PURCHASE,
  INTAKE,
  REVIEW,
  RESULT,
} = constants.purchase

export default {
  orderStatusExamples: (key = 'notStarted') => {
    const isInProgress = key === IN_PROGRESS
    const isDone = key === DONE
    const isCanceled = key === CANCELED

    return {
      [PURCHASE]: {
        [IN_PROGRESS]: isInProgress,
        [DONE]: isDone,
        [CANCELED]: isCanceled,
      },
      [INTAKE]: {
        [IN_PROGRESS]: isInProgress,
        [DONE]: isDone,
        [CANCELED]: isCanceled,
      },
      [REVIEW]: {
        [IN_PROGRESS]: isInProgress,
        [DONE]: isDone,
        [CANCELED]: isCanceled,
      },
      [RESULT]: {
        [IN_PROGRESS]: isInProgress,
        [DONE]: isDone,
        [CANCELED]: isCanceled,
      },
    }
  }
}
