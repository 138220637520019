import constants from '../constants'
const {
  LOWERCASE,
  UPPERCASE,
  SPECIAL_CHAR,
  DIGIT,
  PW_MATCH,
  LENGTH,
  MIN_LENGTH,
  MAX_LENGTH,
  PW_CHANGED,
} = constants.account

export default {
  need_help: 'Need help?',
  contact_us: 'Contact us',
  support_email_address: 'support@emme.com',
  email: 'Email',
  email_address: 'Email address',
  email_invalid: 'This is not a valid email address.',
  password: 'Password',
  password_invalid: 'The password is too short. (Min 8 Chars)',
  create_new: 'Create new account',
  login: 'Log in',
  email_in_use: 'Email is already used. Confirm that you can delete it before proceeding.',
  email_verify: 'A verification link has been sent to your email. Please click on the link in your email to verify your account.',
  forgot_password: 'Forgot password',
  recover_password: 'Recover password',
  recovery_email: 'Enter your email address to receive a password reset link.',
  recovery_email_sent: 'We just send you an email to recover your password',
  recovery_email_info: 'If you use the Emme app, please remember to login with the new password.',
  agree_terms: 'By creating an account, you agree to our ',
  terms: 'Terms of Service',
  and: ' and ',
  privacy: 'Privacy Policy',
  refund: 'Refund Policy',
  terms_of_service: 'Terms of Service',
  terms_of_sale: 'Terms of Sale',
  opt_in_marketing: 'Keep me up to date on news and exclusive offers.',
  unauthorized: 'Sorry, but you are currently not allowed to access this page.',
  send_verify_again: (email) => `Send again to ${email}`,
  tip_reuse_account: 'If you have already made an account in the Emme app, please use your existing login details and the phone number linked with the account.',
  [`passwordCheck-${LOWERCASE}`]: 'At least one lowercase character.',
  [`passwordCheck-${UPPERCASE}`]: 'At least one uppercase character.',
  [`passwordCheck-${SPECIAL_CHAR}`]: 'At least one special character (!, @, #, $, %, ^, &, or *)',
  [`passwordCheck-${DIGIT}`]: 'At least one digit.',
  [`passwordCheck-${PW_MATCH}`]: 'Password is a match.',
  [`passwordCheck-${PW_CHANGED}`]: 'Password cannot be the same as the old one.',
  [`passwordCheck-${LENGTH}`]: `Between ${MIN_LENGTH} and ${MAX_LENGTH} characters.`,
  pw_new_different_than_old: 'The new password cannot be the same as the old one',
  incorrect_old_password: 'We could not verify the old password.',
  reason_pw_weak: 'Your password is too weak.',
  reason_pw_expired: 'Your password is expired.',
  please_update: 'Please update it now.',
  change_password: 'Change password',
  with_email: 'with Email',
  with_google: 'with Google',
  with_facebook: 'with Facebook',
}
