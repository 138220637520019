import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { InputGroup, FormControl } from 'react-bootstrap'
import { Check } from 'react-bootstrap-icons'

import i18n from '../../../lib/i18n'
import styling from '../../../lib/styling'
import constants from '../../../lib/constants'

import { Bold, H2, Normal, SubHeaderBar } from '../../../components/atoms/Fonts'
import Checkbox from '../../../components/atoms/Checkbox'
import LabelValuePair from '../../../components/molecules/LabelValuePair'

const {
  RIGHTS_OWNER,
  RIGHTS_CUSTOMER_SUPPORT,
} = constants.support
const {
  INTERNAL_PHONE,
} = constants.settings

const Container = styled.div`
  padding: 20px;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`
const Header = styled(Bold)`
  padding: 2px;
`
const Email = styled(Bold)`
  width: 40%;
`
const EmailHeader = styled(Bold)`
  width: 40%;
`
const Claim = styled.div`
  width: 10%;
`
const ClaimHeader = styled(Header)`
  width: 10%;
  overflow: hidden;
`
const PhoneNumber = styled.div`
  width: 30%;
`
const PhoneNumberHeader = styled(Header)`
  width: 30%;
`
const AddContainer = styled.div`
  padding: 20px;
  margin: 15px 0 20px;
  border: ${styling.colors.wine} solid 2px;
`
const Highlight = styled.span`
  color: ${styling.colors.wine};
  font-weight: 600;
`

const InternalUser = ({
  internalUsers,
  changeUserRighs,
  changeUserSettings,
  currentUser,
}) => {
  const rights = [RIGHTS_OWNER, RIGHTS_CUSTOMER_SUPPORT]
  const [users, setUsers] = useState(internalUsers)

  useEffect(() => {
    if (internalUsers) {

      const data = Object.keys(internalUsers).reduce((ass, key) => {
        const user = internalUsers[key]
        const { settings, ...auth } = user
        return {
          ...ass,
          [key]: {
            key,
            ...auth,
            internalPhone: settings.internalPhone || '',
          }
        }
      }, {})
      setUsers(data)
    }
  }, [internalUsers])

  const handlePhoneChange = (key, internalPhone) => {
    setUsers({
      ...users,
      [key]: {
        ...users[key],
        internalPhone,
      }
    })
  }

  if (!users) return <></>

  return <Container>
    <H2>
      {i18n.owner.internal_user}
    </H2>
    <AddContainer>
      <SubHeaderBar>Instructions</SubHeaderBar>
      <Normal>
        Until we add here more functionality to <Highlight>add a new internal user</Highlight>, go to Customer, find the new user there. Then change the User Rights for this user.
      </Normal>
      <Normal>
        Make sure to <Highlight>add a phone number</Highlight> to the active user, so the 2-factor authentication can work. Use the grey button to save the number. Refresh to confirm.
      </Normal>
      <Normal>
        If you change the permissions, <Highlight>refresh</Highlight> the page to see the updates.
      </Normal>
    </AddContainer>
    <Row style={{ borderBottom: `${styling.colors.slate} 1px solid` }}>
      <EmailHeader>Email</EmailHeader>
      {
        rights.map((right) => {
          return <ClaimHeader key={right}>
            {i18n.owner[`right-${right}`]}
          </ClaimHeader>
        })
      }
      <PhoneNumberHeader>Phone</PhoneNumberHeader>
    </Row>
    {
      Object.values(users)
        .sort((a, b) => a.email < b.email ? -1 : 1)
        .map(({ key, uid, email, customClaims, internalPhone }) => {
          const isSelf = currentUser.email === email
          const handleUserRightSelect = ({ key, checked }) => {
            // const { email: cEmail, uid, claims } = customer
            if (email && uid) {
              changeUserRighs(uid, {
                ...customClaims,
                [key]: checked
              }, email)
            }
          }
          return <Row key={uid}>
            <Email style={isSelf ? { color: styling.colors.wine } : {}}>{email}</Email>
            {
              rights.map((right) => {
                return <Claim key={`${uid}-${right}`}>
                  <Checkbox
                    onSelect={(checked) => handleUserRightSelect({ key: right, checked })}
                    selected={customClaims[right]}
                    disabled={isSelf}
                    icons={{
                      color: 'blue',
                    }}
                  />
                </Claim>
              })
            }
            <PhoneNumber>
              <InputGroup>
                <FormControl
                  placeholder="Phone number"
                  aria-label="Phonenumber"
                  aria-describedby="phone-number"
                  value={internalPhone}
                  onChange={(val) => handlePhoneChange(key, val.target.value)}
                />
                <InputGroup.Text
                  id="phone-number"
                  onClick={() => { changeUserSettings(uid, { [INTERNAL_PHONE]: internalPhone }) }}
                >
                  <Check />
                </InputGroup.Text>
              </InputGroup>
            </PhoneNumber>
          </Row>
        })
    }
    <SubHeaderBar
      style={{ marginTop: '10px' }}
    >Legend</SubHeaderBar>
    {
      rights.map((right) => {
        return <LabelValuePair
          key={`legend-${right}`}
          label={i18n.owner[`right-${right}`]}
          value={i18n.owner[`right-${right}-descr`]}
        />
      })
    }

  </Container>
}

export default InternalUser
