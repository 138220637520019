import React, { useState } from 'react'
import 'firebase/auth'
import firebase from 'firebase/app'
import styled from 'styled-components'

import '../../services/firebase'

import constants from '../../lib/constants'
import i18n from '../../lib/i18n'

import StrongPassword from '../../components/molecules/StrongPassword'
import { PasswordInput } from '../../components/atoms/Inputs'
import { Longbutton } from '../../components/atoms/Buttons'
import { defaultStatus, HeaderSection, Info } from '.'


const auth = firebase.auth();


const ResetContainer = styled.div`
  margin-bottom: 10px;
`
const EmailSpan = styled.span`
  font-weight: 600;
`

const ForgotPassword = ({
  oobCode,
  authStatus,
  email,
  setAuthStatus,
  isDesktop,
}) => {
  const [error, setError] = useState(null)
  const [newPassword, setNewPassword] = useState(null)
  const [isWeakPassword, setIsWeakPassword] = useState(true)

  const handleChangePassword = (val) => {
    setError(null)
    setNewPassword(val)
  }

  const handleResetPassword = async () => {
    if (!newPassword || isWeakPassword) return false
    try {
      await auth.confirmPasswordReset(oobCode, newPassword)
      setAuthStatus({...defaultStatus, done: true})
      return true
    } catch (e) {
      setError(e)
    }
  }

  const handleValidation = (isWeak) => {
    setIsWeakPassword(isWeak)
    isWeak && setError(i18n.auth.password_strength_1)
  }

  const renderResetHeader = () => <>
    <HeaderSection
      isDone={authStatus.done}
      text={authStatus.done ? i18n.auth.password_changed : i18n.auth.password_reset}
    />
    {
      email && <Info>{i18n.auth.for} <EmailSpan>{email}</EmailSpan></Info>
    }
  </>

  const renderExpired = () => <>
    <Info>{i18n.auth.password_reset_timeout}</Info>
    {/* TODO: button to resend change request */}
  </>

  const renderDone = () => <>
    <Info>{i18n.auth.password_reset_done}</Info>
  </>

  const renderResetPassword = () => <ResetContainer>
    <PasswordInput          
      onChange={handleChangePassword}
      value={newPassword}
      error={error}
      type={constants.form.PASSWORD}
      placeholder={i18n.auth.new_password}
    />
    <StrongPassword
      password={newPassword}
      onCheck={handleValidation}
      withBorder={false}
    />
    <Longbutton
      onClick={handleResetPassword}
      disabled={isWeakPassword}
      maxWidth={isDesktop ? '330px' : null}
    >
      {i18n.save}
    </Longbutton>
  </ResetContainer>

  return <>
    {renderResetHeader()}

    {
      authStatus.expired && renderExpired()
    }
    {
      authStatus.done && renderDone()
    }
    {
      authStatus.loggedIn && renderResetPassword()
    }
  </>

};

export default ForgotPassword
