import { isEmpty } from './index'

export default {
  getCurrentPrescription: (prescriptions, currentNDC) => {
    const isArray = Array.isArray(prescriptions)
    if (!prescriptions || isEmpty(prescriptions)) return null
    prescriptions = isArray ? prescriptions : Object.values(prescriptions)
    if (currentNDC) return prescriptions.find(({ ndc }) => currentNDC === ndc)
    const withStatus = !currentNDC && prescriptions.length > 1 && prescriptions[0].status
    if (withStatus) return prescriptions.find(({ status }) => status === 'active')
    return prescriptions[0]
  },
}

