import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'

const UserCard = ({ user }) => {
	return (
		<Card style={{width: '50rem'}}>
			<Card.Header>
				<Row>
					<Col>
						{user.profile.firstName}
					</Col>
				</Row>
			</Card.Header>
			<Card.Body>
				<Card.Title>
				</Card.Title>
			</Card.Body>
		</Card>
	)
}

export default UserCard
