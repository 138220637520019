import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  selectors,
  actions,
} from '../../store'

import i18n from '../../lib/i18n'
import styling from '../../lib/styling'
import constants from '../../lib/constants'

import {
  PageHeader,
} from '../../components/atoms/Fonts'
import Tabs from '../../components/atoms/Tabs'

import Reports from './tabs/Reports'
import QuickInfo from './tabs/QuickInfo'
import RxOrders from './tabs/RxOrders'

const {
  TAB_INFO,
  TAB_REPORTS,
  TAB_GGM_ORDERS,
} = constants.rx_dashboard

const Container = styled.div`
  background: ${styling.colors.white};
  margin: 20px auto;
  padding: 40px;
  width: 80%;
  border-radius: 15px;
`

const RxDashboard = ({
  rxUpdates,
  getMonthlyRxReport,
  monthlyRxReports,
  comments,
  getAllUserComments,
}) => {
  const rxStrings = i18n.rx_support

  const allTabs = [TAB_GGM_ORDERS, TAB_REPORTS, TAB_INFO]

  return <Container>
    <PageHeader>
      {rxStrings.header}
    </PageHeader>

    <Tabs
      tabId="rx-dashboard-tabs"
      allTabs={allTabs}
      strings={i18n.rx_support}
      tabData={{
        [TAB_REPORTS]: {
          Component: Reports,
          props: {
            getMonthlyRxReport,
            monthlyRxReports,
            comments,
            getAllUserComments,
          }
        },
        [TAB_GGM_ORDERS]: {
          Component: RxOrders,
          props: {
            rxUpdates,
          }
        },
        [TAB_INFO]: {
          Component: QuickInfo,
        },
      }}
    />
  </Container>
}

const RxDashboardPage = withRouter(connect(
  state => ({
    rxUpdates: selectors.rxUpdates(state),
    monthlyRxReports: selectors.monthlyRxReports(state),
    comments: selectors.comments(state),
  }),
  dispatch => {
    return {
      getMonthlyRxReport: (yearMonth) => actions.getMonthlyRxReport(dispatch, yearMonth),
      getAllUserComments: () => actions.getAllUserComments(dispatch),
    }
  },
)(RxDashboard))

export default RxDashboardPage