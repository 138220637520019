export default {
  // keys
  CATEGORY: 'category',
  FILTER_TYPE: 'flter_type',
  FILTERS: 'filters',
  FILTER: 'filter',
  DATA_TYPE: 'data_type',

  // category types
  CATEGORY_PURCHASE: 'category_purchase',

  // filter types
  FILTER_TYPE_INVOICE: 'invoice',
  FILTER_TYPE_INTERNAL: 'internal',

  // data types
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
}