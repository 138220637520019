import support from './support'
import form from './form'
import settings from './settings'
import pillPack from './pillPack'
import bs from './bootstrap'
import links from './links'
import banner from './banner'
import purchase from './purchase'
import alert from './alerts'
import errors from './errors'
import universal from './universal'
import rx_delivery from './rx_delivery'
import loading from './loading'
import validations from './validations'
import marketing from './marketing'
import analytics from './analytics'
import rx_dashboard from './rx_dashboard'
import comments from './comments'
import account from './account'
import owner from './owner'
import auth from './auth'

export default {
  support,
  form,
  settings,
  pillPack,
  bs,
  auth,
  links,
  banner,
  purchase,
  alert,
  errors,
  universal,
  rx_delivery,
  loading,
  validations,
  marketing,
  analytics,
  rx_dashboard,
  comments,
  account,
  owner,

  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',

  IN_PROGRESS: 'inProgress',
  DONE: 'done',
  CANCELED: 'canceled',
}
