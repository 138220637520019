import React from 'react'

import iconSrc from '../../../assets/images/svg/FSA-HSA_icon_long.svg'

const FSAandHSA = () => {

  return <span
    // href="emme.com" 
    // target="_blank" 
    // title="FSA+HSA Eligible" 
    // rel="noopener noreferrer"
  >
    <img src={iconSrc} alt="FSA+HSA Eligible" width="120" height="62" border="0"></img>
  </span>
}

export default FSAandHSA
