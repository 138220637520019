import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  selectors,
  actions,
} from '../../store'

import i18n from '../../lib/i18n'
import styling from '../../lib/styling'
import constants from '../../lib/constants'
import { isEmpty } from '../../lib/utils'

import {
  PageHeader,
} from '../../components/atoms/Fonts'
import Tabs from '../../components/atoms/Tabs'
import InternalUser from './tabs/InternalUser'

const {
  TAB_INTERNAL_USER,
} = constants.owner

const Container = styled.div`
  background: ${styling.colors.white};
  margin: 20px auto;
  padding: 40px;
  width: 80%;
  border-radius: 15px;
`

const OwnerTools = ({
  owner = {},
  getAllInternalUser,
  changeUserRighs,
  changeUserSettings,
  currentUser,
}) => {
  const allTabs = [TAB_INTERNAL_USER]

  useEffect(() => {
    if (isEmpty(owner.internal)) {
      getAllInternalUser()
    }
  }, [owner, getAllInternalUser])

  return <Container>
    <PageHeader>
      {i18n.owner.owner_tools}
    </PageHeader>
    <Tabs
      tabId="owner-tools"
      allTabs={allTabs}
      strings={i18n.owner}
      tabData={{
        [TAB_INTERNAL_USER]: {
          Component: InternalUser,
          props: {
            internalUsers: owner.internal,
            changeUserRighs,
            changeUserSettings,
            currentUser,
          }
        },
      }}
    />
  </Container>
}

const OwnerToolsPage = withRouter(connect(
  state => ({
    owner: selectors.owner(state),
    currentUser: selectors.auth(state),
  }),
  dispatch => ({
    getAllInternalUser: () => actions.getAllInternalUser(dispatch),
    changeUserRighs: (uid, rights, email) => actions.setUserRights(dispatch, uid, rights, email),
    changeUserSettings: (uid, settings) => actions.updateUserSettings(dispatch, uid, settings),
  }),
)(OwnerTools))

export default OwnerToolsPage
