import React from 'react'

import emailSentSrc from '../../../assets/images/illustrations/email-sent.png'

import i18n from '../../../lib/i18n'
import { useWindowDimensions } from '../../../lib/utils/windowDimensions'
import validation from '../../../lib/utils/validation'

import { InputWithValidation } from '../../atoms/Inputs'
import Dialog from '../../molecules/Dialog'
import { Bold, Normal } from '../../atoms/Fonts'



const ForgotPassword = ({
  account,
  onClose,
  sendResetEmail,
  email,
  errors,
  setEmail,
  handleErrors,
}) => {
  const { isSmOrSmaller } = useWindowDimensions()

  return <Dialog
    onClose={onClose}
    buttons={account?.emailSent ? [] : [{
      action: () => sendResetEmail(email),
      text: i18n.account.recover_password,
      disabled: email.length === 0 || errors.email,
    }]}
    image={{
      src: emailSentSrc,
      alt: i18n.image.email_sent_alt,
    }}
    style={{
      width: isSmOrSmaller ? '320px' : '460px',
      padding: isSmOrSmaller ? '20px' : '30px',
    }}
  >
    {
      account?.emailSent ? <>
        <Bold>{i18n.account.recovery_email_sent}</Bold>
        <Normal
          style={{
            fontSize: '14px',
            marginTop: '28px',
          }}
        >* {i18n.account.recovery_email_info}</Normal>
      </>
        :
        <>
          <Bold style={{ marginBottom: '15px' }}>
            {i18n.account.recovery_email}
          </Bold>
          <InputWithValidation
            placeholder={i18n.account.email_address}
            value={email}
            validations={[
              validation.isRequired,
              validation.regex(/.+@.+/, i18n.account.email_invalid),
            ]}
            onValidation={invalid => handleErrors(invalid, 'email')}
            onChange={newEmail => setEmail(newEmail)}
          />
        </>
    }
  </Dialog>
}

export default ForgotPassword
