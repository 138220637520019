import React from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'


import { selectors } from '../../store'
import config from '../../config'

import styling from '../../lib/styling'
import { useWindowDimensions } from '../../lib/utils/windowDimensions'

import { Bold, HeaderTitle, Normal } from '../atoms/Fonts'
import LabelValuePair from '../molecules/LabelValuePair'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 20px 30px 20px;
  border: 15px solid ${styling.colors.wine};
  margin: 5px;
  ${props => props.sm && css`
    width: 100%;
    padding: 30px 20px 60px 20px;
  `}
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  ${props => props.sm && css`
    width: 100%;
    flex-direction: column;
  `}
`

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  width: 33.33%;
  padding: 0 10px;
  ${props => props.sm && css`
    width: 100%;
    min-height: 40px;
  `}
  ${props => !props.sm && !props.isFirst && css`
    border-left: ${styling.colors.slate} 1px solid;
  `}
  ${props => props.sm && !props.isFirst && css`
    border-top: ${styling.colors.slate} 1px solid;
    margin-top: 15px;
  `}
`
const ColHeader = styled(Bold)`
  color: ${styling.colors.wine};
  margin-bottom: 5px;
`

const TestTool = ({
  auth,
}) => {
  const {
    isMdOrSmaller,
  } = useWindowDimensions()

  const inDev = config.ENV === 'development'

  if (!inDev) return <></>

  return <Container>
    <HeaderTitle>Development and Testing Tools</HeaderTitle>
    <Normal style={{
      paddingLeft: '40px',
      color: styling.colors.wine,
      borderBottom: `${styling.colors.slate} 1px solid`,
    }}
    >This is not seen by user. Refresh to see effect. </Normal>
    <InfoContainer sm={isMdOrSmaller}>
      <FlexCol isFirst sm={isMdOrSmaller}>
        <ColHeader>User data</ColHeader>
        <LabelValuePair
          label="UID"
          value={auth.uid}
        />
        <LabelValuePair
          label="Email"
          value={auth.email}
        />
      </FlexCol>
      <FlexCol sm={isMdOrSmaller} />
    </InfoContainer>
  </Container>
}

const ConnectedTestTool = connect(
  (state) => ({
    auth: selectors.auth(state),
  }),
  (dispatch) => ({
  }),
)(TestTool)

export default ConnectedTestTool
