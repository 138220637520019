export default {
  home_header: 'Birth control just got smarter',
  home_paragraph: 'Meet Emme, the game-changing system that empowers you to take and track your birth control pills — anywhere you go.',

  stop_offering: 'EmmeRx will stop offering prescriptions and pill delivery on',
  get_prescription: 'You can now get your pill prescription and convenient home delivery at',

  no_more_refills: 'If you choose not to do the switch there will be no further deliveries to you.',
  one_more_refill: 'If you choose not to do the switch your last refill will be on',

  sent_email_questions_1: 'We’ve sent you an e-mail with all the information you need about your EmmeRx subscription ending. Please reach out to us at',
  sent_email_questions_2: 'with any questions.',
  support: 'support@emme.com',

  click_here: 'Click here for your',
  order_status: 'Order Status',
}