import firebase from 'firebase/app'

import constants from '../../lib/constants'
import * as analytics from '../../lib/tools/analytics'

import actionType from './types'

const {
  WEB_CREATE,
  NEW_WEB_SESSION,
  FIRST_LOGIN,
} = constants.analytics

// call backend functions
export const callFn = (name, data = {}) => {
  const fn = firebase.functions().httpsCallable(name)
  return fn(data)
    .then(result => {
      return result.data
    })
}

export const stopLoading = (dispatch, type = 'general') => {
  dispatch({
    type: actionType.loading,
    payload: { [type]: false },
  })
}

export const startLoading = (dispatch, type = 'general') => {
  dispatch({
    type: actionType.loading,
    payload: { [type]: true },
  })
}

export const handleError = (dispatch, errorType, error, source) => {
  dispatch({
    type: actionType.error,
    errorType,
    payload: { error, source },
  })
}

export const initAnalytics = async (uid, data, onCreate = false) => {
  const {
    source,
    inRXOnboarding,
    internalUser,
  } = data
  if (!uid) return false
  const unique_id = uid.slice(0, 8)
  await analytics.identify(unique_id)
  analytics.track(NEW_WEB_SESSION, {
    source,
    user_type: internalUser ? 'EmmeTool' : 'EmmeRX',
    in_rx_onboarding: inRXOnboarding,
  })
  analytics.setOnce({
    $name: unique_id,
    [FIRST_LOGIN]: new Date().toISOString(),
  })
  if (onCreate) {
    analytics.track(WEB_CREATE)
  }
}
