import { createAction } from 'redux-actions'

export default {
	// updateUsers: createAction("updateUsers"),
	// updateTasks: createAction("updateTasks"),
	getCustomer: createAction('getCustomer'),
	updateCustomer: createAction('updateCustomer'),
	updateEmail: createAction('updateEmail'),
	setUserRights: createAction('setUserRights'),
	account: createAction('account'),
	getUniversalSettings: createAction('getUniversalSettings'),
	getUniversalOpenSettings: createAction('getUniversalOpenSettings'),
	loading: createAction('loading'),
	setBanner: createAction('setBanner'),
	error: createAction('error'),
	success: createAction('success'),
	newPurchase: createAction('newPurchase'),
	userSettings: createAction('userSettings'),
	invoices: createAction('invoices'),
	getFeatureSettings: createAction('getFeatureSettings'),
	rxDashboard: createAction('rxDashboard'),
	reports: createAction('reports'),
	comments: createAction('comments'),
	customerLookup: createAction('customerLookup'),
	prescriptions: createAction('prescriptions'),
	internalUser: createAction('internalUser'),
	twoFactor: createAction('twoFactor'),
	deleteAccount: createAction('deleteAccount'),
	setLifetimeFree: createAction('setLifetimeFree'),
}