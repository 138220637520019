import React, { Fragment } from 'react'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import {
  Alarm,
  Calendar2X,
} from 'react-bootstrap-icons'

import i18n from '../../../../lib/i18n'
import styling from '../../../../lib/styling'

import { Bold } from '../../../../components/atoms/Fonts'
import ValRow from '../../SharedComponents/ValRow'
import CommentRow from '../../SharedComponents/CommentRow'


const Container = styled.div`
  width: 100%;
  margin-top: 15px;
`
const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid ${styling.colors.wine};
`
const MainCol = styled.div`
  width: 33%;
  overflow: hidden;
`
const CommentContainer = styled.div`
  width: 100%;
  border-top: 1px dotted ${styling.colors.wine};
  overflow: hidden;
  padding: 10px 0;
`
const Legend = styled.div``


const PurchaseActivities = (({
  data,
  comments,
}) => {
  const str = i18n.rx_support
  if (!data) return <></>

  const getTimeSpan = (date1, date2) => {
    if (!date1 || !date2) return 0
    const start = DateTime.fromISO(date1)
    const end = DateTime.fromISO(date2)
    const diff = end.diff(start, 'hours').toObject().hours
    return diff
  }

  const getMaxTimeNumber = (defaultSpan, startDate) => {
    if (!startDate || !defaultSpan) return defaultSpan || 0
    const date = DateTime.fromISO(startDate)
    const weekday = date.weekday
    switch (weekday) {
      case 5:
        return defaultSpan + 48
      case 6:
        return defaultSpan + 24 + date.hours
      case 7:
        return defaultSpan + date.hours
      default:
        return defaultSpan
    }
  }

  const computeTMRisks = ({
    initiatedAt,
    intakeFormFinishedAt,
    pickedUp,
    finishedAt,
  }) => {
    const now = new Date().toISOString()
    const pickupSpan = getMaxTimeNumber(24, intakeFormFinishedAt)
    const finishSpan = getMaxTimeNumber(24, pickedUp)
    return {
      intakeForm: {
        overdue: getTimeSpan(initiatedAt, now) > 48 && !intakeFormFinishedAt,
      },
      pickUp: {
        overdue: getTimeSpan(intakeFormFinishedAt, now) > pickupSpan && !pickedUp,
        late: pickedUp && getTimeSpan(intakeFormFinishedAt, pickedUp) > pickupSpan,
      },
      result: {
        overdue: getTimeSpan(pickedUp, now) > finishSpan && !finishedAt,
        late: finishedAt && getTimeSpan(pickedUp, finishedAt) > finishSpan,
      },
    }
  }

  const computeRXRisks = ({
    tracking,
    pickedUp,
    finishedAt,
    initiatedAt,
  }) => {
    const now = new Date().toISOString()
    const pickupSpan = getMaxTimeNumber(24, initiatedAt)
    const finishSpan = getMaxTimeNumber(24, pickedUp)
    const trackingSpan = getMaxTimeNumber(24, finishedAt)
    return {
      pickUp: {
        overdue: getTimeSpan(initiatedAt, now) > pickupSpan && !pickedUp,
        late: pickedUp && getTimeSpan(initiatedAt, pickedUp) > pickupSpan,
      },
      result: {
        overdue: getTimeSpan(pickedUp, now) > finishSpan && !finishedAt,
        late: finishedAt && getTimeSpan(pickedUp, finishedAt) > finishSpan,
      },
      tracking: {
        overdue: getTimeSpan(finishedAt, now) > trackingSpan && (!tracking || !tracking.number),
      },
    }
  }

  const renderRiskSymbols = (risk) => {
    return <span>
      {
        risk.overdue && <Alarm color={styling.colors.orange} />
      }
      {
        risk.late && <Calendar2X color={styling.colors.orange} />
      }
    </span>
  }

  return <Container>
    <Row>
      <MainCol>
        <Bold>
          {str.activities_header_member}
        </Bold>
      </MainCol>
      <MainCol>
        <Bold>
          {str.activities_header_tm}
        </Bold>
      </MainCol>
      <MainCol>
        <Bold>
          {str.activities_header_rx}
        </Bold>
      </MainCol>
    </Row>
    {
      data.map(({
        isInternal,
        telemed,
        uid,
        rxDelivery,
        rxId,
        telemedId,
        hasPrescription,
        state,
        packsPerShipment,
      }) => {
        const tm = telemed[0]
        const tmRisks = computeTMRisks(tm)
        return <Row key={rxId}>
          {/* Member Col */}
          <MainCol>
            <ValRow label={str.activities_uid} value={uid} />
            <ValRow label={str.activities_state} value={state} />
            <ValRow label={str.activities_subscription} value={packsPerShipment} />
            <ValRow label={str.activities_internal} value={isInternal ? 'yes' : 'no'} />
          </MainCol>
          {/* Telemed Col */}
          <MainCol>
            <ValRow label={str.activities_tm_paid_at} value={tm.initiatedAt} />
            <ValRow label={str.activities_tm_invoice} value={tm.invoiceNum} />
            <ValRow label={str.activities_tm_vendor_id} value={telemedId} />
            <ValRow label={str.activities_tm_intakeform_done} value={tm.intakeFormFinishedAt} shownSymbol={renderRiskSymbols(tmRisks.intakeForm)} />
            <ValRow label={str.activities_tm_picked_up} value={tm.pickedUp} shownSymbol={renderRiskSymbols(tmRisks.pickUp)} />
            <ValRow label={str.activities_tm_finished} value={tm.finishedAt} shownSymbol={renderRiskSymbols(tmRisks.result)} />
            <ValRow label={str.activities_tm_result} value={tm.result} />
            <ValRow label={str.activities_tm_prescription} value={hasPrescription ? 'yes' : 'no'} />
          </MainCol>
          {/* RX Col */}
          <MainCol>
            <ValRow label={str.activities_rx_id} value={rxId} />
            {
              rxDelivery.map(rxData => {
                const rxRisks = computeRXRisks(rxData)
                const {
                  invoiceNum,
                  pickedUp,
                  initiatedAt,
                  finishedAt,
                  tracking,
                } = rxData
                return <Fragment key={invoiceNum}>
                  <ValRow
                    style={{ background: styling.colors.greypink }}
                    label={str.activities_rx_invoice}
                    value={invoiceNum}
                  />
                  <ValRow label={str.activities_rx_paid_at} value={initiatedAt} />
                  <ValRow label={str.activities_rx_picked_up} value={pickedUp} shownSymbol={renderRiskSymbols(rxRisks.pickUp)} />
                  <ValRow label={str.activities_rx_finished} value={finishedAt} shownSymbol={renderRiskSymbols(rxRisks.result)} />
                  <ValRow label={str.activities_rx_tracking} value={tracking?.number || 'n/a'} linkTo={tracking?.url} shownSymbol={renderRiskSymbols(rxRisks.tracking)} />
                </Fragment>
              })
            }
          </MainCol>
          <CommentContainer>
            <Bold>Comments</Bold>
            {
              comments && comments[uid] && <CommentRow isHeader />
            }
            {
              comments && comments[uid] && comments[uid].map((comment) => {
                return <CommentRow
                  key={comment.commentAt}
                  {...comment}
                />
              })
            }
          </CommentContainer>
        </Row>
      })
    }
    <Legend>
      <Bold>Legend</Bold>
      <ValRow label={<Alarm />} value='Overdue task' />
      <ValRow label={<Calendar2X />} value='Task finished late' />
    </Legend>
  </Container>
})

export default PurchaseActivities
