import constants from '../constants'
import stringUtils from '../utils/strings'

const {
  EVENT_PURCHASE,
  EVENT_INTAKE_AVAILABLE,
  EVENT_INTAKE_SUCCESS,
  EVENT_REVIEW_START,
  EVENT_REVIEW_END,
  EVENT_REVIEW_ID_ERROR,
  EVENT_REVIEW_REF_OUT,
  EVENT_REVIEW_AUTO_REF_OUT,
  EVENT_REVIEW_DIAGNOSED,
  PURCHASE,
  INTAKE,
  REVIEW,
  RESULT,
  ADDRESS_BILLING,
  ADDRESS_HOME,
  ADDRESS_SHIPPING,
  BUTTON_INTAKE_FORM,
  BUTTON_SUMMARY,
  BUTTON_TRACKING,
  PILLS_CASE_COMBO,
  PILLS_ONLY,
  EMME_STATUS_INTAKE_EXP,
  EMME_STATUS_INTAKE_VOIDED,
} = constants.purchase

const {
  RX_ORDER_NEW,
  RX_ORDER_PROCESSING,
  RX_ORDER_COMPLETE_TRACKING,
  RX_ORDER_CANCELED,
  RX_ORDER_PAST,
} = constants.rx_delivery

const invoice_num_line = (invoiceId) => `Invoice number: ${invoiceId}`

export default {
  latest_updates: 'Latest Updates',
  more_service: {
    header: 'About EmmeRx',
    list: [
      'You will receive a prescription if medically approved within 1 business day after submitting your intake form.',
      'Your medication will be delivered 2-7 days after the prescription is approved.',
      'You will receive emails from us and from our healthcare partners (Wheel, GoGoMeds) to update you on the status.',
      'Once the process is completed, you will get a text message.',
    ]
  },
  more_intake: {
    header: 'Before Starting the Intake Form',
    list: [
      'The form should take no longer than 15 minutes to complete.',
      'Make sure you have a valid photo ID such as a driver\'s license or a passport.',
      'We recommend having a good internet connection.',
      'If you exit the consultation prior to completion, your information will not be saved.',
    ]
  },
  more_my_purchase: {
    header: 'My Order',
    list: [],
  },
  more_expiration_date: (date) => `Prescription Expiration Date: ${date}`,
  more_subscription_type: (num) => `Subscribed to ${num} pack${num === 1 ? '' : 's'} per delivery`,
  more_clinician_recommendation: (text) => `Instructions from Clinician: ${text}`,
  no_purchase: 'To start a new order, ',
  no_purchse_click: 'click here!',
  emme_ecommerce_url: 'Emme.com',
  ongoing_onboarding: 'You have not finished your Onboarding. ',
  ongoing_onboarding_link: 'Go back!',
  not_eligible: (reason) => `You are not eligible for this product. Reason: ${reason}`,
  reason_state: (state) => `We don't service your state (${state}) yet.`,
  reason_pregnant: 'You are currently pregnant.',
  rx_disclaimer: 'By clicking "continue", you agree to have your prescription medication fulfilled by a third party pharmacy that we\'ve partnered with to provide birth control pills.',

  order_status: 'Order Status',
  // expected_result: 'Expected result:',
  // expected_result_within: 'within 24 hours',
  date_time: (date, time) => `${date} at ${time}`,

  [`header_${PURCHASE}`]: 'Prescription Requested',
  [`header_${INTAKE}`]: 'Intake Form',
  [`header_${REVIEW}`]: 'Review by Clinician',
  [`header_${RESULT}`]: 'Shipments',

  [EVENT_PURCHASE]: ({ price }) => ({
    event: 'Purchase Placed',
    paragraphs: [
      'Thank you for your order!',
      `You paid $${price} USD.`
    ],
  }),
  [EVENT_INTAKE_AVAILABLE]: () => ({
    event: 'Intake Form Available',
    paragraphs: [
      'The next step is to complete your intake form. Please note that this step is required so the consultation can be processed.',
    ],
    buttonText: 'Start here',
  }),
  [EMME_STATUS_INTAKE_EXP]: () => ({
    event: 'Intake Form Expired',
    paragraphs: [
      'Your intake form has expired, so we have refunded your account. If you would like to start a new consultation, you can place a new order.',
    ],
  }),
  [EMME_STATUS_INTAKE_VOIDED]: () => ({
    event: 'Order Refunded',
    paragraphs: [
      'We don\'t have a clinician available for your appointment so we have processed a refund. Thank you for your understanding! Please contact support@emme.com in 3-5 days if you don\'t see the refund.',
    ],
  }),
  [EVENT_INTAKE_SUCCESS]: () => ({
    event: 'Intake Form Completed',
    paragraphs: [
      'You finished the intake form successfully.',
    ],
    buttonText: ' Review details',
  }),
  [EVENT_REVIEW_START]: () => ({
    event: 'Clinician Assigned',
    paragraphs: [
      'A clinician has been assigned and will start to work on your consultation soon.',
    ],
  }),
  [EVENT_REVIEW_END]: () => ({
    event: 'Consultation Completed',
    paragraphs: [
      'The clinician has finished the consultation based on your intake form.',
    ],
  }),
  [EVENT_REVIEW_DIAGNOSED]: () => ({
    event: 'Prescription Approved',
    paragraphs: [
      'The clinician approved the prescription and sent it to our partner pharmacy for free delivery.',
    ],
    buttonText: 'Check results',
  }),
  [EVENT_REVIEW_ID_ERROR]: () => ({
    event: 'Consultation Declined',
    paragraphs: [
      'The clinician has declined the consultation based on a lack of sufficient photo identification.',
    ],
    buttonText: 'More info',
  }),
  [EVENT_REVIEW_REF_OUT]: () => ({
    event: 'Medical Result',
    paragraphs: [
      'After reviewing your intake form, the clinician determined you need to see a medical specialist in person.',
    ],
    buttonText: 'More info',
  }),
  [EVENT_REVIEW_AUTO_REF_OUT]: () => ({
    event: 'Order Declined',
    paragraphs: [
      'The intake form found you aren\'t a candidate for birth control.',
    ],
    buttonText: 'More info',
  }),

  [RX_ORDER_NEW]: () => ({
    event: 'Prescription Received',
    paragraphs: [
      `Your order is being prepared by our partner pharmacy and will ship soon.`,
    ]
  }),
  [RX_ORDER_PROCESSING]: ({ invoiceId, orderNumber }) => ({
    event: `Order #${orderNumber} In Process`,
    paragraphs: [
      `Your order is being prepared by our partner pharmacy and will ship soon.`,
      invoice_num_line(invoiceId),
    ]
  }),
  [RX_ORDER_COMPLETE_TRACKING]: ({ orderNumber }) => ({
    event: `Order #${orderNumber} Shipped`,
    paragraphs: [
      `Your order has been shipped.`,
    ],
    buttonText: 'Track Order'
  }),
  [RX_ORDER_CANCELED]: () => ({
    event: 'Order Canceled',
    paragraphs: [
      `Your order has been canceled.`,
    ]
  }),
  [RX_ORDER_PAST]: ({ pastFills }) => ({
    event: 'Past Orders',
    paragraphs: pastFills,
  }),
  rx_order_past_title: (num) => `Order #${num}`,
  rx_order_past_date: (date) => `Processed on ${date}`,
  rx_order_past_invoice: (invoiceId) => invoice_num_line(invoiceId),

  status_refund_text: 'To check the status of your refund ',
  status_refund_click_here: 'click here.',
  refund_subject: (orderId) => `Refund for order ${orderId}`,
  status_cancel_text: 'Your order was canceled. For more information, please ',
  status_cancel_contact: 'contact us.',
  cancel_subject: (orderId) => `About canceled order ${orderId}`,

  // Pre-Purchase
  state_header: 'Birth control at your door',
  state_top: 'Let’s get started',
  state_info: 'Select the state you live in.',
  which_state_not_serviced: 'Your state is not eligible for EmmeRx currently, but we’re quickly expanding coverage. Enter your email address below to get notified when your state is eligible.',
  paused_emme_rx: 'We apologize, but currently there are no consultations available in your state. Enter your email address below to get notified when a clinician is available for your next appointment.',
  newsletter_signed_up_insurance: 'Thanks for signing up! We’ll let you know when insurance becomes available.',
  newsletter_signed_up_state: 'Thanks for signing up! We’ll let you know when your state is eligible for EmmeRx.',

  /** Quick questions */
  quick_header: 'Help us prepare for your online consultation',
  quick_top: 'Let’s get started.',
  quick_info: 'This information will be kept confidential and is used to make sure you are eligible for service on our platform.',
  quick_gender_question: 'What’s your biological sex?',
  quick_gender_info: 'Biological sex is what you\'re given at birth based on medical factors such as anatomy and chromosomes.',
  quick_gender_female: 'Female',
  quick_gender_male: 'Male',
  quick_gender_intersex: 'Intersex',
  quick_age_question: 'What age are you?',
  quick_age_under_18: 'Less than 18',
  quick_age_over_18: 'More than 18',
  quick_pregnant_question: 'Are you currently pregnant?',
  quick_pregnant_yes: 'Yes',
  quick_pregnant_no: 'No',
  quick_reject_info: 'Hi! Based on your responses above, you are currently ineligible to receive an online consultation for birth control pills with Emme. If something changes in the future, we’ll be happy to see you back!',
  quick_I_agree: 'I agree to the ',
  quick_and: ' and ',
  quick_terms: 'Terms of Use',
  quick_privacy: 'Privacy Policy',
  quick_telehealth: 'Telehealth Informed Consent',
  quick_practiced: 'Notice of Privacy Practices',
  quick_first_name: 'First Name',
  quick_last_name: 'Last Name',
  quick_dob: 'Date of Birth (mm/dd/yyyy)',

  /** Phone */
  phone_header_num: 'Enter your phone number',
  phone_top_num: 'Now, your number',
  phone_info_num: 'We’ll send you a 6-digit code via SMS text message to verify your phone number.',
  // phone_info_num: (env) => `We’ll send you a  6-digit code via SMS text message to verify your phone number. The text will say “###### is your verification code for ${env === 'production' ? 'cadence-cf5c6.web.app' : 'cadence-dev-d7353.web.app'}.”, where ###### represents the 6-digit code.`,
  phone_header_verify: 'Enter your code',
  phone_top_verify: 'Verify',
  phone_info_verify: 'Please enter the 6-digit code sent to this number.',

  /** Info */
  info_header: 'Welcome to EmmeRx',
  info_top: 'How it works',
  // info_text: [
  //   'On the next page, you’ll start the checkout process to order a telemedicine consultation for birth control pills with EmmeRx.',
  //   'After providing your personal info, you’ll have the opportunity to choose your subscription plan for monthly or quarterly shipments.',
  //   'If you are prescribed the pill, your order will be shipped automatically.',
  // ],
  info_text: [
    {
      title: 'Add your personal information and choose your subscription plan',
      body: 'Your information will be kept confidential, and we’ll send you text messages about your order status at the phone number you provide. Your online medical consultation will be $20 per year, and you’ll get birth control pills for as low as $15 per pack.',
    },
    {
      title: 'Answer a few health questions and get your prescription',
      body: 'Your health history (and your preferred pill type, if you have one) will help our clinicians determine the right pill for you. A licensed doctor will review your information and write you a prescription if medically approved. Check out our available <%pill_brands%>.',
      links: {
        pill_brands: {
          text: 'pill brands',
          link_to: 'https://emme.com/pill-brands',
        }
      },
    },
    {
      title: 'Get pills delivered for free, plus automatic refills\n',
      body: 'No reminders necessary. Your pills and refills will be delivered on time, every time.',
    }
  ],
  info_insurance: '* We don’t accept insurance at this time.',
  info_insurance_notify: 'Click here if you want to be notified when we accept insurance.',
  info_insurance_email: 'Your email address',

  //** Shipping Membership Type */
  membership_type_header: 'Choose your membership',
  membership_type_top: 'Before checkout',
  membership_type_info: 'You\'re about to start the checkout process. Aside from your membership, you\'ll pay a $20 one-time medical consultation fee, which will cover a prescription for 12 months of refills. If approved, your medication will be automatically delivered based on the birth control pill prescribed.',
  [`membership_type_header_${PILLS_CASE_COMBO}`]: 'The Whole Package',
  [`membership_type_text_${PILLS_CASE_COMBO}`]: [
    {
      key: `${PILLS_CASE_COMBO}_packs`,
      header: '3 packs shipped every 2-3 months',
      info: 'Depending on pill prescription',
    },
    {
      key: `${PILLS_CASE_COMBO}_refills`,
      header: 'Automatic refills for 1 year',
      info: 'If prescription is approved',
    },
    {
      key: `${PILLS_CASE_COMBO}_case`,
      header: 'Smart Case *',
      info: 'To track your pill-taking automatically',
      footnote: '* We’ll email you to choose your Smart Case color once your prescription is approved.',
    },
    {
      key: `${PILLS_CASE_COMBO}_shipment`,
      header: 'Free shipping',
    },
  ],
  [`membership_type_price_${PILLS_CASE_COMBO}`]: '$19',
  [`membership_type_header_${PILLS_ONLY}`]: 'Just the Pills',
  [`membership_type_text_${PILLS_ONLY}`]: [
    {
      key: `${PILLS_CASE_COMBO}_packs`,
      header: '3 packs shipped every 2 - 3 months',
      info: 'Depending on pill prescription',
    },
    {
      key: `${PILLS_CASE_COMBO}_refills`,
      header: 'Automatic refills for 1 year',
      info: 'If prescription is approved',
    },
    {
      key: `${PILLS_CASE_COMBO}_shipment`,
      header: 'Free shipping',
    },
  ],
  [`membership_type_price_${PILLS_ONLY}`]: '$15',
  [`membership_type_savings_${PILLS_ONLY}`]: 'Save 25%',
  membership_type_per_pack: 'per pack',
  membership_type_autopay_info: '(auto-pay every 2-3 months)',
  membership_type_most_popular: 'Most Popular',
  continue_checkout: 'Continue checkout',

  /** Checkout */
  checkout_account: 'Account',
  checkout_shipping: 'Shipping',
  checkout_payment: 'Payment',

  checkout: 'Checkout',
  checkout_save_address: 'Save & Continue',
  checkout_card_number: 'Card number',
  checkout_expiry: 'MM/YY',
  checkout_cvc: 'CVC/CVV',
  continue_to: (page) => `Continue to ${page}`,
  return_to: (page) => `Return to ${page}`,
  pay_now: (price) => `Place order today - $${price}`,
  checkout_contact_info: 'Contact information',
  checkout_marketing: 'Keep me up to date on news and exclusive offers',
  checkout_shipping_address: 'Shipping address',
  checkout_home_address: 'Home address',
  is_also_home: 'This is my home address',
  checkout_use_shipping_address: 'Use my shipping address',
  checkout_use_different_address: 'Use a different address',
  contact: 'Contact',
  ship_to: 'Ship to',
  home: 'Home',

  order_summary: 'Order Summary',
  cart_telemed: 'Medical consultation',
  cart_telemed_info: '$15 consultation + $5 service fee (valid for 12 months)',
  cart_total: 'Total due today',

  one_year_subscription: '1-Year Subscription',
  membership: 'Membership',
  subscription_info_1: ({ quantity, price }) => `Auto-pay $${quantity * price} every 2-3 months`,
  subscription_info_2: ({ quantity, price }) => `${quantity} pack${quantity === 1 ? '' : 's'} per delivery ($${price}/pack)`,
  subscription_info_3: () => `Automatic refills for 1 year`,
  subscription_info_4: () => `Free shipping`,
  subscription_info_5: () => `Cancel your subscription anytime by contacting us at`,
  subscription_info_case_pill_combo_1: () => `Smart case`,
  subscription_info_bottom: 'You won’t be charged for or receive birth control pills until your prescription is approved by our medical team.',

  subscription: 'Subscription',
  subscription_sequence: (seq) => `${seq} pack${seq !== 1 ? 's' : ''} per shipment`,
  label_frequence: (seq) => {
    const addition = seq === 1 ? '' : 'for 25% discount'
    return `Ship ${seq} ${stringUtils.simplePlural(seq, 'pack')} at a time ${addition}`
  },
  description_frequence_1: (price) => `$${price} per pack`,
  description_frequence_3: (price) => `$${price} per pack ($${price * 3} per shipment)`,
  checkout_agree_to_subscription: (seq, price) => `Automatically billed for each refill while your prescription is valid.`,
  product_telemed_header: 'Telemedicine Consultation',
  product_rxtelemed_header: (packCount) => `Rx Birth Control Pill Delivery – ${packCount} Pack${packCount === 1 ? '' : 's'}`,
  product_rxtelemed_description: 'with Telemedicine Consultation',
  discount_code: 'Discount Code',
  invalid_discount_code: 'This Discount Code is invalid.',
  consultation: 'Consultation',
  service_fee: 'One-time Service Fee',
  shipping: 'Shipping',
  telemed: 'Telemedicine Consultation',
  cart_promoCode: 'Discount code',
  pillPack: ({ price, quantity }) => `${quantity} Pack${quantity === 1 ? '' : `s ($${price} per pack)`}`,
  service: 'One-time Service Fee',
  taxes: 'Taxes',
  total: 'Total due now',
  free: 'Free',
  usd: 'USD',
  subscription_total: 'Charged at shipping',
  bill_telemed_header: 'Due now',
  bill_rx_header: 'Due at shipping',
  show_summary: 'Show order summary',
  hide_summary: 'Hide order summary',

  payment: 'Payment',
  credit_card: 'Credit card',
  cardholder: "Card holder`s name",
  payment_byline: 'All transactions are secure and encrypted.',
  billing_address: 'Billing address',
  billing_byline: 'Select the address that matches your card or payment method.',
  [`billing_same_as_${ADDRESS_SHIPPING}`]: '	Same as shipping address',
  [`billing_same_as_${ADDRESS_HOME}`]: '	Same as home address',
  [`billing_same_as_${ADDRESS_BILLING}`]: 'Use a different billing address',

  [`button_${BUTTON_INTAKE_FORM}`]: 'Start here',
  [`button_${BUTTON_SUMMARY}`]: 'Review Consultation',
  [`button_${BUTTON_TRACKING}`]: 'Recent Tracking Number',

  [`info-${EMME_STATUS_INTAKE_EXP}`]: 'Intake Form expired',
  [`info-${EMME_STATUS_INTAKE_VOIDED}`]: 'Intake Form voided',
}