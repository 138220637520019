import React from 'react'

import iconSrc from '../../../assets/images/cert-vendor-logos/logo-hipaa.png'

const Hipaa = () => {

  return <a 
    href="https://compliancy-group.com/hipaa-compliance-verification/" 
    target="_blank" 
    title="Verify HIPAA Approval" 
    rel="noopener noreferrer"
  >
    <img src={iconSrc} alt="HIPAA verified" width="120" height="62" border="0"></img>
  </a>
}

export default Hipaa
