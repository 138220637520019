import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import TaskList from '../components/TaskList'
import { Container, Row } from 'react-bootstrap'
import {Link} from "react-router-dom"
import { useFirestore } from 'react-redux-firebase'
import _ from 'lodash'

const TasksPage = () => {
	const tasks = useSelector(state => (state.firestore && state.firestore.ordered) ? state.firestore.ordered.tasks : [])
	const firestore = useFirestore()

	const tasksListenerSettings = {
		collection: '/rx/root/tasks',
		orderBy: ['performAt', 'asc'],
		limit: 10,
		storeAs: 'tasks',
	}

	useEffect(() => {
		firestore.setListener(tasksListenerSettings)
		return function cleanup() {
			firestore.unsetListener(tasksListenerSettings)
		}
	}, [firestore, tasksListenerSettings])

	// Check if we need to clean up the id on any of the tasks
	// There is a bug in the current version of redux-firestore that leads to ordered items losing their id after updates if it changes the sorting order of the list
	// To fix the problem we unset and reset the listener to force a rebuilding of the ordered data object
	// Some details here: https://github.com/prescottprue/redux-firestore/issues/254
	//  Colin Liotta (6/05/2020)
	if (tasks && _.some(tasks, task => !task.id)) {
		firestore.unsetListener(tasksListenerSettings)
		firestore.setListener(tasksListenerSettings)
	}

	return (
		<Container>
			<Row>
				<Link to="/">Back</Link>
			</Row>
			<h2>Tasks</h2>
			<hr />

			<TaskList tasks={tasks} />
		</Container>
	)

}

export default TasksPage;