import constants from '../constants'

const {
  ERROR_PAYMENT_CARD_CHARGE,
  ERROR_PAYMENT_PROFILE_CHARGE,
  ERROR_PAYMENT_PROFILE_CREATE,
  ERROR_PAYMENT_PROFILE_UPDATE,
  ERROR_RX_ORDER,
  // ERROR_TM_ORDER,
} = constants.errors

export default {
  purchase_different_account: '',
  not_found: '',

  // INPUT errors
  required_field: 'This field is required',
  error_length: ({ min, max, exact }) => {
    if (exact) return `Exact ${exact} characters needed.`
    if (min & max) return `Min ${min} and max ${max} characters needed.`
    if (min) return `At least ${min} characters needed.`
    if (max) return `Maximal ${max} characters allowed.`
    return ''
  },
  incorrect_format: 'The format is incorrect.',
  type_error: (expectedType) => `Incorrect data type. Expected: ${expectedType}.`,
  phone: 'This is not a valid US phone number.',
  string_compare: 'Incorrect value.',
  default_error: 'Something went wrong.',
  incorrect_code: 'The code is incorrect.',
  internal_error: 'Sorry, something went wrong. Please let us know if this issue presist.',

  [`hints_${ERROR_RX_ORDER}`]: [
    'When creating an order for this member, Gogomeds found an issue and rejected the incoming order.',
    'This user has been charged.',
    'Fix the issue, then retrigger the order.',
  ],
  [`hints_${ERROR_PAYMENT_CARD_CHARGE}`]: [
    'This can be mostlikely be ignored.',
    'A user tried to charge their credit card but got rejected.',
    'The user saw in the UI the outcome and had resolved the issue on their own.'
  ],
  [`hints_${ERROR_PAYMENT_PROFILE_CHARGE}`]: [
    'We tried to charge the profile in Authorize.net, but the card on file got rejected.',
    'Contact this user. They either need to work with their bank to let us charge them or give us their new credit card',
    'This can be a repeating error, since we try to charge the profile every day.',
    'Do not change the credit card in Authorize.net. Use the EmmeTool or it will not work.',
    'Once the issue is resolved, you can either wait for this to be retriggered the next day, or retrigger the order right away and make sure the one for tomorrow is canceled.',
  ],
  [`hints_${ERROR_PAYMENT_PROFILE_CREATE}`]: [
    'When the Authorize profile was created, something failed.',
    'Please let the developer know, if they are aware of this.',
    'We have not had this issue before and we might need a new protocol to solve it.',
  ],
  [`hints_${ERROR_PAYMENT_PROFILE_UPDATE}`]: [
    'Something went wrong when the profile was updated.',
    'This has happened right before an recurring payment attempt and has most likely stopped that.',
    'Let the developer know about this. We migth need to update the database and Authorize.net before we can retrigger the order.',
  ],
  [`text_${ERROR_RX_ORDER}`]: 'RX order failed',
  [`text_${ERROR_PAYMENT_CARD_CHARGE}`]: 'Payment fail: New card rejected',
  [`text_${ERROR_PAYMENT_PROFILE_CHARGE}`]: 'Payment fail: Autocharge rejected',
  [`text_${ERROR_PAYMENT_PROFILE_CREATE}`]: 'Payment profile: Creation fail',
  [`text_${ERROR_PAYMENT_PROFILE_UPDATE}`]: 'Payment profile: Update fail',
}