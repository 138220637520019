import constants from '../constants'

const {
  TAB_PROMO_CODE,
} = constants.marketing

export default {
  marketing_tools: 'Markeing Tools',
  promo_code: 'Promo Code',
  discount: 'Discount',
  last_update: 'Last update',
  [`tab_${TAB_PROMO_CODE}`]: 'Promo Codes',
}
