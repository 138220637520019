import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import i18n from '../../../lib/i18n'
import constants from '../../../lib/constants'
import styling from '../../../lib/styling'

import CheckItem from './CheckItem'

const {
  LOWERCASE,
  UPPERCASE,
  SPECIAL_CHAR,
  DIGIT,
  LENGTH,
  MIN_LENGTH,
  MAX_LENGTH,
  PW_CHANGED,
} = constants.account

const Container = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  ${props => props.withBorder && css`
    border: solid 1px ${styling.colors.blue};
    border-radius: 5px;
  `}
`

const StrongPassword = ({
  password = '',
  onCheck,
  oldPassword,
  style = {},
  withBorder = true,
}) => {
  const [rules, setRules] = useState({
    ...oldPassword ? {
      [PW_CHANGED]: false,
    } : {},
    [LENGTH]: false,
    [LOWERCASE]: false,
    [UPPERCASE]: false,
    [SPECIAL_CHAR]: false,
    [DIGIT]: false,
  })

  useEffect(() => {
    if (password) {
      const rulesCheck = {
        ...oldPassword ? {
          [PW_CHANGED]: password !== oldPassword,
        } : {},
        [LENGTH]: password.length >= MIN_LENGTH && password.length <= MAX_LENGTH,
        [LOWERCASE]: password.match(/[a-z]/),
        [UPPERCASE]: password.match(/[A-Z]/),
        [SPECIAL_CHAR]: password.match(/[!@#$%&*^]/),
        [DIGIT]: password.match(/\d/),
      }
      setRules(rulesCheck)
      const weakSpots = Object.values(rulesCheck).filter((isStrong) => !isStrong)
      onCheck(weakSpots.length !== 0)
    }
  }, [password, onCheck, oldPassword])

  return <Container style={style} withBorder={withBorder}>
    {
      Object.entries(rules).map(([key, isStrong]) => {
        return <CheckItem
          key={key}
          isStrong={isStrong}
          text={i18n.account[`passwordCheck-${key}`]}
        />
      })
    }
  </Container>
}

export default StrongPassword