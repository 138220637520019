import React from 'react'
import styled, { css } from 'styled-components'
import { DateTime } from 'luxon'
import styling from '../../../lib/styling'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 12px;
  overflow-wrap: break-word;
`
const Col = styled.div`
  width: 15%;
  padding: 2px 5px;
  ${props => props.isHeader && css`
    font-weight: bold;
    color: ${styling.colors.wine};
  `}
`
const DateCol = styled(Col)``
const NameCol = styled(Col)``
const CommentCol = styled(Col)`
  width: 70%;
`

const CommentRow = (({
  comment,
  commentBy,
  commentAt,
  isHeader = false,
}) => {
  const formatDate = (date) => {
    if (!date) return 'n/a'
    const dateTime = typeof(date) === 'string' ? DateTime.fromISO(date) : DateTime.fromJSDate(date)
    return dateTime.toLocaleString(DateTime.DATETIME_MED)
  }
  if (isHeader) {
    return <Row>
      <DateCol isHeader>Date</DateCol>
      <NameCol isHeader>Author</NameCol>
      <CommentCol isHeader>Comment</CommentCol>
    </Row>
  }
  return <Row>
    <DateCol>{formatDate(commentAt)}</DateCol>
    <NameCol>{commentBy}</NameCol>
    <CommentCol>{comment}</CommentCol>
  </Row>
})

export default CommentRow
