import React from 'react'
import styled from 'styled-components'

import styling from '../../lib/styling'
import i18n from '../../lib/i18n'

import Overlay from '../atoms/Overlay'
import { IconButton } from '../atoms/Buttons'

import closeSrc from '../../assets/images/icon-close.png'
import backSrc from '../../assets/images/icon-arrow-short.png'


const ModalContainer = styled.div`
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`
const ModalBox = styled.div(() => {
  return `
    z-index: 10000;
    background: ${styling.colors.white};
    width: 330px;
    min-height: 330px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-top: 20px;
    margin-top: -70px;
  `
})

const Modal = ({
  onClose = false,
  onBack = false,
  children,
  style = {}
}) => {
  return <>
    {onClose ? <Overlay onClick={onClose} /> : <Overlay />}
    <ModalContainer>
      <ModalBox style={style}>
      {
          onBack && <IconButton
            onClick={onBack}
            src={backSrc}
            alt={i18n.image.back_alt}
            style={{
              marginTop: '5px',
              paddingLeft: '10px',
              display: 'block',
              alignSelf: 'flex-start',
              transform: 'rotate(90deg)',
            }}
          />
        }
        {
          onClose && <IconButton
            onClick={onClose}
            src={closeSrc}
            alt={i18n.image.close_alt}
            style={{
              marginTop: onBack? '-25px' : '5px',
              paddingLeft: '10px',
              display: 'block',
              alignSelf: 'flex-end',
            }}
          />
        }
        {children}
      </ModalBox>
    </ModalContainer>
  </>
}

export default Modal;