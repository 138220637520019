import React from 'react'
import { useSelector } from 'react-redux'
import { selectors } from '../store'
import { Container } from 'react-bootstrap'
import MainHeader from '../components/organisms/MainHeader'
import MainFooter from '../components/organisms/MainFooter'
import constants from '../lib/constants'
import styled from 'styled-components'
import Banner from '../components/atoms/Banner'
import MainBody from '../components/atoms/Body'
import { withRouter } from 'react-router-dom'
import routes from '../routes/routes'
import TestTool from '../components/organisms/TestTool'

const {
	RIGHTS_OWNER,
	RIGHTS_CUSTOMER_SUPPORT,
} = constants.support

const StyledContainer = styled(Container)`
	padding: 0;
	margin: 0;
	height: 100%;
	width: 100%;
	display: flex;
  flex-direction: column;
	min-height: 100%;
	min-width: 100%;
`

const ScrollableContainer = styled.div`
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding: 0;
`

const MainContainer = ({ children, location }) => {
	const auth = useSelector(selectors.auth)
	const authClaims = useSelector(selectors.authClaims)
	const banner = useSelector(selectors.banner)
	const inPurchaseOnboarding = location.pathname.includes(routes.purchase)
	const internalUser = authClaims[RIGHTS_OWNER] || authClaims[RIGHTS_CUSTOMER_SUPPORT]

	return <StyledContainer>
		{
			auth && auth.uid && !inPurchaseOnboarding &&
			<MainHeader
				isInternal={internalUser}
				authClaims={authClaims}
			/>
		}
		{
			banner && banner.type && !internalUser &&
			<Banner type={banner.type} props={banner.props} />
		}
		<ScrollableContainer>
			<MainBody>
				{children}
			</MainBody>
			<MainFooter />
			<TestTool />
		</ScrollableContainer>
	</StyledContainer>
}

export default withRouter(MainContainer)
