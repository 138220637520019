import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'
import actionType from './types'

import {
  callFn,
} from './utils'

const httpFunctions = {
  getCustomer: 'getCustomer',
  lookupCustomerData: 'lookupCustomerData',
  deleteAccount: 'stageAccountForDeletion',
  setLifetimeFree: 'setLifetimeFree',
  updateEmail: 'updateEmail',
}

export const getCustomerByEmail = (dispatch, email) => {
  callFn(httpFunctions.getCustomer, { email })
    .then(response => {
      if (response.error) {
        dispatch({
          type: actionType.getCustomer,
          payload: { error: response.error, email },
        })
      } else {
        dispatch({
          type: actionType.getCustomer,
          payload: response,
        })
      }
    })
    .catch(error => {
      dispatch({
        type: actionType.getCustomer,
        payload: { error, email },
      })
    })
}

export const lookupCustomer = (dispatch, data) => {
  //ZXSmlJ9UBBgzb8U7GapPG812b4T2
  if (!(data.uid || data.serialnumber)) return false
  callFn(httpFunctions.lookupCustomerData, data)
    .then(response => {
      if (response.error) {
        dispatch({
          type: actionType.customerLookup,
          payload: { error: response.error, data },
        })
      } else {
        dispatch({
          type: actionType.customerLookup,
          payload: response,
        })
      }
    })
    .catch(error => {
      dispatch({
        type: actionType.customerLookup,
        payload: { error, data },
      })
    })
}

export const deleteAccount = (dispatch, data) => {
  if (!data.uid || !data.email) return false
  return callFn(httpFunctions.deleteAccount, data)
    .then(response => {
      if (response.error) {
        dispatch({
          type: actionType.deleteAccount,
          payload: { error: response.error, ...data},
        })
        return {
          error: response.error,
          email: data.email,
        }
      } else {
        dispatch({
          type: actionType.deleteAccount,
          payload: {
            ...response,
            ...data,
          },
        })
        return {
          error: null,
          email: data.email,
        }
      }
    })
    .catch(error => {
      dispatch({
        type: actionType.deleteAccount,
        payload: { error, ...data },
      })
      return {
        error: error,
        email: data.email,
      }
    })
}

export const setLifetimeFree = (dispatch, data) => {
  if (!(data.uid)) return false
  callFn(httpFunctions.setLifetimeFree, data)
    .then(response => {
      if (response.error) {
        dispatch({
          type: actionType.setLifetimeFree,
          payload: { error: response.error, data },
        })
      } else {
        dispatch({
          type: actionType.setLifetimeFree,
          payload: response,
        })
      }
    })
    .catch(error => {
      dispatch({
        type: actionType.setLifetimeFree,
        payload: { error, data },
      })
    })
}

export const updateEmail = (dispatch, data) => {
  if (!data.email || !data.newEmail) return false

  return callFn(httpFunctions.updateEmail, data)
    .then(response => {
      if (response.error) {
        dispatch({
          type: actionType.updateEmail,
          payload: { error: response.error, data },
        })
      } else {
        dispatch({
          type: actionType.updateEmail,
          payload: response,
        })
      }
      return response
    })
    .catch(error => {
      dispatch({
        type: actionType.updateEmail,
        payload: { error, data },
      })
      return { error }
    })
}
