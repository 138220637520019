import styled, { css } from 'styled-components'

import {
  Col
} from 'react-bootstrap'
import colors from '../../lib/styling/colors'
import styling from "../../lib/styling";

export const H1 = styled.h1`
  ${props => props.color ? css`
    color: ${props.color};
  ` : css`
    color: ${colors.wine};
  ` }
  font-family: IntroBold;
  font-weight: 400;
  ${props => props.align && css`
    text-align: ${props.align};
  `}
  ${props => props.size && css`
    font-size: ${props.size};
  `}
`
export const PageHeader = styled(H1)`
  margin: 20px;
`

export const H2 = styled.h2`
  color: ${colors.blue};
  ${props => props.align && css`
    text-align: ${props.align};
  `}
`

export const ErrorText = styled.p`
  color: ${colors.wine};
  margin-bottom: 0;
  ${props => props.align && css`
    text-align: ${props.align};
  `}
`

export const Normal = styled.p`
  margin-bottom: 0;
  ${props => props.align && css`
    text-align: ${props.align};
  `}
  ${props => props.color ? css`
    color: ${props.color};
  ` : css`
    color: ${colors.blue};
  ` }
  ${props => props.size && css`
    font-size: ${props.size}px;
  `}
`

export const Bold = styled.p`
  margin-bottom: 0;
  font-weight: bold;
  ${props => props.align && css`
    text-align: ${props.align};
  `}
  ${props => props.color ? css`
   color: ${props.color};
  ` : css`
    color: ${colors.blue};
  ` }
`

export const SubHeaderBar = styled.p`
  color: ${colors.blue};
  font-weight: bold;
  font-size: 16;
  padding: 2px 10px;
  ${props => props.backgroundColor ? css`
    background-color: ${colors.backgroundColor};
    ` : css`
    background-color: ${colors.pinkorange};
  `}
`

export const CenterCol = styled(Col)`
  text-align: center;
`

export const RightCol = styled(Col)`
  text-align: right;
`

export const FooterText = styled.p`
  color: ${colors.white};
  font-size: 10px;
  text-align: center;
  margin: 0;
`
export const FootNote = styled(Normal)`
  color: ${colors.darkslate};
  font-size: 14px;
  line-height: 20px;
`
export const FootNoteLink = styled.a`
  color: ${colors.darkslate};
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
  &:visited, &:hover, &:active {
    color: ${colors.darkslate};
  }
`

export const HeaderTitle = styled.div`
  font-family: 'TradeGothic';
  text-transform: uppercase;
  letter-spacing: 0.3em;
  ${props => props.wine ? css`
    color: ${styling.colors.wine};
  `
    : css`
    color: ${styling.colors.blue};
  `}  
  ${props => props.noMargin ? css``
    : css`
    margin-left: 5px;
    padding-top: 5px;
  `}
  ${props => props.disabled && css`
    color: ${styling.colors.slate};
  `}
`

export const HeaderSubTitle = styled.div`
  font-family: 'TradeGothic';
  color: ${styling.colors.wine};
  font-size: 22px;
  line-height: 30px;
`

export const ErrorMessage = styled.p`
  font-size: 12px;
  margin: 0;
  color: ${styling.colors.fire};
  margin: 5px 0;
`
