import React from 'react'
import styled from 'styled-components'

import i18n from '../../../../lib/i18n'
import styling from '../../../../lib/styling'
import constants from '../../../../lib/constants'
import { Bold } from '../../../../components/atoms/Fonts'

const {
  INVOICE_TM,
  INVOICE_RX,
} = constants.purchase

const Container = styled.div`
  width: 100%;
  margin-top: 15px;
`
const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`
const MainCol = styled.div`
  width: 33%;
  border-bottom: 1px solid ${styling.colors.wine};
  overflow: hidden;
  padding: 2px 5px;
`
const RowLabel = styled(MainCol)`
  background: ${styling.colors.greypink};
`

const SummaryRow = (({
  tmVal = 'n/a',
  rxVal = 'n/a',
  label = '',
  withPrefix,
}) => {
  return <Row>
    <RowLabel>{label}</RowLabel>
    <MainCol>{withPrefix ? `${withPrefix} ` : ''}{tmVal}</MainCol>
    <MainCol>{withPrefix ? `${withPrefix} ` : ''}{rxVal}</MainCol>
  </Row>
})
const PurchaseSummary = (({
  data,
}) => {
  const str = i18n.rx_support
  if (!data) return <></>

  const tm = data[INVOICE_TM] || {}
  const rx = data[INVOICE_RX] || {}

  return <Container>
    <Row>
      <MainCol />
      <MainCol><Bold>{str.summary_header_tm}</Bold></MainCol>
      <MainCol><Bold>{str.summary_header_rx}</Bold></MainCol>
    </Row>
    <SummaryRow
      label={str.summary_row_paid}
      tmVal={tm.paid}
      rxVal={rx.paid}
    />
    <SummaryRow
      label={str.summary_row_refund}
      tmVal={tm.refunded}
      rxVal={rx.refunded}
    />
    <SummaryRow
      label={str.summary_row_canceled}
      tmVal={tm.canceled}
      rxVal={rx.canceled}
    />
    <SummaryRow
      label={str.summary_row_total_sum}
      tmVal={tm.sum}
      rxVal={rx.sum}
      withPrefix="$"
    />
    <SummaryRow
      label={str.summary_row_total_internal}
      tmVal={tm.internal}
      rxVal={rx.internal}
      withPrefix="$"
    />
  </Container>
})

export default PurchaseSummary
