import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'
import actionType from './types'
import constants from '../../lib/constants'

const {
  RIGHTS_OWNER,
  RIGHTS_CUSTOMER_SUPPORT,
} = constants.support

const {
  ERROR_PAYMENT_CARD_CHARGE,
  ERROR_PAYMENT_PROFILE_CHARGE,
  ERROR_PAYMENT_PROFILE_CREATE,
  ERROR_PAYMENT_PROFILE_UPDATE,
  ERROR_RX_ORDER,
  // ERROR_TM_ORDER,
} = constants.errors


const db = firebase.firestore()
const auth = firebase.auth()

const refs = {
  userSettings: (uid) => db.collection('versions').doc('v1.1')
    .collection('users').doc(uid)
    .collection('settings').doc('app'),
  universalSettings: db.collection('universal').doc('settings'),
  universalOpenSettings: db.collection('universal').doc('open'),
  featureSettings: db.collection('universal').doc('featureSettings'),
  rxUser: (uid) => db.collection('rx_delivery').doc('root')
    .collection('users').doc(uid),
  rxPurchase: (uid) => db.collection('rx_delivery').doc('root')
    .collection('users').doc(uid)
    .collection('purchases'),
  rxTelemed: (uid) => db.collection('rx_delivery').doc('root')
    .collection('users').doc(uid)
    .collection('telemed'),
  rxDelivery: (uid) => db.collection('rx_delivery').doc('root')
    .collection('users').doc(uid)
    .collection('rx_delivery'),
  rxDeliveryNotes: db.collection('rx_delivery').doc('gogomeds').collection('tasks'),
  rxErrorTasks: db.collection('rx_delivery').doc('errors').collection('tasks'),
  invoices: db.collection('invoices'),
  userInvoices: (uid) => db.collection('invoices').where('uid', '==', uid),
}

const listenToFirebase_settings = (dispatch) => {
  const user = auth.currentUser
  refs.userSettings(user.uid).onSnapshot(doc => {
    dispatch({
      type: actionType.userSettings,

      payload: doc.data(),
    })
  })

  return true
}

const listenToFirebase_rxErrorTasks = (dispatch) => {
  refs.rxErrorTasks.onSnapshot((doc) => {
    const allErrors = doc.docs.map(d => ({ data: d.data(), key: d.id })) || []
    const rxOrders = allErrors.filter(({ data }) => data.type === ERROR_RX_ORDER) || []
    const purchase = allErrors.filter(({ data }) => [ERROR_PAYMENT_CARD_CHARGE, ERROR_PAYMENT_PROFILE_CHARGE].includes(data.type)) || []
    const paymentProfile = allErrors.filter(({ data }) => [ERROR_PAYMENT_PROFILE_CREATE, ERROR_PAYMENT_PROFILE_UPDATE].includes(data.type)) || []
    const errorTasks = {
      all: allErrors,
      rxOrders,
      purchase,
      paymentProfile,
    }
    dispatch({
      type: actionType.rxDashboard,
      payload: { errorTasks },
    })
  })
}

const listenToFirebase_internalUserData = (dispatch) => {
  refs.rxDeliveryNotes.onSnapshot(doc => {
    const allData = doc.docs.map(d => ({ data: d.data(), key: d.id }))
    const allNotes = allData.filter(({ type }) => type === 'rxNote')
    const allRxUpdates = allData.filter(({ data }) => data.type === 'rxOrderUpdate').reduce((acc, {
      data,
      key,
    }) => {
      const {
        eventDateKey,
        orderUpdate,
      } = data
      const numSuccess = orderUpdate.successful.length
      const numFailed = orderUpdate.failed.length
      const numCanceled = orderUpdate.canceled.length
      return {
        ...acc,
        successful: {
          ...acc.successful,
          [eventDateKey]: orderUpdate.successful,
        },
        failed: {
          ...acc.failed,
          [eventDateKey]: orderUpdate.failed,
        },
        canceled: {
          ...acc.canceled,
          [eventDateKey]: orderUpdate.canceled,
        },
        overview: [
          ...acc.overview,
          {
            total: numCanceled + numFailed + numSuccess,
            eventDateKey,
            id: key,
            successful: numSuccess,
            failed: numFailed,
            canceled: numCanceled,
          }
        ]
      }
    }, {
      successful: {},
      canceled: {},
      failed: {},
      overview: [],
    })

    const rxCaseMembership = allData.reduce((acc, { data, key }) => {
      const {
        type,
        status,
      } = data
      if (type === 'rxCaseMembership') {
        return {
          ...acc,
          [status]: [
            ...(acc && acc[status]) || [],
            {
              ...data,
              key,
            }
          ]
        }
      }
      return acc
    }, {
      new: [],
      processed: [],
    })

    const sortedNotes = allNotes.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    const highPriorityNotes = sortedNotes.filter(({ notes }) => {
      return Boolean(notes.find(({ highPriority }) => highPriority))
    })
    const notes = {
      sortedNotes,
      highPriorityNotes,
    }

    dispatch({
      type: actionType.rxDashboard,
      payload: {
        notes,
        rxUpdates: allRxUpdates,
        rxCaseMembership,
      },
    })
  })

}

const listenToFirebase_universalSettings = (dispatch) => {
  refs.universalSettings.onSnapshot(doc => {
    dispatch({
      type: actionType.getUniversalSettings,
      payload: doc.data(),
    })
  })

  refs.featureSettings.onSnapshot(doc => {
    dispatch({
      type: actionType.getFeatureSettings,
      payload: doc.data(),
    })
  })

  return true
}



const listenToFirebase_openData = (dispatch) => {
  refs.universalOpenSettings.onSnapshot(doc => {
    dispatch({
      type: actionType.getUniversalOpenSettings,
      payload: doc.data(),
    })
  })
  return true
}

// Call on listeners
export const listenToFirebaseChanges = async (dispatch, authClaims) => {
  const isOwner = authClaims[RIGHTS_OWNER]
  const isCS = authClaims[RIGHTS_CUSTOMER_SUPPORT]
  const user = auth.currentUser
  const isLoggedIn = user?.uid
  if (isLoggedIn) {
    await listenToFirebase_settings(dispatch)
    await listenToFirebase_universalSettings(dispatch)
  }
  if (isLoggedIn && (isOwner || isCS)) {
    await listenToFirebase_internalUserData(dispatch)
    await listenToFirebase_rxErrorTasks(dispatch)
  }
  await listenToFirebase_openData(dispatch)
  return true
}
