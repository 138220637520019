import React from 'react'
import styled from 'styled-components'

import styling from '../../lib/styling'
import Constants from '../../lib/constants'
import i18n from '../../lib/i18n'

import { Normal } from './Fonts'
import { Link } from './Links'

const {
  NO_PURCHASE,
  ONGOING_ONBOARDING,
  NOT_ELIGIBLE,
} = Constants.banner

const Container = styled.div`
  background: ${styling.colors.blue};
  min-height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
`

const BannerText = ({ children }) => <Normal
  color={styling.colors.white}
  style={{
    fontWeight: 500,
    textAlign: 'center',
  }}
>{children}</Normal>

const BannerLink = ({ children, href, target = '_blank' }) => <Link
  to={href}
  target={target}
  style={{
    color: styling.colors.white,
    fontWeight: 700,
    textDecoration: 'none',
    display: 'inline',
  }}
>
  {children}
</Link>

const Banner = ({
  type,
  props = {},
}) => {  

  if (!type) return <></>

  const {
    path,
    reason,
  } = props

  const message = {
    [NO_PURCHASE]: <div>
      <BannerText>{i18n.purchase.no_purchase} <BannerLink
          href={path}
          target="_self"
        >
          {i18n.purchase.no_purchse_click}
        </BannerLink>
      </BannerText>
    </div>,
    [ONGOING_ONBOARDING]: <div>
      <BannerText>{i18n.purchase.ongoing_onboarding} <BannerLink
          href={path}
          target="_self"
        >
          {i18n.purchase.ongoing_onboarding_link}
        </BannerLink>
      </BannerText>
    </div>,
    [NOT_ELIGIBLE]: <div>
      <BannerText>
        {i18n.purchase.not_eligible(reason)}
      </BannerText>
    </div>
  }[type]

  return <Container>
    {message}
  </Container>
}

export default Banner