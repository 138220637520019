import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'

import actionType from './types'

export const handleLoading = (dispatch, type, state = true) => {
  dispatch({
    type: actionType.loading,
    payload: { [type]: state },
  })
}
export const dismissError = (dispatch, errorType) => {
  dispatch({
    type: actionType.error,
    errorType,
    payloadAction: 'remove',
  })
}
export const dismissSuccess = (dispatch, successType) => {
  dispatch({
    type: actionType.success,
    successType,
    payloadAction: 'remove',
  })
}
export const setBanner = (dispatch, { type, set, props }) => {
  dispatch({
    type: actionType.setBanner,
    payload: {
      type: set !== false ? type : null,
      props,
    }
  })
}
export const removeBanner = (dispatch) => {
  dispatch({
    type: actionType.setBanner,
    payload: null,
  })
}