import React from 'react'
import styled from 'styled-components'

import LadiesSrc from '../../assets/images/ladies-group.png'
import {
	H1,
	Normal,
} from '../../components/atoms/Fonts'
import styling from '../../lib/styling'
import i18n from '../../lib/i18n'


const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 20px;
`
const Header = styled(H1)`
	text-align: center;
	max-width: 600px;
`
const InfoContainer = styled.div`
	margin-top: 10px;
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 500px;
	background: white;
	padding: 20px;
	border-radius: 10px;
	border: ${styling.border};
`
const Text = styled(Normal)`
	text-align: center;
	margin-bottom: 5px;
`
const Ladies = styled.img`
	height: 200px;
	width: 500px;
`

const Home = () => {
	const str = i18n.emme_home

	return <Container>
		<Header>
			{str.home_header}
		</Header>
		<InfoContainer>
			<Text>
				{str.home_paragraph}
			</Text>

		</InfoContainer>

		<Ladies
			src={LadiesSrc}
		/>
	</Container>
}

export default Home
