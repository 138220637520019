import React from 'react'
import styled from 'styled-components'
import { Circle, CheckCircleFill } from 'react-bootstrap-icons'
import { Normal } from '../../atoms/Fonts'
import styling from '../../../lib/styling'

const Container = styled.div`
  display: flex;
  flex-direction: row;
`
const Text = styled(Normal)`
  text-align: left;
  margin-left: 5px;
  color: ${styling.colors.grey};
  font-size: 13px;
`
const ImgContainer = styled.div`
  width: 15px;
  height: 13px;
  margin-top: -4px;
`

const CheckItem = ({
  text,
  isStrong,
}) => {
  return <Container>
    <ImgContainer>
      {
        isStrong ?
          <CheckCircleFill color={styling.colors.wine} size={13} />
          :
          <Circle color={styling.colors.wine} size={13} />
      }
    </ImgContainer>
    <Text>
      {text}
    </Text>
  </Container>
}

export default CheckItem