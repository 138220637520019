export default {
  MODE: 'mode',
  OOB_CODE: 'oobCode',
  API_KEY: 'apiKey',
  RESET_PASSWORD: 'resetPassword',
  RECOVER_EMAIL: 'recoverEmail',
  VERIFY_EMAIL: 'verifyEmail',
  PW_LENGTH: 'pwLength',
  PW_LOWERCASE: 'pwLowercase',
  PW_UPPERCASE: 'pwUppercase',
  PW_SPECIAL_CHAR: 'pwSpecialChar',
  PW_DIGIT: 'pwDigits',
  MIN_PW_LENGTH: 10,
  MAX_PW_LENGTH: 64,
  ERROR_EXPIRED_CODE:'auth/expired-action-code',
  ERROR_INVALID_CODE:'auth/invalid-action-code',
  OLD_EMAIL: 'oldEmail',
  NEW_EMAIL: 'newEmail',
}
