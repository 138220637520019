export default {
  TAB_TASKS: 'tab_tasks',
  TAB_NOTES: 'tab_notes',
  TAB_INFO: 'tab_info',
  TAB_REPORTS: 'tab_reports',
  TAB_GENERAL: 'tab_general',
  TAB_WHEEL: 'tab_wheel',
  TAB_GGM: 'tab_ggm',
  TAB_GGM_ORDERS: 'tab_ggm_orders',
  TAB_AUTH: 'tab_authorize',
  TAB_PURCHASE_SUMMARY: 'tab_purchase_summary',
  TAB_PURCHASE_ACTIVITIES: 'tab_purchase_activities',
  RX_STATUS_SUCCESS: 'successful',
  RX_STATUS_FAILED: 'failed',
  RX_STATUS_CANCELED: 'canceled',
}