import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import UserList from '../components/UserList'
import { Container, Row } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { useFirestore } from 'react-redux-firebase'

const UsersPage = () => {
	const users = useSelector(state => (state.firestore && state.firestore.ordered && state.firestore.ordered.users) ? state.firestore.ordered.users : [])
	const firestore = useFirestore()
	const listenerSettings = {
		collection: '/rx/root/users',
		limit: 10,
		storeAs: 'users',
	}

	useEffect(() => {
		firestore.setListener(listenerSettings)
		return function cleanup() {
			firestore.unsetListener(listenerSettings)
		}
	}, [firestore, listenerSettings])

	console.log("users", users);

	return (
		<Container>
			<Row>
				<Link to="/">Back</Link>
			</Row>

			<UserList users={users} />
		</Container>
	)

}

export default UsersPage;