import React from 'react'
import { isEmpty } from 'ramda'
import styled, { css } from 'styled-components'
import {
  BrowserView, 
  MobileView,
  isBrowser,
} from 'react-device-detect'
import ReactSelect from 'react-select'

import styling from '../../lib/styling'

const SelectContainer = styled.div`
  position: relative;
  ${props => props.width && css`
    width: ${props.width};
  `}
  ${props => props.height && css`
    height: ${props.height};
  `}
  text-align: right;

  &:after {
    content: '>';
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    color: ${styling.colors.blue};
    right: -3px;
    top: -6px;
    padding-right: 3px;
    height: 30px;
    width: 10px;
    padding: 15px 0px 0px 8px;
    position: absolute;
    pointer-events: none;
    ${props => props.mainColor && css`
      color: ${props.mainColor};
    `}
  }
`
const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;
  height: 30px;
  border-color: ${styling.colors.blue};
  outline: none;
  border-radius: 5px;
  color: ${styling.colors.blue};
  width: 100%;
  padding-left: 5px;
  ${props => props.height && css`
    height: ${props.height};
  `}
  ${props => props.mainColor && css`
    color: ${props.mainColor};
    border-color: ${props.mainColor};
  `}
  ${props => props.noBorder && css`
    border-color: transparent;
  `}
`
const Option = styled.option`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;
  background: ${styling.colors.white};
`

const StyledReactSelect = styled(ReactSelect)`
  & svg {
    fill: ${styling.colors.blue};
    ${props => props.mainColor && css`
      fill: ${props.mainColor};
    `}
  }
  & span {
    background: none;
  }
  input:focus {
    outline: none;
  }
`

const Dropdown = ({
  list = [],
  selected,
  onChange,
  width = '300px',
  height = '100%',
  isSearchable = false,
  isDisabled = false,
  style = {},
  altStyle = {},
  noBorder,
  mainColor,
  fontColor,
}) => {

  if (isEmpty(list) || !Array.isArray(list)) return <></>

  const options = typeof list[0] === 'string' ? list.map(item => ({ value: item, label: item })) : list
  const selectedValue = typeof selected === 'string' && isBrowser ? list.find(item => (item.value === selected)) : list.find(item => (JSON.stringify(item.value) === JSON.stringify(selected)))

  const handleChange = (newVal) => {
    onChange(newVal.value || newVal)
  }

  const styles = {
    control: styles => ({ 
      ...styles, 
      backgroundColor: styling.colors.white, 
      borderColor: noBorder ? 'transparent' : styling.colors.blue, 
      width,
      ...altStyle.control || {},
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected || isFocused
          ? styling.colors.greypink
          : null,
        color: isDisabled
          ? styling.colors.grey
          : isSelected || isFocused
          ? styling.colors.terracotta
          : fontColor || mainColor || styling.colors.blue,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor:
            !isDisabled && isSelected ? styling.colors.greypink : styling.colors.white,
        },
        'focus': {
          ...styles[':focus'],
          borderColor: noBorder ? 'transparent' : styling.colors.blue, 
          outline: 'none',
        },
        ...altStyle.option || {},
      }
    },
    input: styles => ({ 
      ...styles, 
      borderColor: noBorder ? 'transparent' : styling.colors.blue, 
      color: mainColor || styling.colors.blue,
      'focus': {
        ...styles[':focus'],
        borderColor: noBorder ? 'transparent' : styling.colors.blue, 
        outline: 'none',
      },
      ...altStyle.input || {},
    }),
    placeholder: styles => ({ 
      ...styles, 
      color: fontColor || mainColor || styling.colors.blue,
      ...altStyle.placeholder || {},
    }),
    singleValue: styles => ({ 
      ...styles,
      borderColor: noBorder ? 'transparent' : styling.colors.blue, 
      color: fontColor || mainColor || styling.colors.blue,
      ...altStyle.singleValue || {},
    }),
    valueContainer: styles => ({ 
      ...styles,
      ...altStyle.valueContainer || {},
    }),
    container: styles => ({ 
      ...styles,
      'focus': {
        ...styles[':focus'],
        borderColor: noBorder ? 'transparent' : styling.colors.blue, 
        outline: 'none',
      },
      ...altStyle.container || {},
    }),
  };

  return <>
    <MobileView style={style}>
      <SelectContainer width={width} height={height} mainColor={mainColor}>
        <Select 
          onChange={(val) => handleChange(val.target.value)}
          mainColor={mainColor}
          noBorder={noBorder}
          value={selected}
          height={height}
        >
          <Option value={null} />
          {
            options.map(({ value, label }) => {
              return <Option
                value={value}
                key={value}
                mainColor={mainColor}
              >
                {label}
              </Option>
            })
          }
        </Select>
      </SelectContainer>
    </MobileView>
    <BrowserView style={style}>
      <StyledReactSelect
        value={selectedValue}
        options={options}
        isSearchable={isSearchable}
        onChange={handleChange}
        styles={styles}
        isDisabled={isDisabled}
        mainColor={mainColor}
      />
    </BrowserView>
  </>
}

export default Dropdown
