import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  selectors,
} from '../../store'
import styled, { css } from 'styled-components'

import ideaGirl from '../../assets/images/ideagirl.png'

import {
  Tabs,
  Tab,
  FormCheck,
  Row,
  Col,
} from 'react-bootstrap'

import {
  ErrorText,
  Normal,
  SubHeaderBar,
} from '../../components/atoms/Fonts'

import {
  Link,
} from '../../components/atoms/Links'

import styling from '../../lib/styling'
import i18n from '../../lib/i18n'
import constants from '../../lib/constants'
import crypto from '../../lib/utils/crypto'

import LabelValuePair from '../../components/molecules/LabelValuePair'
import BooleanValue from '../../components/atoms/BooleanValue'
import Alert, { ALERT_TYPES } from '../../components/molecules/Alert'

import {
  ownerSettings,
  getSettingsData,
} from './utils'
import Calendar from './tabs/Calendar'
import TroubleShooting from './tabs/TroubleShooting'
import SubscriptionHistory from './tabs/SubscriptionHistory'
import utils, { isEmpty } from '../../lib/utils'

const CustomerContainer = styled.div`
  padding: 20px;
  height: '100%';
  width: '100%';
  background: ${styling.colors.white};
  border-radius: 0px 40px 0px 0px;
`
const CustomerDataContainer = styled.div`
  padding-top: 20px;
`
const CustomerData = styled.div`
  padding: 20px;
  height: '100%';
  width: '100%';
  border: 1px solid ${styling.colors.lightslate};
  border-top: none;
`
const DataBlock = styled.div`
  padding: 0 10px;
  margin-bottom: 20px;
`
const NoSearchImage = styled.img`
  
  margin-top: 40px;
  ${props => props.size && props.size !== 'default' ? css`
    height: ${props.size}px;
  ` : css`
    height: 250px;
  ` }
`
const CenterImage = styled.div`
  text-align: center;
`

const {
  SUBSCRIPTION_LIFETIME_FREE,
} = constants.settings

const {
  EMAIL,
  UID,
  PHONE_NUMBER,
  DAYS,
  SETTINGS,
  TROUBLE_SHOOTING,
  SUBSCRIPTION_HISTORY,
  USER_MANAGEMENT,
  USER_RIGHTS,
  MIXPANEL,
  EMAIL_VERIFIED,
  SHARE_LINK,
  RX_DELIVERY,
} = constants.support

const {
  CHECKBOX,
} = constants.form

const Customer = ({
  changeUserRighs,
  isSuperUser,
  email,
  removeCustomerTab,
  generalAlert,
  updateSettings,
  deleteAccount,
  setLifetimeFree,
  updateEmail,
}) => {
  const customers = useSelector(selectors.customers)
  const universalSettings = useSelector(selectors.universalSettings)
  const batteryPercent = useSelector(state => selectors.batteryPercent(state, email)) * 100
  const [selectedTab, setSelectedTab] = useState(SETTINGS)
  const [alert, setAlert] = useState(customers.alert || generalAlert)
  const [shownError, setShownError] = useState(null)
  const [customer, setCustomer] = useState(email ? customers[email] : null)
  const [subscriptionHistory, setSubscriptionHistory] = useState(null);

  const prevError = utils.usePrevious(shownError)

  useEffect(() => {
    if (customer?.error && customer?.error !== prevError) {
      setShownError(customer.error)
    }
  }, [customer, prevError])

  useEffect(() => {
    if (email && email !== undefined) {
      setCustomer(customers[email])
      customers[email] && setSubscriptionHistory(customers[email].settings?.subscription?.history)
    }
  }, [email, customers])

  if (!email || !customer) return <></>

  const handleUserRightSelect = ({ key, checked }) => {
    const { email: cEmail, uid, claims } = customer
    if (cEmail && uid) {
      changeUserRighs(uid, {
        ...claims,
        [key]: checked
      }, cEmail)
    }
  }

  const generateShareLink = (eMail) => {
    const host = window.location.href
    const encrypted = crypto.encrypt(eMail)

    return `${host}/?customer=${encrypted}`
  }

  const renderCustomerData = (key) => {
    const {
      claims = {},
      settings,
      packs,
    } = (customer && customer.data) || customer || {}

    if (!settings) return <></>

    const rawSettingsData = {
      data: settings,
      universalHardwareSettings: universalSettings.hardware,
      featureStartDates: universalSettings.featureStartDates,
      packs,
      batteryPercent,
    }
    const rxUser = customer && customer[RX_DELIVERY]
    const settingsData = getSettingsData(rawSettingsData, rxUser)

    const settingsHeaders = Object.keys(settingsData)

    if (customer && customer.deleted) {
      return <CustomerData>
        <Alert type={ALERT_TYPES.SUCCESS}>
          {customer.alert.success.message}
        </Alert>
      </CustomerData>
    }

    return <CustomerData>
      {
        alert &&
        <Alert
          type={customers.alert.error ? ALERT_TYPES.WARNING : ALERT_TYPES.SUCCESS}
          dismissible
          onClose={() => setAlert(null)}
        >
          {
            customers.alert.error ?
              customers.alert.error.message :
              customers.alert.success.message
          }
        </Alert>
      }
      {
        key === DAYS && <Calendar
          email={customer.email}
        />
      }
      {
        key === SETTINGS && settingsHeaders.map(header => {
          return <div key={`header-${header}`}>
            <SubHeaderBar>
              {i18n.support[header]}
            </SubHeaderBar>
            <DataBlock>
              {
                settingsData[header].hasData && settingsData[header].data.map(({
                  key,
                  value,
                }) => {
                  return <LabelValuePair
                    label={i18n.support[key] || 'tbd'}
                    value={value}
                    key={key}
                  />
                })
              }
              {
                !settingsData[header].hasData && <Normal key="no-data">{i18n.no_data}</Normal>
              }
            </DataBlock>
          </div>
        })
      }
      {
        key === SETTINGS && isEmpty(settingsData) && <Normal key="no-data">{i18n.support.no_data_alert}</Normal>
      }
      {
        key === TROUBLE_SHOOTING && <TroubleShooting
          email={customer.email}
          updateSettings={(data) => updateSettings(customer.uid, data)}
          updateEmail={updateEmail}
          deleteAccount={(reason) => deleteAccount({ 
            uid: customer.uid,
            email: customer.email,
            reason,
          })}
          setLifetimeFree={(isLifetimeFree) => {
            setLifetimeFree({
              uid: customer.uid,
              [SUBSCRIPTION_LIFETIME_FREE]: isLifetimeFree,
            })
            setCustomer({
              ...customer,
              ...customer.settings && customer.settings.subscription ? {
                settings: {
                  ...customer.settings,
                  subscription: {
                    ...customer.settings.subscription,
                    [SUBSCRIPTION_LIFETIME_FREE]: isLifetimeFree,
                  }
                },
              } : {},
            })
          }}
        />
      }
      { 
        key === SUBSCRIPTION_HISTORY && <SubscriptionHistory
          history={subscriptionHistory}
        />
      }
      {
        key === USER_MANAGEMENT && ownerSettings && <>
          <SubHeaderBar>
            {i18n.support[USER_RIGHTS]}
          </SubHeaderBar>
          <DataBlock>
            {
              ownerSettings[USER_RIGHTS].data.map(({ key, text, info }) => {
                return <Row key={`${key}-selectedCustomerEmail`}>
                  <Col sm={3}>
                    <FormCheck
                      type={CHECKBOX}
                      label={text}
                      value={key}
                      checked={claims[key]}
                      onChange={(e) => handleUserRightSelect({ key, checked: e.target.checked })}
                    />
                  </Col>
                  <Col>
                    {info}
                  </Col>
                </Row>
              })
            }
          </DataBlock>
        </>
      }
    </CustomerData>
  }

  const userTabs = [SETTINGS, DAYS, TROUBLE_SHOOTING]
  if (subscriptionHistory) userTabs.push(SUBSCRIPTION_HISTORY)
  if (isSuperUser) userTabs.push(USER_MANAGEMENT)

  if (!customer) return <></>

  if (customer.alert) {
    const { error, success } = customer.alert
    return <Alert
      type={error ? ALERT_TYPES.WARNING : ALERT_TYPES.SUCCESS}
    >
      {error ? (error.message || error) : success.message}
    </Alert>
  }

  return <CustomerContainer>
    <Link
      onClick={() => removeCustomerTab(customer.email)}
      align="right"
    >
      {i18n.dismiss}
    </Link>

    {
      customer &&
      <>
        {
          shownError && <Alert
            dismissible
            onClose={() => setShownError(null)}
            type={ALERT_TYPES.WARNING}
          >
            {shownError}
          </Alert>
        }
        {
          [UID, EMAIL, PHONE_NUMBER].map(key => {
            const val = customer[key]
            const value = [EMAIL, PHONE_NUMBER].includes(key) && val ? <BooleanValue
              text={val}
              value={key === PHONE_NUMBER || customer[EMAIL_VERIFIED]}
            /> : val
            return <LabelValuePair
              key={key}
              label={i18n.support[key]}
              value={value}
              labelColor={styling.colors.wine}
            />
          })
        }
        <LabelValuePair
          key={MIXPANEL}
          label={i18n.support[MIXPANEL]}
          value={customer[UID] && i18n.support.mixpanel_link(customer[UID].slice(0, 8))}
          isLink={true}
          labelColor={styling.colors.wine}
        />
        <LabelValuePair
          key={SHARE_LINK}
          label={i18n.support[SHARE_LINK]}
          value={generateShareLink(customer[EMAIL])}
          isLink={true}
          labelColor={styling.colors.wine}
        />
        <CustomerDataContainer>
          <Tabs
            id="customer-tabs"
            transition={false}
            activeKey={selectedTab}
            onSelect={tabKey => setSelectedTab(tabKey)}
          >
            {
              userTabs.map(key => {
                return <Tab
                  eventKey={key}
                  title={i18n.support[key]}
                  key={key}
                  tabClassName={`tab-${key === selectedTab ? 'selected' : 'unselected'}`}
                >
                  {renderCustomerData(key)}
                </Tab>
              })
            }
          </Tabs>
        </CustomerDataContainer>
        <Customer />
        <CenterImage>
          <NoSearchImage src={ideaGirl} alt="Girl pointing" size="100" />
        </CenterImage>
      </>
    }
    {
      customer && customer.error && !customer.uid &&
      <ErrorText>
        {customer.error.message}
      </ErrorText>
    }
  </CustomerContainer>;
};

export default Customer;
