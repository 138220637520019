import React from 'react'

import iconSrc from '../../../assets/images/cert-vendor-logos/logo-authorize-net.png'

const AuthorizeNet = () => {

  return <span 
    // href="emme.com" 
    // target="_blank" 
    // title="Verify Authorize.net Approval" 
    // rel="noopener noreferrer"
  >
    <img src={iconSrc} alt="Authorize.net verified" width="86" height="62" border="0"></img>
  </span>
}

export default AuthorizeNet
