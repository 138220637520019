import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import selectors from '../../store/selectors'
import * as actions from '../../store/actions'

import {
	PageHeader,
} from '../../components/atoms/Fonts'
import { SingleColumnContainer } from '../../components/atoms/Containers'
import Fallback from './Fallback'
import TwoFactor from './TwoFactor'

const CSHomePage = ({
	userSettings,
	removeCode,
	resendCode,
	auth,
	user,
}) => {

	const [twoFactorCode, setTwoFactorCoder] = useState(false)
	const [codeError, setCodeError] = useState(false)

	useEffect(() => {
		(user || userSettings) && setTwoFactorCoder(user.twoFactor || userSettings.twoFactor)
	}, [user, userSettings])

	if (!userSettings) return <></>

	return <SingleColumnContainer>
		<PageHeader>
			Welcome {auth.email}!
		</PageHeader>
		{
			twoFactorCode ?
				<TwoFactor
					code={twoFactorCode}
					resendCode={() => resendCode(auth.uid)}
					setCodeConfirmed={(isConfirmed) => {
						if (isConfirmed) {
							setCodeError(false)
							removeCode(auth.uid)
						} else {
							setCodeError(true)
						}
					}}
					codeError={codeError}
				/>
				:
				<Fallback />
		}
	</SingleColumnContainer>
}

export default connect(
	(state) => {
		return {
			userSettings: selectors.userSettings(state),
			authClaims: selectors.authClaims(state),
			auth: selectors.auth(state),
			user: selectors.user(state),
		}
	},
	(dispatch) => {
		return {
			removeCode: (uid) => actions.removeTwoFactor(dispatch, uid),
			resendCode: (uid) => actions.resendTwoFactor(dispatch, uid),
		}
	}
)(CSHomePage)