import React from 'react'
import styling from '../../lib/styling'
import styled from 'styled-components'

const StyledOverlay = styled.div(({
  cursor = 'default',
}) => {
  return`
    cursor: ${cursor};
    position: absolute;
    z-index: 50;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${styling.colors.transparentOverlay};
  `
})

const Overlay = ({
  onClick = false,
}) => {
  if (onClick) return <StyledOverlay cursor="pointer" onClick={onClick} />
  return <StyledOverlay />
}

export default Overlay