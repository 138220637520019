import { useState, useEffect } from 'react'
import {
  isBrowser,
  isMobile,
} from 'react-device-detect'
import constants from '../../lib/constants'

const RESIZE = 'resize'

const {
  XSMALL_SMALL,
  SMALL_MEDIUM,
  MEDIUM_LARGE,
  LARGE_XLARGE,
} = constants.bs

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
    isXS: width < XSMALL_SMALL,
    isSM: width >= XSMALL_SMALL && width < SMALL_MEDIUM,
    isMD: width >= SMALL_MEDIUM && width < MEDIUM_LARGE,
    isLG: width >= MEDIUM_LARGE && width < LARGE_XLARGE,
    isXL: width >= LARGE_XLARGE,
    isSmOrSmaller: width < SMALL_MEDIUM || isMobile,
    isMdOrSmaller: width < MEDIUM_LARGE,
    isLgOrSmaller: width < LARGE_XLARGE,
    isSmOrBigger: width >= XSMALL_SMALL,
    isMdOrBigger: width >= SMALL_MEDIUM,
    isLgOrBigger: width >= MEDIUM_LARGE,
    isBrowser,
    isMobile,
  }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener(RESIZE, handleResize)
    return () => window.removeEventListener(RESIZE, handleResize)
  }, []);

  return windowDimensions
}