import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  selectors,
  actions,
} from '../../store'
import styled, { css } from 'styled-components'

import ideaGirl from '../../assets/images/ideagirl.png'
import { SingleColumnContainer } from '../../components/atoms/Containers'

import {
  isEmpty
} from 'ramda'

import {
  InputGroup,
  FormControl,
  Button,
  Tabs,
  Tab,
} from 'react-bootstrap'

import {
  PageHeader,
} from '../../components/atoms/Fonts'

import i18n from '../../lib/i18n'
import constants from '../../lib/constants'
import utils from '../../lib/utils'
import crypto from '../../lib/utils/crypto'

import Customer from './Customer'
import GeneralAdvice from './GeneralAdvice'

const NoSearchImage = styled.img` 
  margin-top: 40px;
  ${props => props.size && props.size !== 'default' ? css`
    height: ${props.size}px;
  ` : css`
    height: 250px;
  ` }
`
const CenterImage = styled.div`
  text-align: center;
`

const {
  RIGHTS_OWNER,
} = constants.support

const Support = ({
  fetchCustomer,
  fetchUniversalSettings,
  changeUserRighs,
  authClaims,
  lookupCustomer,
  customerLookup,
  updateSettings,
  prescriptions,
  loading,
  deleteAccount,
  setLifetimeFree,
  updateEmail,
}) => {
  const customers = useSelector(selectors.customers)
  const isSuperUser = authClaims[RIGHTS_OWNER]
  const [userEmail, setUserEmail] = useState('')
  const [shownCustomers, setShownCustomers] = useState(new Set())
  const [selectedCustomerEmail, setSelectedCustomerEmail] = useState(null)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [lookupData, setLookupData] = useState({ uid: '', serialnumber: '' })

  utils.useEffectOnce(() => {
    sharedCustomer()
  })

  useEffect(() => {
    fetchUniversalSettings()
  }, [fetchUniversalSettings])

  useEffect(() => {
    if (customers && customers[selectedCustomerEmail]) setSelectedCustomer(customers[selectedCustomerEmail])
  }, [customers, selectedCustomerEmail])

  useEffect(() => {
    if (customerLookup) {
      setUserEmail(customerLookup.email)
    }
  }, [customerLookup])

  const searchUser = () => {
    if (userEmail && userEmail.length > 0) {
      if (!customers[userEmail]) fetchCustomer(userEmail);
      setShownCustomers(new Set([userEmail, ...shownCustomers]))
      setUserEmail('')
      setSelectedCustomerEmail(userEmail)
    } else {
      window.alert(i18n.email_needed)
    }
  }

  const searchUserEmail = () => {
    if (lookupData.uid || lookupData.serialnumber) {
      lookupCustomer(lookupData)
      setLookupData({ uid: '', serialnumber: '' })
    }
  }

  const removeCustomerTab = (email) => {
    const newShownCustomers = [...shownCustomers].filter(e => e !== email)
    setShownCustomers(new Set(newShownCustomers))
    if (selectedCustomerEmail === email && !isEmpty(shownCustomers)) {
      setSelectedCustomerEmail(newShownCustomers[0])
    } else if (selectedCustomerEmail === email) {
      setSelectedCustomerEmail(null)
    }
  }

  const sharedCustomer = async () => {
    const customer = await utils.cookies.get('customer')
    if (!customer || customer === 'null') return
    const customerEmail = await crypto.decrypt(customer)
    setUserEmail(customerEmail)
  }

  return <SingleColumnContainer>
    <PageHeader>{i18n.support.title}</PageHeader>
    <GeneralAdvice />
    <div>
      <InputGroup className="mb-3">
        <FormControl
          placeholder={i18n.support.user_uid}
          aria-label={i18n.support.user_uid}
          aria-describedby="basic-addon2"
          onChange={(e) => setLookupData({ uid: e.target.value })}
          value={lookupData.uid}
        />
        <FormControl
          placeholder={i18n.support.user_serialnumber}
          aria-label={i18n.support.user_serialnumber}
          aria-describedby="basic-addon2"
          onChange={(e) => setLookupData({ serialnumber: e.target.value })}
          value={lookupData.serialnumber}
        />
        <InputGroup.Append>
          <Button
            variant="outline-secondary"
            onClick={searchUserEmail}
          >Find User Email</Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
    <div>
      <InputGroup className="mb-3">
        <FormControl
          placeholder={i18n.support.user_email}
          aria-label={i18n.support.user_email}
          aria-describedby="basic-addon2"
          onChange={(e) => setUserEmail(e.target.value)}
          value={userEmail}
        />
        <InputGroup.Append>
          <Button
            variant="outline-secondary"
            onClick={searchUser}
          >Find User</Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
    {
      isEmpty(customers) && !selectedCustomer && <CenterImage>
        <NoSearchImage src={ideaGirl} alt="Girl pointing" />
      </CenterImage>
    }
    <Tabs
      id="customer-data"
      transition={false} // this is needed to not throw an ref error
      activeKey={selectedCustomerEmail}
      onSelect={email => setSelectedCustomerEmail(email)}
    >
      {
        !isEmpty(customers) && selectedCustomer && [...shownCustomers].map(email => {
          if (!email) return <></>
          return <Tab
            eventKey={email}
            title={email}
            key={email}
            tabClassName={`tab-${email === selectedCustomerEmail ? 'selected' : 'unselected'}`}
          >
            {
              email && <Customer
                email={email}
                changeUserRighs={changeUserRighs}
                isSuperUser={isSuperUser}
                alert={alert}
                removeCustomerTab={removeCustomerTab}
                generalAlert={customers.alert}
                updateSettings={updateSettings}
                prescriptions={prescriptions}
                loading={loading}
                deleteAccount={deleteAccount}
                setLifetimeFree={setLifetimeFree}
                updateEmail={(newEmail) => updateEmail({email, newEmail})}
              />
            }
          </Tab>
        })
      }
    </Tabs>
  </SingleColumnContainer>;
};

const SupportPage = withRouter(connect(
  state => ({
    authClaims: selectors.authClaims(state),
    customerLookup: selectors.customerLookup(state),
    prescriptions: selectors.prescriptions(state),
    loading: selectors.loading(state),
  }),
  dispatch => {
    return {
      fetchCustomer: (email) => actions.getCustomerByEmail(dispatch, email),
      fetchUniversalSettings: () => actions.getUniversalSettings(dispatch),
      changeUserRighs: (uid, rights, email) => actions.setUserRights(dispatch, uid, rights, email),
      updateSettings: (uid, data) => actions.updateUserSettings(dispatch, uid, data),
      lookupCustomer: (data) => actions.lookupCustomer(dispatch, data),
      deleteAccount: (data) => actions.deleteAccount(dispatch, data),
      setLifetimeFree: (data) => actions.setLifetimeFree(dispatch, data),
      updateEmail: (data) => actions.updateEmail(dispatch, data),
    }
  },
)(Support));

export default SupportPage;
