import React from 'react'
import styled from 'styled-components'

import logoSrc from '../../assets/images/Logo.png'

import i18n from '../../lib/i18n'



const LogoContainer = styled.div`
	text-align: center;
`
const Emme = styled.img`
	height: 43px;
  margin-top: 15px;
  margin-bottom: 10px;
`

const Logo = ({
  emmeStyle = {},
  style = {},
}) => {
  return <LogoContainer style={style}>
    <Emme src={logoSrc} alt={i18n.image.logo_alt} style={emmeStyle} />
  </LogoContainer>
};

export default Logo
