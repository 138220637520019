export default {
  none: 'none',
  pagebackground: '#FFF7F3',
  lightpagebackground: '#FFFDFD',
  transparentOverlay: 'rgba(79, 79, 79, 0.3)',
  lightgrey: '#E5E5E5',
  grey: '#4F4F4F',
  white: '#FFFFFF',
  black: '#000000',
  blue: '#10069F',
  terracotta: '#E3775E',
  pink: '#ECBAA8',
  pinkorange: '#FFE1D1',
  wine: '#84344E',
  lightwine: '#A6536E',
  slate: '#A2ACAB',
  lightslate: '#DADADA',
  darkslate: '#8E9897',
  rost: '#C06B13',
  babyblue: '#7F8BBF',
  babypink: '#E58CCC',
  bronze: '#84344E',
  lightterracotta: '#FDEEE6',
  salmon: '#FFD0B9',
  transparent: 'transparent',
  darkorange: '#F4886A',
  darkslate2: '#BFC5DF',
  orange: '#F4866B',
  fire: '#FF3B30',
  greypink: '#E3DADA',
  alert_error: 'rgba(255, 59, 48, 0.53)',
  alert_warning: 'rgba(192, 107, 19, 0.53)',
  alert_info: 'rgba(16, 6, 159, 0.53)',
  alert_success: 'rgba(31, 128, 128, 0.53)',
  error_red: '#FF3B30',
};
