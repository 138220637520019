import React from 'react'
import styled from 'styled-components'

import { Bold, Normal } from '../atoms/Fonts'

import moon1Src from '../../assets/images/icon-moon-1.png'
import moon2Src from '../../assets/images/icon-moon-2.png'
import moon3Src from '../../assets/images/icon-moon-3.png'
import moon4Src from '../../assets/images/icon-moon-4.png'
import i18n from '../../lib/i18n'
import styling from '../../lib/styling'
import {Link} from "../atoms/Links";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const ListRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 25px;
`
const BulletPoint = styled.img`
  margin-right: 20px;
`
const ListHeader = styled.h3`
  font-family: 'TradeGothic';
  color: ${styling.colors.wine};
  font-size: 18px;
  font-weight: 700;
`

const UnorderedList = ({
  title,
  list = [],
  style = {},
}) => {
  const bulletPoints = [moon1Src, moon2Src, moon3Src, moon4Src]
  const numBulletPoints = bulletPoints.length

  return <Container style={style}>
    {
      title && <Bold
        color={styling.colors.wine}
        style={{
          marginBottom: '37px',
        }}
      >{title}</Bold>
    }
    {
      list.map((item, index) => {
        const title = item.title
        const links = item.links
        const bulletPointIndex = index % numBulletPoints
        const key = typeof(item) === 'string' ? item : item.title ? item.title : item.key

        const renderBody = (text, color = styling.colors.wine, style = {}, options = {}) => {

          return <Normal
            color={color}
            style={style}
            {...options}
          >
            {text}
          </Normal>
        }
        const handleLinks = () => {
          const splitText = item.body.split('<%')
          const build = splitText.reduce((acc, partial) => {
            if (partial && !partial.includes('%>')) {
              return [
                ...acc,
                { text: partial },
              ]
            }
            if (partial && partial.includes('%>')) {
              const splitPartial = partial.split('%>')
              const addition = [links[splitPartial[0]]]
              if (splitPartial[1]) addition.push({ text: splitPartial[1] })
              return [
                ...acc,
                ...addition,
              ]
            }
            return acc
          }, [])

          return build.map(({text, link_to}, index) => {
            if (link_to) return <Link to={link_to} target="_blank" key={`${text}-${index}`}>{text}</Link>
            return renderBody(text, styling.colors.blue, {fontSize: '16px', fontFamily: 'WorkSans', display: 'inline'}, { key: `${text}-${index}` })
          })
        }
        const renderWithTitle = () => {
          return <div key={key}>
            <ListHeader>{title}</ListHeader>
            {links ? handleLinks() : renderBody(item.body, styling.colors.blue, {fontSize: '16px', fontFamily: 'WorkSans'})}
          </div>
        }
        const text = typeof(item) === 'string' ? renderBody(item) : item.render

        return <ListRow key={key}>
          <BulletPoint src={bulletPoints[bulletPointIndex]} alt={i18n.image.bullet_point_alt} />
          {
            title ?
              renderWithTitle()
              :
              text
          }
        </ListRow>
      })
    }
  </Container>
}

export default UnorderedList
