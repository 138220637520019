import React from 'react'
import styled from 'styled-components'

import styling from '../../lib/styling'
import i18n from '../../lib/i18n'

import Modal from '../atoms/Modal'
import { InvisibleButton } from '../atoms/Buttons'
import { Normal } from '../atoms/Fonts'

const ConfirmationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`

const ConfirmationBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
`

const BodyImage = styled.img`
  height: 100px;
`

const ConfirmationButtons = styled.div`
  display: flex;
  border-top: 1px solid ${styling.colors.blue};
  flex-direction: row;
  height: 55px;
  justify-content: space-between;
`

const ConfirmationButton = styled(InvisibleButton)`
  width: 50%;
  height: 100%;
`

const ConfirmButton = ({
  action,
  text,
  textStyle = {},
  style = {},
}) => {
  return <ConfirmationButton
    onClick={action}
    style={style}
  >
    <Normal
      color={styling.colors.blue}
      style={{
        textTransform: 'capitalize',
        ...textStyle
      }}
    >{text}</Normal>
  </ConfirmationButton>
}

const Confirmation = ({
  cancelText = i18n.cancel,
  cancelAction,
  confirmText = i18n.confirm,
  confirmAction,
  iconSrc,
  iconAlt = 'Confirm Icon',
  headerText,
  infoText = '',
}) => {
  return <Modal style={{height: '330px'}}>
    <ConfirmationContainer>
      <ConfirmationBody>
        {
          iconSrc && <BodyImage
            src={iconSrc}
            alt={iconAlt}
          />
        }
        {
          headerText && <Normal
            color={styling.colors.blue}
            style={{
              fontWeight: 700,
              padding: '20px',
              paddingBottom: 0,
            }}
          >{headerText}</Normal>
        }
        <Normal
            color={styling.colors.blue}
          >{infoText}</Normal>
      </ConfirmationBody>
      <ConfirmationButtons>
        <ConfirmButton
          action={cancelAction}
          text={cancelText}
        />
        <ConfirmButton
          action={confirmAction}
          text={confirmText}
          style={{
            borderLeft: `1px solid ${styling.colors.blue}`
          }}
        />
      </ConfirmationButtons>
    </ConfirmationContainer>
  </Modal>
}

export default Confirmation;