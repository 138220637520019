import React from 'react'
import styled from 'styled-components'

import Modal from '../atoms/Modal'
import { Longbutton } from '../atoms/Buttons'

const Image = styled.img`
  width: 128px;
  margin-bottom: 20px;
`

const Dialog = ({
  children,
  buttons = [],
  image = {
    src: null,
    alt: '',
  },
  onClose,
  style = {},
  onBack,
}) => {
  return <Modal 
    onClose={onClose}
    onBack={onBack}
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '15px', 
      ...style
    }}
  >
    {
      image?.src && <Image
        src={image.src}
        alt={image.src}
      />
    }
    {children}
    {
      buttons.map(({
        text,
        action,
        disabled,
        secondary
      }) => {
        return <Longbutton
          onClick={action}
          secondary={secondary}
          disabled={disabled}
          style={{marginTop: '15px'}}
          key={text}
        >
          {text}
        </Longbutton>
      })
    }
  </Modal>
}

export default Dialog
