import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import utils, { isEmpty } from '../../../../lib/utils'
import constants from '../../../../lib/constants'
import i18n from '../../../../lib/i18n'

import Tabs from '../../../../components/atoms/Tabs'

import YearMonthSelector from '../../SharedComponents/YearMonthSelector'

import PurchaseActivities from './PurchaseActivities'
import PurchaseSummary from './PurchaseSummary'

const {
  TAB_PURCHASE_ACTIVITIES,
  TAB_PURCHASE_SUMMARY,
} = constants.rx_dashboard

const Container = styled.div``

const Reports = ({
  getMonthlyRxReport,
  monthlyRxReports,
  comments,
  getAllUserComments,
}) => {
  const [yearMonth, setYearMonth] = useState({})
  const prevYearMonth = utils.usePrevious(yearMonth)

  const yearMonthStr = `${yearMonth.year}-${yearMonth.month < 10 ? `0${yearMonth.month}` : yearMonth.month}`

  utils.useEffectOnce(() => {
    getAllUserComments()
  })

  useEffect(() => {
    if ((!prevYearMonth || yearMonth.month !== prevYearMonth?.month) &&
      yearMonth.month && (isEmpty(monthlyRxReports) || isEmpty(monthlyRxReports[yearMonthStr]))) {
      getMonthlyRxReport(yearMonthStr)
    }
  }, [yearMonth, prevYearMonth, getMonthlyRxReport, monthlyRxReports, yearMonthStr])
  console.log({ monthlyRxReports })

  return <Container>
    <YearMonthSelector
      startYearMonth={{ year: 2021, month: 5 }}
      onSelect={(val) => setYearMonth(val)}
      selected={yearMonth}
    />
    <Tabs
      tabId="rx-reports-tabs"
      allTabs={[TAB_PURCHASE_ACTIVITIES, TAB_PURCHASE_SUMMARY,]}
      strings={i18n.rx_support}
      tabData={{
        [TAB_PURCHASE_ACTIVITIES]: {
          Component: PurchaseActivities,
          props: {
            data: monthlyRxReports && monthlyRxReports[yearMonthStr] && monthlyRxReports[yearMonthStr].purchases,
            comments,
          },
        },
        [TAB_PURCHASE_SUMMARY]: {
          Component: PurchaseSummary,
          props: {
            data: monthlyRxReports && monthlyRxReports[yearMonthStr] && monthlyRxReports[yearMonthStr].summary,
          },
        },
      }}
    />
  </Container>
}

export default Reports
