import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  selectors,
} from '../../../store'
import styled from 'styled-components'


import i18n from '../../../lib/i18n'
import constants from '../../../lib/constants'
import utils, { isEmpty } from '../../../lib/utils'

import {
  Row,
  Col,
} from 'react-bootstrap'
import {
  Check,
  X,
} from 'react-bootstrap-icons'

import styling from '../../../lib/styling'

import {
  Normal,
  SubHeaderBar,
  Bold,
} from '../../../components/atoms/Fonts'
import { Longbutton } from '../../../components/atoms/Buttons'
import Modal from '../../../components/atoms/Modal'
import { InputWithLabel } from '../../../components/atoms/Inputs'
import RadioButtons from '../../../components/atoms/RadioButtons'
import Checkbox from '../../../components/atoms/Checkbox'

const {
  START_DATE,
  NUM_ACTIVE_PILLS,
  NUM_PLACEBO_PILLS,
} = constants.pillPack

const {
  MODAL_FIX_SETTINGS,
  MODAL_DELETE_ACCOUNT,
  MODAL_CHANGE_EMAIL,
  DELETE_REASON_DEFAULT,
  DELETE_REASON_DISSATISFIED,
  DELETE_REASON_NO_PILL,
  DELETE_REASON_OTHER,
  DELETE_REASON_SWITCH,
} = constants.support

const {
  APP_START_DATE,
  EMAIL,
  HOMEZONE,
  PREFERRED_NAME,
  FIRST_REMINDER,
  FIRST_REMINDER_ON,
  SECOND_REMINDER,
  SECOND_REMINDER_ON,
  THIRD_REMINDER,
  THIRD_REMINDER_ON,
  PLACEBO_REMINDER,
  PLACEBO_REMINDER_ON,
  SKIPS_PACEBO,
  SUBSCRIPTION_LIFETIME_FREE,
  SUBSCRIPTION_LEGACY,
  HAS_SUBSCRIPTION,
} = constants.settings

const {
  cleanDate,
  today,
  toDateKey,
} = utils.dateTime

const DataBlock = styled.div`
  padding: 0 10px;
  margin-bottom: 20px;
`

const Feedback = ({
  success,
}) => {
  if (success) return <Check color="green" />
  return <X color="red" />
}

const deleteReasons = [
  {
    text: i18n.support.delete_reason_1,
    value: DELETE_REASON_SWITCH,
    key: DELETE_REASON_SWITCH,
  },
  {
    text: i18n.support.delete_reason_2,
    value: DELETE_REASON_NO_PILL,
    key: DELETE_REASON_NO_PILL,
  },
  {
    text: i18n.support.delete_reason_3,
    value: DELETE_REASON_DISSATISFIED,
    key: DELETE_REASON_DISSATISFIED,
  },
  {
    text: i18n.support.delete_reason_4,
    value: DELETE_REASON_DEFAULT,
    key: DELETE_REASON_DEFAULT,
  },
  {
    text: i18n.support.delete_reason_5,
    value: DELETE_REASON_OTHER,
    key: DELETE_REASON_OTHER,
  },
]

const TroubleShooting = ({
  email,
  updateSettings,
  deleteAccount,
  setLifetimeFree,
  updateEmail,
}) => {
  const customers = useSelector(selectors.customers)
  const customer = customers[email]
  const [settingsData, setSettingsData] = useState({})
  const [shownModal, setShownModal] = useState(null)
  const [error, setError] = useState(null)
  const [deleteReason, setDeleteReason] = useState(null)
  const [otherDeleteReason, setOtherDeleteReason] = useState('')
  const [showSubscription, setShowSubscription] = useState(false)
  const [hasLifetime, setHasLifetime] = useState(null)
  const [newEmail, setNewEmail] = useState('')
  const [userConfirmed, setUserConfirmed] = useState(false)

  const expectedSettings = [APP_START_DATE, EMAIL, HOMEZONE, PREFERRED_NAME, FIRST_REMINDER,
    FIRST_REMINDER_ON, SECOND_REMINDER, SECOND_REMINDER_ON, THIRD_REMINDER, THIRD_REMINDER_ON,
    PLACEBO_REMINDER, PLACEBO_REMINDER_ON, SKIPS_PACEBO,
  ]

  useEffect(() => {
    const settings = (customer && customer.settings && customer.settings.app) || {}
    if (settings) {
      const handleBool = (val, defaultVal) => {
        if (val === true || val === false) return val
        return defaultVal
      }
      setSettingsData({
        [APP_START_DATE]: (settings[APP_START_DATE] && settings[APP_START_DATE]._convertedPureDate) || '',
        [EMAIL]: settings[EMAIL] || email,
        [HOMEZONE]: settings[HOMEZONE] || '',
        [PREFERRED_NAME]: settings[PREFERRED_NAME] || '',
        [FIRST_REMINDER]: settings[FIRST_REMINDER] || 'PT0H',
        [FIRST_REMINDER_ON]: handleBool(settings[FIRST_REMINDER_ON], true),
        [SECOND_REMINDER]: settings[SECOND_REMINDER] || 'PT2H',
        [SECOND_REMINDER_ON]: handleBool(settings[SECOND_REMINDER_ON], true),
        [THIRD_REMINDER]: settings[THIRD_REMINDER] || 'PT3H',
        [THIRD_REMINDER_ON]: handleBool(settings[THIRD_REMINDER_ON], true),
        [PLACEBO_REMINDER]: settings[PLACEBO_REMINDER] || 'PT0H',
        [PLACEBO_REMINDER_ON]: handleBool(settings[PLACEBO_REMINDER_ON], true),
        [SKIPS_PACEBO]: handleBool(settings[SKIPS_PACEBO], false),
      })
    }
  }, [customer, email])

  useEffect(() => {
    const settings = (customer && customer.settings && customer.settings.subscription) || {}
    if (isEmpty(settings) || settings[HAS_SUBSCRIPTION] || settings[SUBSCRIPTION_LEGACY]) {
      setShowSubscription(false)
    } else {
      setShowSubscription(true)
      setHasLifetime(settings[SUBSCRIPTION_LIFETIME_FREE])
    }
  }, [customer, email])

  if (!customer) return <></>
  if (customer.deleted) return <DataBlock>
    <Row>{i18n.support.delete_success}</Row>
  </DataBlock>

  const totalNumPacks = Object.keys(customer.packs).length

  const hasNoSettings = isEmpty(customer.settings) || isEmpty(customer.settings.app)
  const hasMissingSettings = !hasNoSettings && expectedSettings.find(key => customer.settings.app && [null, undefined].includes(customer.settings.app[key]))

  const checkDaysForPack = (startDate, endDate) => {
    let currentDate = startDate
    const days = customer.days
    let foundDay = !isEmpty(days)
    while (currentDate <= endDate && foundDay) {
      foundDay = !!days[toDateKey(currentDate)]
      currentDate = currentDate.plus({ day: 1 })
    }
    return foundDay
  }


  const packCheck = () => {
    const lastPack = customer.packs[totalNumPacks - 1]
    if (!lastPack) return {
      hasPacks: false
    }
    const lastPackSpan = lastPack[NUM_ACTIVE_PILLS] + lastPack[NUM_PLACEBO_PILLS]
    const lastPackStart = cleanDate(lastPack[START_DATE])
    const lastPackEnd = lastPackStart.plus({ day: lastPackSpan - 1 })
    const hasCurrentPack = today <= lastPackEnd

    const hasDaysForPack = checkDaysForPack(lastPackStart, lastPackEnd)

    return {
      hasCurrentPack,
      lastPackStart,
      lastPackEnd,
      hasPacks: true,
      hasDaysForPack,
    }
  }

  const {
    hasPacks,
    hasCurrentPack,
    lastPackEnd,
    hasDaysForPack,
  } = packCheck()

  const handleSettingsChange = (key, val) => {
    setError(null)
    setSettingsData({
      ...settingsData,
      [key]: val,
    })
  }

  const handleSave = () => {
    const appStart = settingsData[APP_START_DATE]
    const match = appStart.match(/^20\d{2}-([01]\d)-([0123]\d)$/)
    const dateFormat = match && match[1] <= 12 && match[2] <= 31

    if (dateFormat) {
      updateSettings({
        ...customer.settings.app,
        ...settingsData,
        [APP_START_DATE]: {
          _convertedPureDate: settingsData[APP_START_DATE],
        },
      })
      setShownModal(null)
    } else setError('Date is formatted incorrect: YYYY-MM-DD, or is missing')
  }

  const handleChangeEmail = async () => {
    try {
      const response = await updateEmail(newEmail)
      if (response.error) {
        const msg = response.error?.errorInfo?.message || response.error?.message || response.error
        setError(msg)
      } else {
        setShownModal(null)
      }
      return true
    } catch (e) {
      setError(e)
      return false
    }
  }

  const handleDelete = async () => {
    try {
      const getReason = () => {
        const saveOtherReason = deleteReason === DELETE_REASON_OTHER && !isEmpty(otherDeleteReason)
        if (saveOtherReason) return otherDeleteReason
        
        return deleteReason || DELETE_REASON_DEFAULT
      }
      const resp = await deleteAccount(getReason())
      if (resp.error) setError(resp.error)
      else setShownModal(null)
    } catch (e) {
      setError(e)
    }
  }

  return <>
    <SubHeaderBar>
      {i18n.support.pill_pack_issues}
    </SubHeaderBar>
    <DataBlock>
      {
        !hasPacks ?
          <Row>{i18n.support.no_packs}</Row>
          :
          !hasCurrentPack ?
            <Row>{i18n.support.no_current_pack(lastPackEnd)}</Row>
            :
            <>
              <Row>
                <Col sm={4}>{i18n.support.has_pills_in_pack}</Col>
                <Col><Feedback success={hasDaysForPack} /></Col>
                {
                  !hasDaysForPack &&
                  <Col>{i18n.support.no_pills_in_pack_action}</Col>
                }
              </Row>
            </>
      }
    </DataBlock>
    <SubHeaderBar>
      {i18n.support.data_issues}
    </SubHeaderBar>
    <DataBlock>
      {
        hasNoSettings && <Row>{i18n.support.no_settings}</Row>
      }
      {
        hasMissingSettings && <Row>
          <Col>{i18n.support.missing_settings}</Col>
          <Col md={3}>
            <Longbutton
              narrowButton
              onClick={() => setShownModal(MODAL_FIX_SETTINGS)}
              style={{
                width: '100px'
              }}
            >
              {i18n.change}
            </Longbutton>
          </Col>
        </Row>
      }
      {
        !hasNoSettings && !hasMissingSettings && <Row>{i18n.support.looks_good}</Row>
      }
    </DataBlock>

    {
      showSubscription && <>
        <SubHeaderBar>
          { i18n.support.lifetime_header }
        </SubHeaderBar>
        <DataBlock>
          <Row>
            <Col>
              {hasLifetime ? i18n.support.lifetime_info_remove : i18n.support.lifetime_info_grant}
            </Col>
            <Col md={3}>
              <Longbutton
                narrowButton
                onClick={() => {
                  setLifetimeFree(!hasLifetime)
                  setHasLifetime(!hasLifetime)
                }}
                style={{
                  width: '100px',
                  height: '30px',
                }}
              >
                {i18n.yes}
              </Longbutton>
            </Col>
          </Row>
        </DataBlock>
      </>
    }

    <SubHeaderBar>
      {i18n.support.handle_account}
    </SubHeaderBar>
    <DataBlock>
      <Row>
        <Col>{i18n.support.change_email}</Col>
        <Col md={3}>
          <Longbutton
            narrowButton
            onClick={() => setShownModal(MODAL_CHANGE_EMAIL)}
            style={{
              width: '100px',
              height: '30px',
            }}
          >
            {i18n.change}
          </Longbutton>
        </Col>
      </Row>
    </DataBlock>

    <SubHeaderBar>
      {i18n.support.delete_account}
    </SubHeaderBar>

    <DataBlock>
      {
        customer.deleted && <Row>{i18n.support.delete_success}</Row>
      }
      {
        customer.deleted === false && <>
          <Row>{i18n.support.delete_fail}</Row>
          {
            customer.error && <Row>
              <Col>{i18n.support.delete_error}</Col>
              <Col>{customer.error}</Col>
            </Row>
          }
        </>
      }
      {
        !customer.deleted && <>
          <Row>
            <Col>{i18n.support.delete_info_2}</Col>
          </Row>
          <Row>
            <Col>
            {i18n.support.delete_info_1}
            </Col>
            <Col md={3}>
              <Longbutton
                narrowButton
                onClick={() => setShownModal(MODAL_DELETE_ACCOUNT)}
                style={{
                  width: '100px',
                  height: '30px',
                }}
              >
                {i18n.yes}
              </Longbutton>
            </Col>
          </Row>
        </>

      }
    </DataBlock>
    {
      shownModal === MODAL_FIX_SETTINGS && <Modal
        onClose={() => setShownModal(null)}
        style={{
          width: '500px',
          padding: '20px',
        }}
      >
        <SubHeaderBar>Update Settins</SubHeaderBar>
        {
          error && <Normal style={{
            color: styling.colors.wine,
            padding: '5px',
            border: `${styling.colors.wine} solid 1px`,
            background: styling.colors.lightterracotta,
          }}>
            {error}
          </Normal>
        }
        <InputWithLabel
          label="App Start Date (YYYY-MM-DD)"
          value={settingsData[APP_START_DATE]._convertedPureDate}
          onChange={val => handleSettingsChange(APP_START_DATE, val)}
          style={{ marginBottom: '10px' }}
        />
        <InputWithLabel
          label="Homezone (find in Mixpanel, like America/New_York)"
          value={settingsData[HOMEZONE]}
          onChange={val => handleSettingsChange(HOMEZONE, val)}
          style={{ marginBottom: '10px' }}
        />
        <Longbutton
          onClick={handleSave}
          disabled={error}
        >
          {i18n.save}
        </Longbutton>
      </Modal>
    }
        {
      shownModal === MODAL_CHANGE_EMAIL && <Modal
        onClose={() => setShownModal(null)}
        style={{
          width: '500px',
          padding: '20px',
        }}
      >
        <SubHeaderBar>{i18n.support.change_email}</SubHeaderBar>
        {
          error && <Normal style={{
            color: styling.colors.wine,
            padding: '5px',
            border: `${styling.colors.wine} solid 1px`,
            background: styling.colors.lightterracotta,
          }}>
            {error}
          </Normal>
        }
        <Row>
          <Col><Bold>Old Email:</Bold></Col>
          <Col><Normal>{email}</Normal></Col>
        </Row>
        <InputWithLabel
          label="New email"
          value={newEmail}
          onChange={val => setNewEmail(val)}
          style={{ marginBottom: '10px' }}
        />
        <Row>
          <Col>
          <Checkbox
            label="I confirmed the correctness of this change."
            onSelect={() => setUserConfirmed(!userConfirmed)}
            selected={userConfirmed}
          />
          </Col>
        </Row>
        <Longbutton
          onClick={handleChangeEmail}
          disabled={!userConfirmed || !newEmail || !newEmail.match(/.+@.+/)}
        >
          {i18n.save}
        </Longbutton>
      </Modal>
    }
    {
      shownModal === MODAL_DELETE_ACCOUNT && <Modal
        onClose={() => setShownModal(null)}
        style={{
          width: '500px',
          padding: '20px',
        }}
      >
        <SubHeaderBar>{i18n.support.delete_account}</SubHeaderBar>
        {
          error && <Normal style={{
            color: styling.colors.wine,
            padding: '5px',
            border: `${styling.colors.wine} solid 1px`,
            background: styling.colors.lightterracotta,
          }}>
            {error}
          </Normal>
        }
        <RadioButtons
          options={deleteReasons}
          selectedKey={deleteReason}
          onSelect={(val) => {
            if (val !== DELETE_REASON_OTHER && !isEmpty(otherDeleteReason)) {
              setOtherDeleteReason('')
            }
            setDeleteReason(val)
          }}
        />
        {
          deleteReason === DELETE_REASON_OTHER && <InputWithLabel
            label="Other reason:"
            value={otherDeleteReason}
            onChange={setOtherDeleteReason}
            style={{ marginBottom: '10px' }}
          />
        }
        <Longbutton
          onClick={handleDelete}
          disabled={error}
        >
          {i18n.save}
        </Longbutton>
      </Modal>
    }
  </>
}

export default TroubleShooting
