import React from 'react'
import styled, { keyframes } from 'styled-components'

import loadingSrc from '../../assets/images/icon-moons-loading.png'

import i18n from '../../lib/i18n'


const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
const Loader = styled.img`
  width: 100px;
  height: 100px;
  animation: ${spin} 2s linear infinite;
`


const LoadingSpinner = ({
  style = {},
}) => {
  return <div style={style}>
    <Loader src={loadingSrc} alt={i18n.image.loading_alt}/>
  </div>
}

export default LoadingSpinner
