import React, { useState } from 'react'
import { DateTime } from 'luxon'
import styled, { css } from 'styled-components'

import { selectors, store } from '../../../store'

import constants from '../../../lib/constants'
import i18n from '../../../lib/i18n'
import styling from '../../../lib/styling'

import {
  Row,
  Col,
} from 'react-bootstrap'
import {
  Normal,
  H2,
  CenterCol,
  RightCol,
} from '../../../components/atoms/Fonts'

const {
  DAY,
  MONTH,
  YEAR,
} = constants

const HeaderDay = styled(Col)`
  height: 30px;
  border-bottom: 1px ${styling.colors.lightslate} solid;
  color: ${styling.colors.wine};
  font-weight: bold;
  text-align: center;
`
const Day = styled(Col)`
  height: 130px;
  color: ${styling.colors.grey};
  border-bottom: 1px ${styling.colors.lightslate} solid;
  ${props => props.leftborder && css`
    border-left: 1px ${styling.colors.lightslate} solid;
  `}
  ${props => props.background && props.background !== 'default' ? css`
    background: ${props.background};
  ` : css`
    background: ${styling.colors.white};
  ` }
`
const DayNumber = styled.div`
  font-weight: bold;
  
  color: ${styling.colors.grey};
  border-radius: 20px;
  padding: 5px 10px;
  margin: 5px 0;
  ${props => props.background && props.background !== 'default' ? css`
    background: ${props.background};
  ` : css`
    background: ${styling.colors.lightterracotta};
  ` }
  ${props => props.bordercolor && props.bordercolor !== 'default' ? css`
    border-color: ${props.bordercolor};
  ` : css`
    border-color: ${styling.colors.lightterracotta};
  ` }
`

const Pill = ({
  pill,
}) => {
  if (!pill) return <></>
  const {
    date,
    pillTakenDate,
    automaticRecording,
  } = pill || {}

  if (date > DateTime.local()) return <></>
  const recordedString = automaticRecording === undefined ? '' : automaticRecording ? '- a' : '- m'

  if (!pillTakenDate) return <Normal size={14}>{i18n.support.pill_not_taken}</Normal>

  return <Normal size={14}>{i18n.support.pill_recorded} {recordedString}</Normal>
}

const Calendar = ({
  email,
}) => {
  const [selectedDate, setSelectedDate] = useState(DateTime.local())
  const selectedMonth = selectors.customerDataForCalendar(store.getState(), email, selectedDate)
  const weekdays = [i18n.sunday, i18n.monday, i18n.tuesday, i18n.wednesday, i18n.thursday, i18n.friday, i18n.saturday]

  if (!selectedMonth) return <></>

  const nextMonth = () => {
    const firstOfNextMonth = selectedDate.plus({month: 1}).startOf(MONTH)
    setSelectedDate(firstOfNextMonth)
  }

  const previousMonth = () => {
    const firstOfPreviousMonth = selectedDate.minus({month: 1}).startOf(MONTH)
    setSelectedDate(firstOfPreviousMonth)
  }

  return <>
    <Row>
      <Col onClick={previousMonth}>
        <H2 style={{cursor: 'pointer'}}>{'<'}</H2>
      </Col>
      <CenterCol onClick={() => setSelectedDate(DateTime.local())}>
        <H2 style={{cursor: 'pointer'}}>{selectedDate.monthLong}, {selectedDate.get(YEAR)}</H2>
      </CenterCol>
      <RightCol onClick={nextMonth}>
        <H2 style={{cursor: 'pointer'}}>></H2>
      </RightCol>
    </Row>
    <Row>
      {
        weekdays.map(dayString => <HeaderDay key={dayString}>{dayString}</HeaderDay>)
      }
    </Row>
    {
      selectedMonth && selectedMonth.weeks && selectedMonth.weeks.map((week, index) => {
        return <Row key={`week-${index}`}>
          {
            week && week.map((day, idx) => {
              const {
                key,
                date,
                packStarted,
                packEnded,
                pill,
                experiencesRecorded,
              } = day
              const styleProps = {}
              if (idx > 0 || key === '0-after') styleProps.leftborder = 'leftBorder'
              const isToday = date && date.equals(DateTime.local().startOf(DAY))

              return date ? <Day key={key} background={isToday ? styling.colors.lightterracotta : 'default'} {...styleProps}>
                <DayNumber
                  background={isToday ? styling.colors.white : 'default'}
                >{date.get(DAY)}</DayNumber>
                {
                  packStarted && <Normal size={14}>{i18n.support.pack_started(packStarted.id)}</Normal>
                }
                {
                  packEnded && <Normal size={14}>{i18n.support.pack_ended(packEnded.id)}</Normal>
                }
                <Pill pill={pill} />
                {
                  experiencesRecorded && <Normal size={14}>{i18n.support.experiences_recorded}</Normal>
                }
              </Day> : <Day key={key} {...styleProps} />
            })
          }
        </Row>
      })
    }
  </>
}

export default Calendar
