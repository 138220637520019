import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { withRouter, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import routes from '../../../routes/routes'
import {
  selectors,
  actions,
} from '../../../store'

import i18n from '../../../lib/i18n'
import constants from '../../../lib/constants'
import validation from '../../../lib/utils/validation'
import { isEmpty } from '../../../lib/utils'

import { InputWithValidation } from '../../atoms/Inputs'
import { Longbutton } from '../../atoms/Buttons'
import BoxAlert from '../../atoms/BoxAlert'
import ForgotPassword from './ForgotPassword'
import {
  FootNote,
} from '../../atoms/Fonts'
import Checkbox from '../../atoms/Checkbox'
import StrongPassword from '../../molecules/StrongPassword'
import ChangePassword from './ChangePassword'
import { AgreeToTerms, Help, LinkButton } from './shared'
import ConfirmEmail from './ConfirmEmail'
import OAuth from './oAuth'

const {
  RIGHTS_OWNER,
  RIGHTS_CUSTOMER_SUPPORT,
} = constants.support


const Container = styled.div`
  margin-top: 20px;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const LoginSignUpUI = ({
  successRoute = routes.home,
  verifyEmail = true,
  auth,
  account,
  userError,
  dismissError,
  history,
  login,
  signUp,
  authClaims,
  resendVerify,
  getFeatureSettings,
  sendResetEmail,
  featureSettings,
  strongPasswordSetAt,
  saveChangedPassword,
}) => {
  const queryParams = new URLSearchParams(useLocation().search)

  const [isLogin, setIsLogin] = useState(true)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [weakPassword, setWeakPassword] = useState(false)
  const [expiredPassword, setExpiredPassword] = useState(false)
  const [confirmEmail, setConfirmEmail] = useState(false)
  const [changePassword, setChangePassword] = useState(false)
  const [errors, setErrors] = useState({})
  const [hasError, setHasError] = useState(false)
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [optInMarketing, setOptInMarketing] = useState(true)

  useEffect(() => {
    const exitAuthPage = auth.email && (!verifyEmail || auth.emailVerified) && !weakPassword && !expiredPassword
    const isLoggedIn = Boolean(isLogin && auth.email)

    if (exitAuthPage) {
      history.replace({
        pathname: successRoute,
        search: queryParams.toString(),
      })
    } else if (auth.email && !auth.emailVerified && verifyEmail) {
      setConfirmEmail(true)
    } else if (isLoggedIn && (weakPassword || expiredPassword)) {
      setChangePassword(true)
    } else if (auth.email && auth.emailVerified) {
      setConfirmEmail(false)
    } else if (isLoggedIn && !weakPassword && !expiredPassword) {
      setChangePassword(false)
    }
  }, [auth, successRoute, history, email, verifyEmail, queryParams,
    weakPassword, expiredPassword, isLogin,])

  useEffect(() => {
    const loginEmail = queryParams.get('loginEmail')
    if (email.length === 0 && loginEmail) {
      setEmail(loginEmail)
    }
  }, [email, queryParams])

  useEffect(() => {
    if (auth.email) {
      const createdAt = new Date(parseInt(auth.createdAt))
      const lastUpdate = strongPasswordSetAt && new Date(strongPasswordSetAt)
      const today = new Date()
      const sixMonthsAgo = new Date(today.setMonth(today.getMonth() - 6))

      const isExpired = sixMonthsAgo > (lastUpdate || createdAt)
      setExpiredPassword(isExpired)
    }
  }, [auth, strongPasswordSetAt])

  useEffect(() => {
    const error = !isEmpty(userError) && userError !== undefined
    if (error && errors.user !== error) {
      setErrors({ ...errors, user: error })
    }
  }, [userError, errors])

  useEffect(() => {
    const checkErrors = Object.values(errors).find(e => e)
    setHasError(!!checkErrors)
  }, [errors])

  useEffect(() => {
    if (!featureSettings) {
      getFeatureSettings()
    }
  }, [featureSettings, getFeatureSettings])

  const handleTypeChange = () => {
    setIsLogin(!isLogin)
    setHasError(false)
  }

  const handleErrors = (error, key) => {
    if (errors[key] !== error) {
      setErrors({ ...errors, [key]: error })
    }
  }

  const dismissUserError = () => {
    if (userError && errors?.user) {
      setErrors({ ...errors, user: false })
      dismissError(constants.errors.LOGIN_SIGNUP)
    }
  }

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail)
    dismissUserError()
  }
  const handlePWChange = (newPassword) => {
    setPassword(newPassword)
    dismissUserError()
  }

  const buttonText = isLogin ? i18n.login : i18n.sign_up
  const fetchMeta = () => {
    const source = history.location.pathname
    const internalUser = authClaims[RIGHTS_OWNER] || authClaims[RIGHTS_CUSTOMER_SUPPORT]
    const inRXOnboarding = source.includes(routes.purchase)
    if (isLogin) {
      return { verifyEmail, inRXOnboarding, source, internalUser, weakPassword, expiredPassword }
    } else {
      return { optInMarketing, inRXOnboarding, source, internalUser }
    }
  }
  const buttonAction = () => {
    if (isLogin) {
      login(email, password, fetchMeta())
    } else {
      signUp(email, password, fetchMeta())
    }
  }
  const diableButton = hasError || isEmpty(email) || isEmpty(password) || (!isLogin && weakPassword)

  return <Container>
    {
      showForgotPassword && <ForgotPassword
        onClose={() => setShowForgotPassword(false)}
        account={account}
        sendResetEmail={sendResetEmail}
        email={email}
        setEmail={setEmail}
        handleErrors={handleErrors}
        errors={errors}
      />
    }
    {
      confirmEmail ?
        <ConfirmEmail
          setConfirmEmail={setConfirmEmail}
          resendVerify={resendVerify}
          confirmEmail={confirmEmail}
          auth={auth}
        />
        :
        changePassword ?
          <ChangePassword
            oldPassword={password}
            onChange={async (oldPassword, newPassword) => {
              const save = await saveChangedPassword(oldPassword, newPassword)
              if (save.success) {
                setWeakPassword(false)
                setExpiredPassword(false)
              }
            }}
            reason={weakPassword ? i18n.account.reason_pw_weak : i18n.account.reason_pw_expired}
            errors={userError}
          />
          :
          <>
            <InputWithValidation
              label={i18n.account.email_address}
              value={email}
              validations={[
                validation.isRequired,
                validation.regex(/.+@.+/, i18n.account.email_invalid),
              ]}
              onValidation={invalid => handleErrors(invalid, 'email')}
              onChange={newEmail => handleEmailChange(newEmail)}
            />
            <InputWithValidation
              label={i18n.account.password}
              type="password"
              value={password}
              validations={[
                validation.isRequired,
                validation.length({ min: 8 }, i18n.account.password_invalid),
              ]}
              onValidation={invalid => handleErrors(invalid, 'password')}
              onChange={(newPassword) => handlePWChange(newPassword)}
              style={{
                margin: '15px 0 25px',
              }}
            />
            {
              <StrongPassword
                password={password}
                onCheck={(isWeak) => setWeakPassword(isWeak)}
                style={{
                  display: isLogin ? 'none' : 'block',
                }}
              />
            }
            {
              hasError && errors && errors.user && !isEmpty(userError) && <BoxAlert
                textStyle={{ fontSize: '12px' }}
                style={{ marginBottom: '15px' }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    display: 'block',
                  }}
                >
                  {userError.code}
                </span>
                {userError.message}
              </BoxAlert>
            }
            {
              !isLogin && <Checkbox
                label={<FootNote align="left">
                  {i18n.account.opt_in_marketing}
                </FootNote>}
                onSelect={() => setOptInMarketing(!optInMarketing)}
                selected={optInMarketing}
                icons={{
                  style: {
                    width: '15px',
                    height: '15px',
                    alignSelf: 'start',
                    marginTop: '5px',
                  }
                }}
              />
            }
            <Longbutton
              style={{
                marginBottom: '10px',
              }}
              disabled={diableButton}
              onClick={buttonAction}
            >
              {buttonText} {i18n.account.with_email}
            </Longbutton>
            <OAuth
              buttonText={buttonText}
              meta={fetchMeta()}
            />
            {
              isLogin ?
                <>
                  <LinkButton onClick={handleTypeChange}>
                    {i18n.account.create_new}
                  </LinkButton>
                  <LinkButton onClick={() => setShowForgotPassword(true)}>
                    {i18n.account.forgot_password}
                  </LinkButton>
                  <Help />
                </>
                :
                <>
                  <AgreeToTerms />
                  <LinkButton onClick={handleTypeChange}>
                    {i18n.account.login}
                  </LinkButton>
                  <Help />
                </>
            }
          </>
    }
  </Container>
}

const LoginSignUp = connect(
  state => ({
    auth: selectors.auth(state),
    authClaims: selectors.authClaims(state),
    account: selectors.userAccount(state),
    userError: selectors.error(state, constants.errors.LOGIN_SIGNUP),
    featureSettings: selectors.featureSettings(state),
    strongPasswordSetAt: selectors.strongPasswordSetAt(state),
  }),
  dispatch => ({
    login: (email, password, data) => {
      actions.login(dispatch, { email, password }, data)
    },
    signUp: (email, password, data = {}) => actions.signUp(dispatch, { email, password }, data),
    saveChangedPassword: (oldPassword, newPassword) => actions.changePassword(dispatch, { newPassword, oldPassword }),
    sendResetEmail: (email) => actions.forgotPassword(dispatch, email),
    dismissError: (errorType) => actions.dismissError(dispatch, errorType),
    getFeatureSettings: () => actions.getFeatureSettings(dispatch),
    resendVerify: (email) => actions.resendVerify(dispatch, email),
  })
)(LoginSignUpUI)

export default withRouter(LoginSignUp)
