import React from 'react'

import styled, { css } from 'styled-components'

// TODO: get icons from Ana
import errorSrc from '../../assets/images/simpleIcons/icon-error-inverted.png'
import infoSrc from '../../assets/images/simpleIcons/icon-info-inverted.png'
import successSrc from '../../assets/images/simpleIcons/icon-success-inverted.png'

import styling from '../../lib/styling'
import constants from '../../lib/constants'

import { Normal } from './Fonts'

const {
  ERROR,
  INFO,
  SUCCESS,
} = constants.alert

const AlertContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${props => props.typeColor && css`
    border: ${props.typeColor} 1px solid;
  `}
  ${props => props.onClick && css`
    cursor: pointer;
  `}
`
const AlertText = styled(Normal)`
  margin: 15px;
  width: calc(100% - 50px);
  color: ${styling.colors.grey};
`
const TypeIndicator = styled.div`
  ${props => props.typeColor && css`
    background: ${props.typeColor};
  `}
  width: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Icon = styled.img`
  width: 20px;
`

const BoxAlert = ({
  type = ERROR,
  children,
  style = {},
  textStyle = {},
  onClick,
}) => {
  const styleValues = {
    [ERROR]: {
      typeColor: styling.colors.alert_error,
      src: errorSrc,
    },
    [INFO]: {
      typeColor: styling.colors.alert_warning,
      src: infoSrc,
    },
    [SUCCESS]: {
      typeColor: styling.colors.alert_success,
      src: successSrc,
    },
  }[type]

  return <AlertContainer
    style={style}
    typeColor={styleValues.typeColor}
    {...onClick ? { onClick } : {}}
  >
    <TypeIndicator
      typeColor={styleValues.typeColor}
    >
      <Icon
        src={styleValues.src}
      />
    </TypeIndicator>
    <AlertText
      style={textStyle}
      color={styleValues.color}
    >
      {children}
    </AlertText>
  </AlertContainer>
}

export default BoxAlert
