import constants from '../constants'
import config from '../../config'

const {
  EMAIL,
  UID,
  PHONE_NUMBER,
  DAYS,
  // PACKS,
  SETTINGS,
  TROUBLE_SHOOTING,
  // USER_STATE,
  USER_MANAGEMENT,
  USER_RIGHTS,
  MIXPANEL,
  SHARE_LINK,
  RX_DELIVERY,
  SUBSCRIPTION_HISTORY,
  SUBSCR_HIST_COMMISSION,
  SUBSCR_HIST_EVENT_TIME,
  SUBSCR_HIST_OFFER_CODE,
  SUBSCR_HIST_PERIOD,
  SUBSCR_HIST_PRICE,
  SUBSCR_HIST_PURCHASE_TIME,
  SUBSCR_HIST_STORE,
  SUBSCR_HIST_TAKEHOME,
  SUBSCR_HIST_TAX,
  SUBSCR_HIST_TRANSACTION, 
  SUBSCR_HIST_TYPE,
  SUBSCR_HIST_COUNTRY,
} = constants.support

const {
  HARDWARE,
  PERIPHERAL_NAME,
  PERIPHERAL_SERIAL_NUMBER,
  PERIPHERAL_FW_VERSION,
  PERIPHERAL_UUID,
  BATTERY_LEVEL,
  LAST_BATTERY_READ,
  GENERAL,
  APP_START_DATE,
  FIRST_PACK_CREATION,
  REMINDERS,
  FIRST_REMINDER,
  SECOND_REMINDER,
  THIRD_REMINDER,
  PLACEBO_REMINDER,
  PILL_TIME,
  PILL_TYPE,
  PILL_PACK,
  HOMEZONE,
  REMINDER_METHOD,
  REMINDER_NOTIFICATION,
  REMINDER_BOTH,
  REMINDER_TEXT,
  OS,
  OS_VERSION,
  ONBOARDING_FINISHED,
  HAS_PURCHASED,
  IS_CANCELED,
  MEMBERSHIP_TYPE,
  CANCELED_REASON,
  CANCELED_AT,
  SUBSCRIPTION,
  SUBSCRIPTION_LEGACY,
  SUBSCRIPTION_LIFETIME_FREE,
  SUBSCRIPTION_TYPE,
  SUBSCRIPTION_STORE,
  SUBSCRIPTION_UPDATE,
  SUBSCRIPTION_APP_STORE,
  HAS_SUBSCRIPTION,
  ACCOUNT_CREATED_AT,
  SUBSCRIPTION_DATA,
  SUBSCRIPTION_RELEASE,
  SUBSCRIPTION_PURCHASED_AT,
  SUBSCRIPTION_USER_TYPE,
} = constants.settings

const {
  ADDRESS_BILLING,
  ADDRESS_HOME,
  ADDRESS_SHIPPING,
  PAY_CREDIT_CARD,
} = constants.purchase

export default {
  title: 'Customers',
  user_email: 'Email of customer you are looking for',
  user_uid: 'UID of customer you are looking for',
  user_serialnumber: 'Serialnumber of customer you are looking for',
  email_needed: 'You need to provide an email address.',
  [EMAIL]: 'Email',
  [UID]: 'Identifier (UID)',
  [PHONE_NUMBER]: 'Phone',
  [DAYS]: 'Calendar',
  [MIXPANEL]: 'Mixpanel',
  [SHARE_LINK]: 'Share link',
  // [PACKS]: 'Pill Packs',
  [SETTINGS]: 'Settings',
  [TROUBLE_SHOOTING]: 'Trouble Shooting',
  [SUBSCRIPTION_HISTORY]: 'Subscription History',
  // [USER_STATE]: 'Badges',
  [USER_MANAGEMENT]: 'User Rights',
  [RX_DELIVERY]: 'Was EmmeRX',

  right_owner: 'Owner',
  right_customer_support: 'CS/CX',
  right_marketing: 'Marketing',
  right_owner_info: 'This user can see and do everything other users can do and can assign rights to user.',
  right_customer_support_info: 'User has access to Customer data and the RX Dashboard.',
  right_marketing_info: 'User can access the Marketing Tools and the RX Dashboard.',

  [HARDWARE]: 'Hardware',
  [PERIPHERAL_NAME]: 'Name',
  [PERIPHERAL_SERIAL_NUMBER]: 'Serial Number',
  [PERIPHERAL_UUID]: 'Identifier (Peripheral UUID)',
  [PERIPHERAL_FW_VERSION]: 'Firmware Version',
  [BATTERY_LEVEL]: 'Battery Level',
  [LAST_BATTERY_READ]: 'Last Battery Reading',
  [GENERAL]: 'General',
  [APP_START_DATE]: 'App Start Date',
  [FIRST_PACK_CREATION]: 'First Pack Created',
  [USER_RIGHTS]: 'User Rights',
  [REMINDERS]: 'Reminders',
  [PILL_TIME]: 'Pill Time',
  [PILL_PACK]: 'Pill Pack',
  [PILL_TYPE]: 'Pill Type',
  [FIRST_REMINDER]: 'First Reminder',
  [SECOND_REMINDER]: 'Second Reminder',
  [THIRD_REMINDER]: 'Third Reminder',
  [PLACEBO_REMINDER]: 'Placebo Reminder',
  [HOMEZONE]: 'Homezone',
  reminder_off: 'Reminder is off.',
  [REMINDER_METHOD]: 'Reminder Method',
  [REMINDER_NOTIFICATION]: 'Notification',
  [REMINDER_TEXT]: 'Text',
  [REMINDER_BOTH]: 'Notification and Text',
  [OS]: 'Phone OS',
  [OS_VERSION]: 'Phone OS version',
  [ONBOARDING_FINISHED]: 'Onboarding finished',
  [HAS_PURCHASED]: 'Purchased',
  [MEMBERSHIP_TYPE]: 'Membership type',
  [IS_CANCELED]: 'Is canceled',
  [CANCELED_AT]: 'Cancled at',
  [CANCELED_REASON]: 'Cancel reason',

  [SUBSCRIPTION]: 'Subscription',
  [SUBSCRIPTION_RELEASE]: 'Released on',
  [SUBSCRIPTION_DATA]: 'Data',
  [SUBSCRIPTION_LEGACY]: 'Is Legacy user',
  [SUBSCRIPTION_LIFETIME_FREE]: 'Lifetime free (set in Troubleshooting)',
  [ACCOUNT_CREATED_AT]: 'Account created at',
  [HAS_SUBSCRIPTION]: 'Has subscription',
  [SUBSCRIPTION_UPDATE]: 'Last subscription updated:',
  [SUBSCRIPTION_APP_STORE]: 'AppStore',
  [SUBSCRIPTION_TYPE]: 'Type of update',
  [SUBSCRIPTION_STORE]: 'Store',
  [SUBSCRIPTION_PURCHASED_AT]: 'Purchased at',
  [SUBSCRIPTION_USER_TYPE]: 'Access type',

  [SUBSCR_HIST_COMMISSION]: 'Commission',
  [SUBSCR_HIST_EVENT_TIME]: 'Event time',
  [SUBSCR_HIST_OFFER_CODE]: 'Offer code',
  [SUBSCR_HIST_PERIOD]: 'Period type',
  [SUBSCR_HIST_PRICE]: 'Price',
  [SUBSCR_HIST_PURCHASE_TIME]: 'Purchase time',
  [SUBSCR_HIST_STORE]: 'Store',
  [SUBSCR_HIST_TAKEHOME]: 'Takehome',
  [SUBSCR_HIST_TAX]: 'Tax',
  [SUBSCR_HIST_TRANSACTION]: 'Transaction ID', 
  [SUBSCR_HIST_TYPE]: 'Event type',
  [SUBSCR_HIST_COUNTRY]: 'Country',

  pack_started: (id) => `Pack ${id} started`,
  pack_ended: (id) => `Pack ${id} ended`,
  pro: 'POP',
  combo: 'COC',
  pill_type: (type, active, placebo) => `${type} ${active}/${placebo}`,

  pill_not_taken: 'No Pill',
  pill_taken_day: (date, time) => `Pill taken on ${date} at ${time}.`,
  pill_taken_time: (time) => `Pill taken at ${time}.`,
  pill_recorded: 'Pill recorded',
  experiences_recorded: 'Exp. recorded',
  available: 'available',
  installed: 'installed',

  mixpanel_link: (id) => `https://mixpanel.com/report/${config.MIXPANEL_ID}/view/1240827/profile#distinct_id=${id}`,

  // Troubleshooting
  pill_pack_issues: 'Pill Pack issues',
  data_issues: 'Data issues',
  delete_info_1: 'Account deletions can not be reversed. Make sure that the customer wants their account permanently deleted.',
  delete_info_2: 'Advice the user that since we are HIPAA complient and we are required to keep a copy for up to 10 years depending on the state.',
  delete_account: 'Delete this account',
  delete_success: 'Deletion was successful',
  delete_fail: 'Deletion was not sucessful. Please try again or contact Engineering for help.',
  delete_error: 'Error message',
  delete_reason_1: 'They are switching to a different tracking app',
  delete_reason_2: 'They are no longer on the pill',
  delete_reason_3: 'This app does not meet their needs',
  delete_reason_4: 'User did not share',
  delete_reason_5: 'Other',
  handle_account: 'Handle account issues',
  change_email: 'Change email',
  has_pills_in_pack: 'The current pack has valid data.',
  no_pills_in_pack_action: 'Ask customer to remove the current pack and create a new one.',
  no_packs: 'This customer has no packs.',
  no_current_pack: (endDate) => `This customer has no current/active pack. Last pack ended at ${endDate}`,
  no_settings: 'This user has no settings data. The user will not be able to log into their mobile account anymore. Potentially all their data is corrupt. Please reach out to the Dev Team.',
  has_rx: 'This user has RX data. Potentially this is to blame for the missing Settings data.',
  missing_settings: 'This user has missing fields in the Settings data.',
  looks_good: 'Looks good',
  no_data_alert: 'This is not usual that this data is empty. Please inform the Dev Team.',

  // Subscription troubleshooting
  lifetime_header: 'Free app access',
  lifetime_info_remove: 'Remove free app access',
  lifetime_info_grant: 'Grant free app access',

  // General Advice
  general_google_service: 'Did Google had any outages?',
  general_google_service_url: 'https://status.firebase.google.com/',
  general_android_app_version: 'Current Android version',
  general_ios_app_version: 'Current iOS version',

  // Rx/Telemed
  purchase_header: 'Purchase',
  purchased_at: 'Purchased At',
  next_purchase_at: 'Next Purchase Available',
  next_fill_at: 'Next RX fill at',
  membership_type: 'Membership type',
  can_purchase: 'Member Can Purchase',
  has_prescription: 'Has Prescription',
  payment_info: 'Payment Info',
  card_expired: 'Card Expiry',
  card_holder: 'Card Holder',
  card_number: 'Card number',
  last_card_use: 'Last Payment Success',
  auth_profile: 'Authorize Profile ID',
  auth_payment_profile: 'Authorize Payment Profile ID',
  auth_transaction: 'Authorize Transaction ID',
  invoice_id: 'Invoice ID (add -TM or -RX)',
  telemed_id: 'Telemed ID',
  rx_id: 'Rx Order ID',
  purchase_id: 'Purchase ID',
  // subscription: 'Packs per Shipment',
  onboarding: 'Onboarding Progress',
  intakeform_expired: 'Intake Form Expired At',
  intakeform_voided: 'Intake Form Voided At',
  in_checkout: 'In Checkout',
  onboarding_step: 'In Onboarding Step',
  checkout_step: 'In Checkout Step',
  addresses: 'Addresses',
  [`title_${ADDRESS_BILLING}`]: 'Billing',
  [`title_${ADDRESS_HOME}`]: 'Home',
  [`title_${ADDRESS_SHIPPING}`]: 'Shipping',

  [`header_${ADDRESS_BILLING}`]: 'Billing Address',
  [`header_${ADDRESS_HOME}`]: 'Home Address',
  [`header_${ADDRESS_SHIPPING}`]: 'Shipping Address',
  [`header_${PAY_CREDIT_CARD}`]: 'Credit Card',
  actions: 'Actions',
  cancel_membership: 'Cancel membership',
  move_next_fill: 'Move next fill',
  assign_new_prescription: 'Assign new prescription',
}
