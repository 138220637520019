import CryptoJS from 'crypto-js'

const defaultKey = 'blabla'

const encrypt = (word, key = defaultKey) => {
  const encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString()
  const encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
  return encData
}

const decrypt = (word, key = defaultKey) => {
  const decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8)
  const bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8)
  return JSON.parse(bytes)
}

export default {
  encrypt,
  decrypt,
}
