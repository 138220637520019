import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import i18n from '../../../lib/i18n'
import styling from '../../../lib/styling'
import validation from '../../../lib/utils/validation'
import { isEmpty } from '../../../lib/utils'
import { Longbutton } from '../../atoms/Buttons'
import { Bold, Normal } from '../../atoms/Fonts'
import { InputWithValidation } from '../../atoms/Inputs'
import StrongPassword from '../../molecules/StrongPassword'

const Container = styled.div`
  width: 300px;
`
const Header = styled(Bold)`
  color: ${styling.colors.wine};
  margin: 10px 0 15px;
`
const Error = styled(Normal)`
  font-size: 12px;
  color: ${styling.colors.wine};
`

const ChangePassword = ({
  onChange,
  errors,
  reason,
}) => {
  const [passwordOld, setPasswordOld] = useState('')
  const [passwordNew, setPasswordNew] = useState('')
  const [isWeakPassword, setIsWeakPassword] = useState(true)
  const [validationError, setValidationError] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    if (!isEmpty(errors)) {
      const msg = errors.code === 'auth/wrong-password' ? i18n.account.incorrect_old_password : errors.message
      setErrorMessage(msg)
    }
  }, [errors])


  const custom = validation.customCheck(() => !isEmpty(passwordOld) && !isEmpty(passwordNew) && passwordOld === passwordNew, i18n.account.pw_new_different_than_old)

  return <Container>
    <Header>{i18n.account.change_password}</Header>
    {
      reason && <Normal>{reason}</Normal>
    }
    <Normal>{i18n.account.please_update}</Normal>
    <InputWithValidation
      label={'Old password'}
      value={passwordOld}
      type="password"
      onChange={(val) => {
        setErrorMessage(null)
        setPasswordOld(val)
      }}
      style={{
        marginBottom: '15px',
        marginTop: '10px',
      }}
    />
    <InputWithValidation
      label={'New password'}
      value={passwordNew}
      type="password"
      onChange={(val) => {
        setErrorMessage(null)
        setPasswordNew(val)
      }}
      style={{
        marginBottom: '15px',
      }}
      validations={[custom]}
      onValidation={setValidationError}
    />
    {
      errorMessage && <Error>
        {errorMessage}
      </Error>
    }
    <StrongPassword
      onCheck={setIsWeakPassword}
      password={passwordNew}
      oldPassword={passwordOld}
    />
    <Longbutton
      onClick={() => onChange(passwordOld, passwordNew)}
      disabled={isWeakPassword || !passwordOld || !passwordNew || validationError || errorMessage}
    >
      Save
    </Longbutton>
  </Container>
}

export default ChangePassword
