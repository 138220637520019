import React from 'react'
import styled from 'styled-components'
import { DateTime } from 'luxon'

import i18n from '../../lib/i18n'
import constants from '../../lib/constants'

import instagramSrc from '../../assets/images/icon-instagram.png'
import facebookSrc from '../../assets/images/icon-facebook.png'

import { IconLink } from '../atoms/Links'
import { FooterText } from '../atoms/Fonts'


const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`

const SideMenuFooter = () => {
  const {
    EMME_INSTAGRAM,
    EMME_FACEBOOK,
  } = constants.links

  return <div>
    <SocialMediaContainer>
      <IconLink
        href={EMME_INSTAGRAM}
        src={instagramSrc}
        alt={i18n.image.instagram_alt}
        target="_blank"
      />
      <IconLink 
        href={EMME_FACEBOOK}
        src={facebookSrc}
        alt={i18n.image.facebook_alt}
        style={{marginLeft: '40px'}}
        target="_blank"
      />
    </SocialMediaContainer>
    <FooterText>{i18n.footer_text_1(DateTime.local().year)}</FooterText>
    <FooterText>{i18n.footer_text_2}</FooterText>
  </div>
}

export default SideMenuFooter
