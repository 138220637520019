import React from 'react'
import styled from 'styled-components'

import swirlRight from '../../assets/images/swirl-right.png'
import swirlLeft from '../../assets/images/swirl-left.png'

import { useWindowDimensions } from '../../lib/utils/windowDimensions'

const Body = styled.div(({
	sm,
}) => {
	const leftSize = sm ? 30 : 20
	const rightSize = sm ? 50 : 30
	return `
		background-image: url(${swirlRight}), url(${swirlLeft});
		background-position: 100% 100%, 0% 5%;
		background-repeat: no-repeat, no-repeat;
		background-size: ${rightSize}%, ${leftSize}%;
    width: 100%;
	`
})

const MainBody = ({children, style = {}}) => {
  const { isSmOrSmaller } = useWindowDimensions()

  return <Body sm={isSmOrSmaller} style={style}>
    {children}
  </Body>
}

export default MainBody
