import React from 'react'
import styled from 'styled-components'
import { Longbutton } from '../../components/atoms/Buttons'

import { Normal, SubHeaderBar } from '../../components/atoms/Fonts'
import { InputWithButton } from '../../components/atoms/Inputs'

import constants from '../../lib/constants'
import styling from '../../lib/styling'

const {
  TWO_FACTOR_MISSING_PHONE,
} = constants.settings

const Container = styled.div`
  width: 700px;
  min-height: 300px;
  padding: 40px 20px;
  margin: 30px auto;
  background: ${styling.colors.white};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`

const Header = styled(SubHeaderBar)`
  width: 100%;
`
const Error = styled(Normal)`
  color: ${styling.colors.wine};
`

const TwoFactor = ({
  code,
  setCodeConfirmed,
  resendCode,
  codeError,
}) => {
  return <Container>
    {
      code === TWO_FACTOR_MISSING_PHONE ?
        <>
          <Header>There is a problem!</Header>
          <Normal>We do not have your phone in the system yet. Please connect with Engineering to set this up.</Normal>
        </>
        :
        <>
          <Header>A code was sent to your phone.</Header>
          <InputWithButton
            placeholder="Code"
            onSubmit={(typedCode) => setCodeConfirmed(typedCode === code)}
            width="300px"
          />
          {
            codeError && <Error>The code is not correct.</Error>
          }
          <Longbutton
            onClick={resendCode}
            secondary
            style={{
              width: "300px"
            }}
          >
            Resend code
          </Longbutton>
        </>
    }
  </Container>
}

export default TwoFactor