import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'

import { isEmpty } from '../../lib/utils'

import { store } from '../index'
import selectors from '../selectors'

import { callFn } from './utils'
import * as action from './index'

const httpFunctions = {
  addToNewsletter: 'addToNewsletter',
}

export const updateMarketingList = async (dispatch, user, options = {}) => {
  if (options.newsletter || options.insurance_updates || options.state_updates) {
    const data = { ...user, ...options }
    return await callFn(httpFunctions.addToNewsletter, data)
  } else {
    const settings = await selectors.userSettings(store.getState())
    if (!settings || isEmpty(settings)) await action.getUserSettings(dispatch, user.uid)
    const klaviyoId = await selectors.userKlaviyoId(store.getState())
    if ((klaviyoId && options.updates) || (!klaviyoId && options.isOptIn)) {
      options = {
        updates: {},
        beforeLogin: false,
        klaviyoId,
        ...options,
      }
      const data = { ...user, ...options, klaviyoId }
      return await callFn(httpFunctions.addToNewsletter, data)
    }
  }
}