import React from 'react'
import styled from 'styled-components'

import constants from '../../../lib/constants'
import support from '../../../lib/i18n/support'
import utils from '../../../lib/utils'
import styling from '../../../lib/styling'

const {
  formatDateTime,
} = utils.dateTime

const {
  SUBSCR_HIST_COMMISSION,
  SUBSCR_HIST_OFFER_CODE,
  SUBSCR_HIST_PERIOD,
  SUBSCR_HIST_PRICE,
  SUBSCR_HIST_PURCHASE_TIME,
  SUBSCR_HIST_STORE,
  SUBSCR_HIST_TAKEHOME,
  SUBSCR_HIST_TAX,
  SUBSCR_HIST_TRANSACTION, 
  SUBSCR_HIST_TYPE,
  SUBSCR_HIST_CURRENCY,
  SUBSCR_HIST_COUNTRY,
} = constants.support;

const eventSections = [
  SUBSCR_HIST_TYPE,
  SUBSCR_HIST_STORE,
  SUBSCR_HIST_PURCHASE_TIME,
  SUBSCR_HIST_PERIOD,
  SUBSCR_HIST_OFFER_CODE,
  SUBSCR_HIST_COUNTRY,
  SUBSCR_HIST_TRANSACTION,
  SUBSCR_HIST_PRICE,
  SUBSCR_HIST_TAX,
  SUBSCR_HIST_COMMISSION,
  SUBSCR_HIST_TAKEHOME,
];
const timeEvents = [SUBSCR_HIST_PURCHASE_TIME];
const calcFromPrice = [SUBSCR_HIST_TAX, SUBSCR_HIST_COMMISSION, SUBSCR_HIST_TAKEHOME];
const currencyReturns = [SUBSCR_HIST_PRICE, SUBSCR_HIST_TAX, SUBSCR_HIST_COMMISSION, SUBSCR_HIST_TAKEHOME];

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 20%;
`;
const SectionValue = styled.div``;
const SectionTitle = styled(SectionValue)`
  font-weight: bold;
  padding-bottom: 5px;
  margin-bottom: 5px;
`;

const Section = (({
  title,
  value,
}) => {
  return <SectionBox>
    <SectionTitle>{title}</SectionTitle>
    <SectionValue>{value}</SectionValue>
  </SectionBox>;
});

const SummaryContainer = styled.div`
  padding: 20px;
  background: ${styling.colors.greypink};
  border: 2px ${styling.colors.slate} solid;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Summary = (({
  history = [],
}) => {
  const calcSum = (key) => {
    const sum = history.reduce((acc, event) => {
      const val = parseFloat(event[key])
      if (val && key === SUBSCR_HIST_PRICE) {
        return acc += val
      } else if (val) {
        const price = parseFloat(event[SUBSCR_HIST_PRICE])
        return acc += (val * price)
      }
      return acc
    }, 0)
    return sum
  };
  const currency = history[0][SUBSCR_HIST_CURRENCY]
  return <SummaryContainer>
    {
      [SUBSCR_HIST_PRICE, SUBSCR_HIST_COMMISSION, SUBSCR_HIST_TAKEHOME, SUBSCR_HIST_TAX].map(numKey => {
        return <Section
          key={numKey}
          title={support[numKey]}
          value={`${currency} ${calcSum(numKey)}`}
        />
      })
    }
  </SummaryContainer>
});

const HistoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid grey;
`;

// TODO: See how this looks where we have data
const SubscriptionHistory = ({
  history = {},
}) => {
  const handleValue = (key, section) => {
    const event = history[key]
    const val = event[section]
    let string = ''
    if (currencyReturns.includes(section)) {
      string += `${event[SUBSCR_HIST_CURRENCY]} `
    }
    if (timeEvents.includes(section)) {
      const date = new Date(val)
      string += formatDateTime(date)
    } else if (calcFromPrice.includes(section)) {
      string += parseFloat(event[SUBSCR_HIST_PRICE]) * parseFloat(val)
    } else {
      string += val
    }
    return string
  }

  return <>
    <Summary history={Object.values(history)}/>
    {
      Object.keys(history).map((key) => {
        return <HistoryContainer>
          {
            eventSections.map(section => {
              if (!history[key][section]) return null
    
              return <Section
                key={`${key}-${section}`}
                title={support[section]}
                value={handleValue(key, section)}
              />
            })
          }
        </HistoryContainer>
      })
    }
  </>
};

export default SubscriptionHistory
