import React from 'react'
import styled, { css } from 'styled-components'

import styling from '../../lib/styling'
import { useWindowDimensions } from '../../lib/utils/windowDimensions'
import constants from '../../lib/constants'
import i18n from '../../lib/i18n'

import { IconLink } from '../atoms/Links'
import SideMenuFooter from '../molecules/SideMenuFooter'

import appleSrc from '../../assets/images/download-apple.png'
import googleSrc from '../../assets/images/download-google.png'
import AuthorizeNet from '../atoms/Icons/AuthorizeNet'
import Hipaa from '../atoms/Icons/Hipaa'
import FSAandHSA from '../atoms/Icons/FSAandHSA'

const {
  EMME_APPLE_STORE,
  EMME_ANDROID_STORE,
} = constants.links

const FooterContainer = styled.div`
  display: flex;
  background: ${styling.colors.terracotta};
  justify-content: space-between;
  flex-direction: row;
  padding: 30px 20px 30px 20px;
  min-height: 200px;
  ${props => props.sm && css`
    width: 100%;
    flex-direction: column-reverse;
    padding: 30px 20px 60px 20px;
    min-height: 460px;
  `}
`

const SyledIconLink = styled(IconLink)``

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 33.33%;
  ${props => props.sm && css`
    width: 100%;
  `}
  ${SyledIconLink} {
    display: block;
  }
`
const SpacerCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
`

const CertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const CertRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80px;
  width: 230px;
`


const MainFooter = ({
  inPurchase,
}) => {
  const {
    isSmOrSmaller,
    isLgOrBigger,
    isMD,
  } = useWindowDimensions()

  const certifications = <CertContainer>
    <CertRow style={{ justifyContent: 'center' }}>
      <Hipaa />
    </CertRow>
    <CertRow>
      <FSAandHSA />
      <AuthorizeNet />
    </CertRow>
  </CertContainer>

  return <FooterContainer sm={isSmOrSmaller}>
    {
      (isLgOrBigger || isMD) && <SpacerCol>
        {certifications}
      </SpacerCol>
    }
    <FlexCol sm={isSmOrSmaller} lg={isLgOrBigger}>
      <SideMenuFooter />
    </FlexCol >
    {
      isSmOrSmaller && <FlexCol sm={isSmOrSmaller} lg={isLgOrBigger}>
        {certifications}
      </FlexCol >
    }
    {
      inPurchase ?
        <SpacerCol />
        :
        <FlexCol
          sm={isSmOrSmaller}
          lg={isLgOrBigger}
          style={{
            ...isSmOrSmaller ? {
              alignItems: 'flex-end',
              marginBottom: '40px',
              flexDirection: 'row',
              width: '300px',
              alignSelf: 'center',
              justifyContent: 'space-between',
            } : {
              alignItems: 'center',
              marginBottom: 0,
            },
          }}
        >
          <SyledIconLink
            src={appleSrc}
            alt={i18n.image.app_store_alt}
            href={EMME_APPLE_STORE}
            target="_blank"
          />
          <SyledIconLink
            src={googleSrc}
            alt={i18n.image.google_play_alt}
            href={EMME_ANDROID_STORE}
            target="_blank"
          />
        </FlexCol>
    }
  </FooterContainer>
}

export default MainFooter
