import React from 'react'
import styled from 'styled-components'
import styling from '../../lib/styling'

const {
  white,
  blue,
  lightslate,
  grey,
} = styling.colors

export const Longbutton = styled.button(({
  secondary = false,
  width = '100%',
  height,
  disabled = false,
  styling = 'two-corners',
  fontSize,
  narrowButton = false,
  maxWidth,
}) => {
  const getBorderStyle = () => {
    if (styling === 'two-corners') return '0 10px'
    if (styling === 'pill' || styling === 'status') return '20px'
    return 'none'
  }
  const getWidth = () => {
    if (styling === 'status') return 'auto'
    return width
  }
  const getPadding = () => {
    if (styling === 'status') return '5px 15px'
    return ''
  }
  const baseColor = disabled ? lightslate : secondary ? white : blue
  const highlightColor = disabled ? grey : secondary ? blue : white

  return `
    background: ${baseColor};
    padding: ${getPadding()};
    color: ${highlightColor};
    font-size: ${fontSize ? fontSize : narrowButton ? 11 : 14}px;
    width: ${getWidth()};
    min-height: ${height ? height : narrowButton ? '24px' : '40px'};
    border-radius: ${getBorderStyle()};
    border: 1px solid ${disabled ? baseColor : blue};
    outline: none;
    text-transform: capitalize;
    ${maxWidth ? `max-width: ${maxWidth};` : null}
    &:hover, &:active {
      background: ${disabled ? baseColor : highlightColor};
      color: ${disabled ? highlightColor : baseColor};
      outline: none;
    }
    &:visited, &:focus {
      background: ${baseColor};
      color: ${highlightColor};
      outline: none;
    }
  `
})

export const LongbuttonLink = ({
  children,
  href,
  target = '_self',
  disabled = false,
  styling,
  narrowButton,
  style = {},
}) => {
  return <a
    href={href}
    target={target}
    disabled={disabled}
    style={style}
  >
    <Longbutton disabled={disabled} styling={styling} narrowButton={narrowButton}>
      {children}
    </Longbutton>
  </a>
}

export const InvisibleButton = styled.button`
  background: none;
  border: 0;
  outline: none;
  &:active, &:focus, &:hover, &:visited {
    background: none;
    border: 0;
    outline: none;
    box-shadow: ${styling.colors.blue};
  }
`

const StyledIcon = styled.img`
  &:active, &:focus, &:hover, &:visited {
    opacity: 0.6;
  }
`

export const IconButton = ({
  onClick,
  disabled = false,
  src,
  alt = 'Button with icon',
  style = {}
}) => {

  return <InvisibleButton disabled={disabled} onClick={onClick} style={style}>
    <StyledIcon src={src} alt={alt} />
  </InvisibleButton>

}

export const BigLinkButton = styled.button`
  color: ${styling.colors.wine};
  font-family: IntroBold;
  font-size: 32px;
  padding: 0;
  margin: 0;
  line-height: 90px;
  font-weight: 400;
  text-decoration: none;
  display: block;
  background: none;
  border: 0;
  text-transform: capitalize;
  &:visited {
    color: ${styling.colors.wine};
    outline: none;
  }
  &:hover, &:active {
    color: ${styling.colors.blue};
    outline: none;
  }
`
