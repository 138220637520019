//
// App-wide config information
//
// Set variables in .env
// to override for local development create a .env.local
// see https://create-react-app.dev/docs/adding-custom-environment-variables

// convert a string to an int, in a way that Typescript doesn't complain about
function toInt(str) {
	if (!str) return NaN;

	return parseInt(str, 10);
}


const config = {
	// Firebase
	FIREBASE_APIKEY: process.env.REACT_APP_FIREBASE_API_KEY,
	FIREBASE_AUTHDOMAIN: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
	FIREBASE_DATABASEURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
	FIREBASE_PROJECTID: process.env.REACT_APP_FIREBASE_PROJECTID,
	FIREBASE_STORAGEBUCKET: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
	FIREBASE_MESSAGESENDERID: process.env.REACT_APP_FIREBASE_MESSAGESENDERID,
	FIREBASE_APPID: process.env.REACT_APP_FIREBASE_APPID,

	// config values are minutes, but we need it in miliseconds
	USER_IDLE_TIMEOUT: (toInt(process.env.REACT_APP_USER_IDLE_TIMEOUT) || 10) * 60 * 1000,
	ADMIN_IDLE_TIMEOUT: (toInt(process.env.REACT_APP_ADMIN_ILDE_TIMEOUT) || 10) * 60 * 1000,
	ENV: process.env.REACT_APP_ENV,

	//////
	// development related configs

	// should be a string with the URL where the functions emulator is running
	FORCE_LOCAL_FUNCTIONS: process.env.REACT_APP_FORCE_LOCAL_FUNCTIONS,
	ENABLE_REDUX_LOGGER: process.env.REACT_APP_ENABLE_REDUX_LOGGER ? true : false,
	LOG_CONFIG_AT_START: process.env.REACT_APP_LOG_CONFIG_AT_STARTUP ? true : false,
	// use "local" persistence
	// by default we use a "session" persistence so the user is logged out
	// if they close the tab. "local" also means all tabs share login info.
	FORCE_LOGIN_PERSISTENCE_LOCAL: process.env.REACT_APP_FORCE_LOGIN_PERSISTENCE_LOCAL ? true : false,
	// FIXME remove when we're not whitelisting anymore
	DISABLE_USER_WHITEIST: process.env.REACT_APP_DISABLE_USER_WHITEIST ? true : false,

	// Address validation
	LOB_ENV: process.env.REACT_APP_LOB_ENV,
	LOB_API_KEY: process.env.REACT_APP_LOB_ENV === 'live' ? process.env.REACT_APP_LOB_LIVE_KEY : process.env.REACT_APP_LOB_TEST_KEY,

	MIXPANEL_ID: process.env.REACT_APP_MIXPANEL_ID,
	MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,
	AUTHORIZE_ID: process.env.REACT_APP_AUTHORIZE_ID,
	AUTHORIZE_TRANSACTION_KEY: process.env.REACT_APP_AUTHORIZE_TRANSACTION_KEY,
	AUTHOIZE_ENV: process.env.REACT_APP_AUTHOIZE_ENV
};

if (config.LOG_CONFIG_AT_START) {
	console.log('config:');
	console.log(JSON.stringify(config, null, 2));
}

export default config;
