import React from 'react'

import i18n from '../../lib/i18n'

import { HeaderSection, Info } from '.'

const VerifyEmail = ({
  authStatus,
}) => {

  const renderExpired = () => <>
    <Info>{i18n.auth.verify_email_timeout}</Info>
  </>

  const renderDone = () => <>
    <Info>{i18n.auth.verify_email_done}</Info>
  </>

  const renderVerifyEmail = () => <>
    <Info>{i18n.auth.verify_email_success_info}</Info>
  </>

  return <>
    <HeaderSection
      isDone={authStatus.done}
      text={authStatus.done ? i18n.auth.verify_email_success_header : i18n.auth.verify_email_issue_header}
    />

    {
      authStatus.expired && renderExpired()
    }
    {
      authStatus.done && renderDone()
    }
    {
      authStatus.loggedIn && renderVerifyEmail()
    }
  </>

};

export default VerifyEmail
