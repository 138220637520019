import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'

import constants from '../../lib/constants'
import { store } from '../index'
import selectors from '../selectors'
import actionType from './types'
import * as action from './index'

import {
  callFn,
} from './utils'

const {
  WEB_SIGNUP,
} = constants.marketing

const httpFunctions = {
  getUniversalSettings: 'getUniversalSettings',
  getUniversalOpenSettings: 'getUniversalOpenSettings',
  getUserSettings: 'getUserSettings',
  updateUserSettings: 'updateUserSettings',
  initUserSettings: 'initUserSettings',
  getFeatureSettings: 'getFeatureSettings',
}

export const getUserSettings = (dispatch, uid) => {
  callFn(httpFunctions.getUserSettings, { uid })
    .then(response => {
      return dispatch({
        type: actionType.userSettings,
        payload: response,
      })
    })
    .catch(error => {
      dispatch({
        type: actionType.userSettings,
        payload: { error },
      })
    })
}

export const initUserSettings = (dispatch, uid, options = {}) => {
  callFn(httpFunctions.initUserSettings, { uid, options })
    .then(() => {
      const settings = action.getUserSettings(dispatch, uid)
      const user = selectors.auth(store.getState())
      const options = {
        updates: {
          [WEB_SIGNUP]: true,
        }
      }
      action.updateMarketingList(dispatch, user, options)
      return settings
    })
    .then(settings => {
      return dispatch({
        type: actionType.userSettings,
        payload: settings,
      })
    })
    .catch(error => {
      dispatch({
        type: actionType.userSettings,
        payload: { error },
      })
    })
}

export const updateUserSettings = (dispatch, uid, settings) => {
  callFn(httpFunctions.updateUserSettings, { uid, settings })
    .then(response => {
      return dispatch({
        type: actionType.userSettings,
        payload: response,
      })
    })
    .catch(error => {
      dispatch({
        type: actionType.getUniversalSettings,
        payload: { error },
      })
    })
}

export const getUniversalSettings = (dispatch) => {
  callFn(httpFunctions.getUniversalSettings)
    .then(response => {
      dispatch({
        type: actionType.getUniversalSettings,
        payload: response,
      })
    })
    .catch(error => {
      dispatch({
        type: actionType.getUniversalSettings,
        payload: { error },
      })
    })
}

export const getFeatureSettings = (dispatch) => {
  callFn(httpFunctions.getFeatureSettings)
    .then(response => {
      dispatch({
        type: actionType.getFeatureSettings,
        payload: response,
      })
    })
    .catch(error => {
      dispatch({
        type: actionType.getFeatureSettings,
        payload: { error },
      })
    })
}

export const getUniversalOpenSettings = (dispatch) => {
  callFn(httpFunctions.getUniversalOpenSettings)
    .then(response => {
      dispatch({
        type: actionType.getUniversalOpenSettings,
        payload: response,
      })
    })
    .catch(error => {
      dispatch({
        type: actionType.getUniversalOpenSettings,
        payload: { error },
      })
    })
}