import React, { useState, useEffect } from 'react'
import 'firebase/auth'
import firebase from 'firebase/app'
import styled, { css } from 'styled-components'
import { useLocation } from 'react-router-dom'
import {
  BrowserView, 
  MobileView,
} from 'react-device-detect'
import {
	Container,
	Row,
	Col,
} from 'react-bootstrap'

import '../../services/firebase'
import ladiesLeftSrc from '../../assets/images/ladies-left.png'
import ladiesRightSrc from '../../assets/images/ladies-right.png'
import emailSentSrc from '../../assets/images/illustrations/email-sent.png'
import checkedSrc from '../../assets/images/icon-checked-circle.png'

import constants from '../../lib/constants'
import i18n from '../../lib/i18n'
import styling from '../../lib/styling'

import Logo from '../../components/atoms/Logo'
import LoadingSpinner from '../../components/atoms/LoadingSpinner'
import ForgotPassword from './ForgotPassword'
import VerifyEmail from './VerifyEmail'
import RecoverEmail from './RecoverEmail'


const auth = firebase.auth();

const {
  MODE,
  OOB_CODE,
  RESET_PASSWORD,
  VERIFY_EMAIL,
  RECOVER_EMAIL,
  ERROR_EXPIRED_CODE,
  ERROR_INVALID_CODE,
} = constants.auth

const Header = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: ${styling.colors.blue};
  margin-top: 30px;
  text-align: center;
`
export const Info = styled.p`
  color: ${styling.colors.blue};
  text-align: center;
`

const MobileContainer = styled.div`
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: ${styling.colors.white};
`
const BowserContainer = styled(Container)`
	align-items: center;
	justify-content: center;
	height: 100%;
  width: 100%;
	position: absolute;
	display: flex;
`
const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 67px;
  height: 100%;
  width: 100%;
  padding: 20px 40px;
`
const PageRow = styled(Row)`
	width: 100%;
	align-items: center;
	justify-content: center;
`
const LadiesContainer = styled(Col)`
	padding: 0 5px;
`
const LadiesImg = styled.img`
	width: 100%;
`
const LoginContainer = styled(Col)`
	background: ${styling.colors.white};
	width: 100%;
	box-shadow: 0px 2px 10px rgba(196, 196, 196, 0.7);
	border-radius: 32px;
	padding-top: 84px;
	padding-bottom: 40px;
	text-align: center;
	position: initial;
`
const EmailSentImage = styled.img`
  height: 108px;
	${props => props.isDone && css`
   	height: 86px;
  `}
`

export const HeaderSection = ({
  isDone = false,
  text,
}) => {
  return <>
    <EmailSentImage src={isDone ? checkedSrc : emailSentSrc} isDone={isDone}/>
    <Header>
      {text}
    </Header>
  </>
}

const Ladies = ({ src, alt }) => {
  const classNameSm = 'd-md-block d-none'
  return <LadiesContainer md={3} lg={4} className={classNameSm}>
    <LadiesImg src={src} alt={alt} />
  </LadiesContainer>
}

export const defaultStatus = {expired: false, loading: false, loggedIn: false, done: false}

const AuthActions = () => {
  const searchParams = new URLSearchParams(useLocation().search)
  const oobCode = searchParams.get(OOB_CODE)
  const mode = searchParams.get(MODE)


  const [authStatus, setAuthStatus] = useState({...defaultStatus, loading: true})
  const [email, setEmail] = useState(null)

  useEffect(() => {
    const handleError = (e) => {
      if (e.code === ERROR_EXPIRED_CODE) {
        setAuthStatus({...defaultStatus, expired: true})
      }
      if (e.code === ERROR_INVALID_CODE) {
        setAuthStatus({...defaultStatus, done: true})
      }
    }
    const getEmail = async () => {
      try {
        const accountEmail = await auth.verifyPasswordResetCode(oobCode)
        setEmail(accountEmail)
        setAuthStatus({...defaultStatus, expired: !accountEmail, loggedIn: Boolean(accountEmail)})
        return true
      } catch (e) {
        return handleError(e)
      }
    }
    const verifyEmail = async () => {
      try {
        await auth.applyActionCode(oobCode)
        setAuthStatus({...defaultStatus, done: true})
      } catch (e) {
        return handleError(e)
      }
    }
    const recoverEmail = async () => {
      try {
        const check = await auth.checkActionCode(oobCode)
        const restoredEmail = check['data']['email']
        setEmail(restoredEmail)
        setAuthStatus({...defaultStatus, loggedIn: true})
      } catch (e) {
        return handleError(e)
      }
    }
    if (oobCode && !authStatus.done) {
      mode === RESET_PASSWORD && getEmail()
      mode === VERIFY_EMAIL && verifyEmail()
      mode === RECOVER_EMAIL && recoverEmail()
    }
  }, [oobCode, authStatus, mode])

  const renderLoading = () => <LoadingSpinner/>

  const renderContent = (isDesktop) => <>
  
    {
      mode === RESET_PASSWORD && <ForgotPassword
        email={email}
        oobCode={oobCode}
        authStatus={authStatus}
        setAuthStatus={setAuthStatus}
        isDesktop={isDesktop}
      />
    }
    {
      mode === VERIFY_EMAIL && <VerifyEmail
        authStatus={authStatus}
      />
    }
    {
      mode === RECOVER_EMAIL && <RecoverEmail
        authStatus={authStatus}
        oobCode={oobCode}
        setAuthStatus={setAuthStatus}
        restoredEmail={email}
        isDesktop={isDesktop}
      />
    }
  </>

  return <>
    <BrowserView>
      <BowserContainer fluid>
        <PageRow>
          <Ladies src={ladiesLeftSrc} alt={i18n.image.emme_ladies_alt} />
          <LoginContainer lg={4} md={6} sm={10} xs={12}>
            
            {authStatus.loading ? renderLoading() : renderContent(true)}

            <Logo emmeStyle={{width: '122px', height: '28px'}} style={{marginTop: '30px'}}/>

          </LoginContainer>
          <Ladies src={ladiesRightSrc} alt={i18n.image.emme_ladies_alt} />
        </PageRow>

      </BowserContainer>
    </BrowserView>
    <MobileView>
      <MobileContainer>
        <Center>
          {authStatus.loading ? renderLoading() : renderContent(false)}
        </Center>

        <Logo emmeStyle={{width: '122px', height: '28px'}}/>
      </MobileContainer>
    </MobileView>
  </>
};

export default AuthActions
