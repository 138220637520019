import React from 'react'
import styled, { css } from 'styled-components'
import { Bold, Normal } from '../../../components/atoms/Fonts'
import styling from '../../../lib/styling'

const Container = styled.div`
  margin-bottom: 20px;
  ${props => props.scroll && css`
    overflow-x: scroll;
    overflow-y: hidden;
    margin-bottom: 40px;
  `}
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`
const Column = styled.div`
  text-align: center;
  overflow: hidden;
  ${props => props.num && css`
    width: ${100/props.num}%;
  `}
  ${props => props.minColWidth && css`
    min-width: ${props.minColWidth}px;
  `}
  ${props => props.onClick && css`
    cursor: pointer;
    &:hover {
      color: ${styling.colors.wine};
    }
  `}
`

const Table = ({
  header,
  rows,
  onHeaderClick,
  noClick = [],
  scroll,
  minColWidth,
}) => {
  return <Container scroll={scroll}>
    {
      header && <Row>
        {
          header.map(text => {
            const props = {
              num: minColWidth ? null : header.length,
              minColWidth,
            }
            if (onHeaderClick && !noClick.includes(text)) {
              props.onClick = () => onHeaderClick(text)
            }
            return <Column {...props} key={text}>
              <Bold>
                {text}
              </Bold>
            </Column>
          })
        }
      </Row>
    }
    {
      rows?.length > 0 && rows.map(({data, id}) => {
        const key = `row-${id}`
        return <Row key={key}>
          {
            data.map((text, index) => {
              return <Column
                num={minColWidth ? null : header.length}
                key={`${key}-${text}-${index}`}
                minColWidth={minColWidth}
              >
                <Normal>
                  {text}
                </Normal>
              </Column>
            })
          }
        </Row>
      })
    }
    {
      rows?.length === 0 && <Row>
        <Column num={1}>
          No date available.
        </Column>
      </Row>
    }
  </Container>
}

export default Table
