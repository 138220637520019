export default {
  EMME: 'https://emme.com/',
  EMME_CASE: 'https://emme.com/products/emme-smart-case',
  EMME_APP: 'https://emme.com/app',
  EMME_PILL_DELIVERY: 'https://emme.com/birth-control-pills',
  EMME_FACEBOOK: 'https://www.facebook.com/try.emme/',
  EMME_INSTAGRAM: 'https://www.instagram.com/meetemme/',
  EMME_APPLE_STORE: 'https://apps.apple.com/us/app/id1294152287',
  EMME_ANDROID_STORE: 'https://play.google.com/store/apps/details?id=com.emme.insights.android',
  HOME: '/',
  SUPPORT: 'support',
  RX_SUPPORT: 'rx_support',
  // MARKETING_TOOLS: 'marketing_tools',
  OWNER_TOOLS: 'owner_tools',
  SUPPORT_EMAIL: 'mailto:support@emme.com',
}