const GENDER = 'gen'
const AGE = 'age'
const PREGNANT = 'preg'
const TERMS = 'terms'
const CONSENT = 'consent'
const PRACTICES = 'practices'
const PRIVACY = 'privacy'

// database fields:
const FIRST_NAME = 'firstName'
const LAST_NAME = 'lastName'
const DOB = 'dob'
const STREET_1 = 'street1'
const STREET_2 = 'street2'
const CITY = 'city'
const COUNTRY = 'country'
const STATE = 'state'
const ZIP = 'zip'
const BIO_GENDER = 'biologicalGender'
const IS_PREGNANT = 'isPregnant'

const PACKS_PER_SHIPMENT = 'packsPerShipment'
const PILL_PACK = 'pillPack'
const MEMBERSHIP_TYPE = 'membershipType'

export default {
  PTYPE_TELEMED: 'telemed',
  PTYPE_RXTELEMED: 'rx-with-telemed',
  PTYPE_TEMP: 'temp-setup',
  INVOICE_TM: 'TM',
  INVOICE_RX: 'RX',

  EVENT_PURCHASE: 'purchase_event_success',
  EVENT_NEW_PURCHASE: 'purchase_available',
  EVENT_INTAKE_AVAILABLE: 'intakeform_received',
  EVENT_INTAKE_SUCCESS: 'intakeform_sent',
  EVENT_REVIEW_START: 'consult_start',
  EVENT_REVIEW_END: 'consult_finished',
  EVENT_REVIEW_ID_ERROR: 'consult_id_error',
  EVENT_REVIEW_REF_OUT: 'consult_refer_out',
  EVENT_REVIEW_AUTO_REF_OUT: 'consult_auto_refer_out',
  EVENT_REVIEW_DIAGNOSED: 'consult_diagnosed',

  EMME_STATUS_INTAKE_EXP: 'intakeFormExpired',
  EMME_STATUS_INTAKE_VOIDED: 'intakeFormVoided',

  PURCHASE: 'purchase',
  INTAKE: 'intake',
  REVIEW: 'review',
  RESULT: 'result',

  // Nav data fields
  GENDER,
  AGE,
  PREGNANT,
  TERMS,
  CONSENT,
  PRACTICES,
  PRIVACY,

  // database fields:
  FIRST_NAME,
  LAST_NAME,
  DOB,
  STREET_1,
  STREET_2,
  CITY,
  COUNTRY,
  STATE,
  ZIP,
  BIO_GENDER,
  IS_PREGNANT,
  PACKS_PER_SHIPMENT,
  PILL_PACK,
  MEMBERSHIP_TYPE,

  // nav <> db map
  DB_TO_NAV: {
    [FIRST_NAME]: FIRST_NAME,
    [LAST_NAME]: LAST_NAME,
    [DOB]: DOB,
    [BIO_GENDER]: GENDER,
  },

  // Address types
  ADDRESS_HOME: 'home',
  ADDRESS_SHIPPING: 'shipping',
  ADDRESS_BILLING: 'billing',

  // Checkout summary keys
  CHECKOUT_CONTACT: 'contact',
  CHECKOUT_SHIP_TO: 'ship_to',
  CHECKOUT_SUBSCRIPTION: 'subscription',

  // Subscriptions:
  DELIVERY_FREQUENCE_1: 1,
  DELIVERY_FREQUENCE_3: 3,
  DEFAULT_FREQUENCE: 3,
  PILLS_ONLY: 'pillPack',
  PILLS_CASE_COMBO: 'pillCaseCombo',

  // BILLING KEYS:
  BILL_TELEMED: 'telemed',
  BILL_SERVICE: 'service',
  BILL_SHIPPING: 'shipping',
  BILL_PILL_PACK: 'pillPack',
  BILL_PROMO_CODE: 'promoCode',

  // PAYMENT OPTIONS:
  PAY_CREDIT_CARD: 'creditCard',
  PAYMENT_TYPE: 'paymentType',
  TRANSACTION: 'transaction',

  // ORDER UPDATES:
  MORE_INTAKE: 'intake',
  MORE_SERVICE: 'service',
  MORE_MY_ORDER: 'myOrder',

  // BUTTONS:
  BUTTON_INTAKE_FORM: 'intake_form',
  BUTTON_SUMMARY: 'summary',
  BUTTON_TRACKING: 'tracking',
}