import support from './support'
import store from './store'
import account from './account'
import purchase from './purchase'
import image from './image'
import banner from './banner'
import errors from './errors'
import user from './user'
import validations from './validations'
import rx_support from './rx_dashboard'
import marketing from './marketing'
import owner from './owner'
import emme_home from './home'
import auth from './auth'

export default {
  support,
  store,
  account,
  purchase,
  image,
  banner,
  errors,
  user,
  auth,
  validations,
  rx_support,
  marketing,
  owner,
  emme_home,

  emme: 'Emme',
  emme_ecommerce_url: 'Emme.com',
  emme_support: 'support@emme.com',

  na: 'n/a',
  no_data: 'No data available',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',

  email: 'Email',
  password: 'Password',
  login: 'Log in',
  logout: 'Log out',
  contact_us: 'Contact us',

  logout_confirm_header: 'Are you sure you want to log out?',
  logout_confirm_text: 'Remember to log in with the same email.',

  link_emme_site: 'Emme site',
  link_case: 'The case',
  link_download_app: 'Download app',
  link_pill_delivery: 'The pills',

  footer_text_1: (year) => `©Emme™ ${year}. Women’s health in women’s hands.`,
  footer_text_2: 'All rights reserved.',

  dismiss: 'Dismiss',
  cancel: 'Cancel',
  confirm: 'Confirm',
  done: 'Done',
  sign_up: 'Sign up',
  continue: 'Continue',
  next: 'Next',
  change: 'Change',
  apply: 'Apply',
  edit: 'Edit',
  save: 'Save',
  remove: 'Remove',
  undo: 'Undo',
  move: 'Move',
  yes: 'Yes',
  no: 'No',

  rx_dashbaord: 'EmmeRX',
  customers: 'Customers',
  marketing_tools: 'Marketing Tools',
  owner_tools: 'Owner Tools',
  home: 'Home',

  loading: 'One moment! We are loading your data.',

  read_more: 'Read more',
  read_less: 'Read less',

  your_email: 'Your email address',
}
