import React from 'react'

import styled, { css } from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

import colors from '../../lib/styling/colors'
import i18n from '../../lib/i18n'

// Standard Link

const LinkStyle = styled.a`
  color: ${colors.blue};
  margin-bottom: 0;
  text-decoration: underline;
  cursor: pointer;
  ${props => props.align && css`
    text-align: ${props.align};
  `}
`
const LinkStyleButton = styled.button`
  background: none;
  border: 0;
  outline: none;
  color: ${colors.blue};
  cursor: pointer;
  &:active, &:focus, &:hover, &:visited {
    background: none;
    border: 0;
    outline: none;
  }
  ${props => props.underline && css`
    text-decoration: underline;
  `}
`

const AdjustButton = styled.div`
  ${props => props.align && css`
    text-align: ${props.align};
  `}
`

export const Link = ({
  children,
  onClick,
  to,
  disabled = false,
  style = {},
  ...props
}) => {
  const getLinkType = () => {
    const isEmail = to && to.includes('@')
    if (isEmail) return {
      isEmail,
      link: `mailto:${to}`,
    }
    const websites = [i18n.emme_ecommerce_url]
    const isHtml = to && (to.match(/.+\..+/) || to.match(/^http.+/) || websites.includes(to))
    if (isHtml) return {
      isHtml,
      link: to,
    }

    return {}
  }
  const linkType = to && getLinkType()
  if (to && !disabled && typeof(to) === 'string') {
    if (linkType.isEmail) {
      return <LinkStyle href={linkType.link} {...props}>{children}</LinkStyle>
    }
    if (linkType.isHtml) {
      // eslint-disable-next-line react/jsx-no-target-blank
      return <LinkStyle href={linkType.link} target="_blank" style={style} {...props}>{children}</LinkStyle>
    }
    return <RouterLink to={to} {...props} style={style}>{children}</RouterLink>
  }
  return <AdjustButton {...props} style={style}>
    <LinkStyleButton onClick={onClick} {...props} disabled={disabled}>{children}</LinkStyleButton>
  </AdjustButton>
};

// Special Links
const BigAnchorLink = styled.a`
  color: ${colors.wine};
  font-family: IntroBold;
  font-size: 32px;
  padding: 0;
  margin: 0;
  line-height: 2.15em;
  font-weight: 400;
  text-decoration: none;
  display: block;
  text-transform: capitalize;
  &:visited {
    color: ${colors.wine};
    text-decoration: none;
  }
  &:hover, &:active {
    color: ${colors.blue};
    text-decoration: none;
  }
`

export const BigLink = ((props) => {
  const {
    to,
    href,
    disabled,
    target = '_self',
    children,
    style = {},
    onClick,
  } = props
  if (to) return <BigAnchorLink
    href={to}
    disabled={disabled}
    style={style}
    target={target}
    onClick={() => {
      onClick && onClick()
    }}
  >{children}</BigAnchorLink>
  if (href) return <BigAnchorLink
    href={href}
    target={target}
    disabled={disabled}
    style={style}
  >{children}</BigAnchorLink>
  return null
})

export const NavAnchorLinks = styled.a`
  font-family: TradeGothic, sans-serif;
  font-weight: 700;
  color: ${colors.wine};
  text-decoration: none;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  padding: 0 5px;
  letter-spacing: 2px;
  &:visited {
    color: ${colors.wine};
    text-decoration: none;
  }
  &:hover, &:active {
    color: ${colors.terracotta};
    text-decoration: none;
    background: white;
    box-shadow: none;
    outline: none;
  }
`

export const NavLinks = ((props) => {
  const {
    to,
    href,
    disabled,
    target = '_self',
    children,
    style = {},
  } = props
  if (to) return <NavAnchorLinks
    href={to}
    disabled={disabled}
    style={style}
    target={target}
  >{children}</NavAnchorLinks>
  if (href) return <NavAnchorLinks
    href={href}
    target={target}
    disabled={disabled}
    style={style}
  >{children}</NavAnchorLinks>
  return null
})

const StyledIcon = styled.img`
  &:active, &:focus, &:hover, &:visited {
    opacity: 0.6;
  }
`

export const IconLink = ({
  href,
  disabled = false,
  src,
  alt = 'Button with icon',
  style = {},
  target = '_self',
}) => {

  return <a disabled={disabled} href={href} style={style} target={target}>
    <StyledIcon src={src} alt={alt} />
  </a>

}
