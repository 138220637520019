export default {
  home: '/',
  purchase: '/purchase',
  login: '/login',
  authActions: '/___actions/auth',
  support: '/support',
  rxSupport: '/rx_support',
  marketingTools: '/marketing_tools',
  ownerTools: '/owner_tools',
  wip: '/wip',
  emmeRx: 'order_status',
}