import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'

import * as auth from './auth'
import * as page from './page'
import * as newsletter from './newsletter'
import * as customer from './customer'
import * as emmeRxReports from './emmeRx/reports'
import * as settings from './settings'
import * as tasks from './tasks'
import * as owner from './owner'

/// PAGE
export const handleLoading = page.handleLoading
export const dismissError = page.dismissError
export const setBanner = page.setBanner
export const removeBanner = page.removeBanner

/// AUTH
export const login = auth.login
export const signUp = auth.signUp
export const changePassword = auth.changePassword
export const forgotPassword = auth.forgotPassword
export const resendVerify = auth.resendVerify
export const setUserRights = auth.setUserRights
export const removeTwoFactor = auth.removeTwoFactor
export const resendTwoFactor = auth.initTwoFactor
export const useGoogleOAuth = auth.useGoogleOAuth

/// OWNER
export const getAllInternalUser = owner.getAllInternalUser

/// CUSTOMER
export const getCustomerByEmail = customer.getCustomerByEmail
export const lookupCustomer = customer.lookupCustomer
export const deleteAccount = customer.deleteAccount
export const setLifetimeFree = customer.setLifetimeFree
export const updateEmail = customer.updateEmail

/// EmmeRX REPORTS
export const getMonthlyRxReport = emmeRxReports.getMonthlyRxReport // Delete when EmmeTool removes RX support
export const getAllUserComments = tasks.getAllUserComments // Delete when EmmeTool removes RX support

/// SETTINGS
export const getUserSettings = settings.getUserSettings
export const initUserSettings = settings.initUserSettings
export const updateUserSettings = settings.updateUserSettings
export const getUniversalSettings = settings.getUniversalSettings
export const getFeatureSettings = settings.getFeatureSettings
export const getUniversalOpenSettings = settings.getUniversalOpenSettings

/// NEWSLETTER
export const updateMarketingList = newsletter.updateMarketingList

