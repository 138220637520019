import { Container } from 'react-bootstrap'
import styled from 'styled-components'

const SingleColumnContainer = styled(Container)`
  margin: 0 auto;
`


export {
  SingleColumnContainer,
}