import React from 'react'

import styled, { css } from 'styled-components'

import selectedSrc from '../../assets/images/simpleIcons/icon-checkbox-checked.png'
import selectedBlueSrc from '../../assets/images/simpleIcons/icon-checkbox-blue-checked.png'
import unselectedSrc from '../../assets/images/simpleIcons/icon-checkbox-unchecked.png'
import unselectedBlueSrc from '../../assets/images/simpleIcons/icon-checkbox-blue-unchecked.png'

import { Normal } from './Fonts'

const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-bottom: 7px;
  align-items: center;
  ${props => props.disabled && css`
    &:hover {
      cursor: default;
    }
  `}
`
const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
  }
  ${props => props.blueIcon && props.disabled && css`
    padding: 2px; // TODO: get better blue icon at one point. they have a two pixel padding around them.
  `}
  ${props => props.disabled && css`
    &:hover {
      cursor: default;
    }
  `}
`

const Checkbox = ({
  label = '',
  onSelect,
  disabled,
  selected = false,
  style = {},
  labelStyle = {},
  icons = {},
}) => {
  const renderLabel = typeof label === 'string' ?
    <Normal style={labelStyle} align="left">{label}</Normal>
    : label

  const { selected: selectedIcon, unselected: unselectedIcon, color, style: iconStyle, styleDisabled } = icons

  const blueIcon = color === 'blue'

  const fallbackSelected = blueIcon && !disabled ? selectedBlueSrc : selectedSrc
  const fallbackUnselected = blueIcon && !disabled ? unselectedBlueSrc : unselectedSrc

  const styleVals = {
    ...disabled ? styleDisabled || {} : iconStyle || {}
  }

  const renderIcon = (src) => {
    return <Icon src={src} style={styleVals} blueIcon={blueIcon} disabled={disabled} />
  }

  return <ItemContainer
    onClick={() => disabled ? {} : onSelect(!selected)}
    style={style}
    disabled={disabled}
  >
    {
      selected ?
        renderIcon(selectedIcon || fallbackSelected)
        :
        renderIcon(unselectedIcon || fallbackUnselected)
    }
    {renderLabel}
  </ItemContainer>
}

export default Checkbox
