const CHECKOUT = 'checkout'

export default {
  VIEW: 'webview',
  VIEW_KEY: 'page',
  VIEW_LANDING: 'landing_page',
  VIEW_ONBOARDING: 'onboarding',
  SOURCE: 'source',
  INIT_SOURCE: 'initial_source',
  WEB_CREATE: 'web_first_account',
  NEW_WEB_SESSION: 'new_web_session',
  NEW_PURCHASE: 'new_purchase',
  FIRST_LOGIN: 'first_web_login',
  TM_RX_ONBOARDING: 'tm_rx_onboarding',
  RX_INSURANCE: 'rx_interested_in_insurance',
  RX_STATE: 'rx_interested_in_state',
  RX_INTEREST: 'rx_interest',
  RX_MEMBERSHIP_TYPE: 'rx_membership_type',
  FINISHED: 'finished',
  STATE: 'state',
  INSURANCE: 'insurance',
  TM_RX_ONBOARDING_STATUS: 'tm_rx_onboarding_status',
  CHECKOUT,
  BEFORE_CHECKOUT: 'before_checkout',
  IN_CHECKOUT: 'in_checkout',
  DONE_CHECKOUT: 'done_checkout',
  PURCHASED_MEMBERSHIP: 'purchased_membership',
  FINISHED_ONBOARDING_STEP: 'finished_onboarding_step',
  TM_RX_ONBOARDING_STEPS: {
    0: 'checkout_info',
    1: 'available_states',
    2: 'quick_questions',
    3: 'phone_number',
    4: 'shipping_frequency',
    5: CHECKOUT,
  },
  BREADCRUMB_STEP_NAMES: {
    checkout: {
      0: 'checkout_information',
      1: 'checkout_subscription',
      2: 'checkout_payment',
    }
  },
  STATE_SELECTED: 'onboarding_state_select',
  DISCOUNT_CODE_APPLIED: 'discount_code_applied',
  DISCOUNT_CODE_REMOVED: 'discount_code_removed',
}