import React, { useState } from 'react'
import styled from 'styled-components'
import { Bold, Normal } from '../../../components/atoms/Fonts'
import styling from '../../../lib/styling'
import { isEmpty } from '../../../lib/utils'

const Container = styled.div`
  margin-bottom: 15px;
`
const DateEventsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`
const Date = styled.div``
const DateEvents = styled.div`
  display: flex;
  flex-direction: column;
`
const EventRow = styled.div`
  display: flex;
  flex-direction: row;
`
const EventData = styled.div`
  width: 33.33%;
`

const ShowMore = styled.div`
  width: 100%;
  padding: 5px 20px;
  background: ${styling.colors.pink};
  color: ${styling.colors.wine};
`
const SubHeader = styled.div`
  color: ${styling.colors.pink};
  padding: 2px 5px;
  border-radius: 5px;
  font-weight: bold;
`

const RxOrderUpdates = ({
  updates,
  title,
  maxNumShown = 5,
}) => {
  const [showAll, setShowAll] = useState(false)
  const eventDates = Object.keys(updates).sort((a, b) => (a > b ? -1 : 1)) || []
  const shownDates = showAll ? eventDates : eventDates.slice(0, maxNumShown)
  const hasMore = eventDates.length > shownDates.length
  const renderRow = ({
    uid,
    message,
    data,
  }, key) => {
    const props = {}
    if (key) props.key = key
    return <EventRow {...props}>
      {
        uid ? <EventData><Normal>{uid}</Normal></EventData> : <EventData/>
      }
      {
        message ? <EventData><Normal>{message}</Normal></EventData> : <EventData/>
      }
      {
        data ? <EventData><Normal>{JSON.stringify(data)}</Normal></EventData> : <EventData/>
      }
    </EventRow>
  }

  if (isEmpty(updates)) return <></>

  return <Container>
    <SubHeader>
      {title}
    </SubHeader>
    {
      shownDates.map(date => {
        const update = updates[date]
        return <DateEventsContainer key={date}>
          <Date>
            {date}
            {
              update?.length === 0 && <Normal>No events found</Normal>
            }
            <DateEvents>
              {
                update?.length > 0 && update.map((updateData, index) => {
                  const {
                    uid,
                  } = updateData

                  if (index === 0) {
                    return <div key={`${date}-${uid}`}>
                      <EventRow>
                        <EventData><Bold>UID</Bold></EventData>
                        <EventData><Bold>Message</Bold></EventData>
                        <EventData><Bold>MetaData</Bold></EventData>
                      </EventRow>
                      {
                        renderRow(updateData)
                      }
                    </div>
                  }
                  return renderRow(updateData, `${date}-${uid}`)
                })
              }
            </DateEvents>
          </Date>
        </DateEventsContainer>
      })
    }
    {
      hasMore && <ShowMore
        onClick={() => setShowAll(!showAll)}
      >
        {showAll ? 'Show less' : 'Show all'}
      </ShowMore>
    }
  </Container>
}

export default RxOrderUpdates
