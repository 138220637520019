import React from 'react'

import i18n from '../../../lib/i18n'

import { Longbutton } from '../../atoms/Buttons'
import { Normal } from '../../atoms/Fonts'

import { Help, LinkButton } from './shared'


const ConfirmEmail = ({
  setConfirmEmail,
  resendVerify,
  confirmEmail,
  auth,
}) => {
  return <>
    <Normal>
      {i18n.account.email_verify}
    </Normal>
    <Longbutton
      style={{
        marginBottom: '10px',
        marginTop: '20px',
      }}
      onClick={() => {
        // TODO: this needs work
        window.location.reload()
        if (auth.emailVerified && confirmEmail) setConfirmEmail(false)
      }}
    >
      {i18n.continue}
    </Longbutton>
    <LinkButton
      onClick={() => {
        resendVerify(auth.email)
      }}
    >
      {i18n.account.send_verify_again(auth.email)}
    </LinkButton>
    <Help />
  </>
}

export default ConfirmEmail
