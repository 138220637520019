import constants from '../constants'

const {
  FIRST_NAME,
  LAST_NAME,
  STREET_1,
  STREET_2,
  CITY,
  COUNTRY,
  STATE,
  ZIP,
} = constants.purchase

export default {
  email: 'Email',
  [FIRST_NAME]: 'First Name',
  [LAST_NAME]: 'Last Name',
  [STREET_1]: 'Street address',
  [STREET_2]: 'Apt #, Floor, etc.',
  [CITY]: 'City',
  [COUNTRY]: 'Country/Region',
  [STATE]: 'State',
  [ZIP]: 'Zip code',
}