import React from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom"
import { useSelector } from 'react-redux'

import { selectors } from '../store'

import CSHomePage from '../pages/CSHomePage'
import HomePage from '../pages/HomePage'
import TasksPage from '../pages/TasksPage'
import UsersPage from '../pages/UsersPage'
import SupportPage from '../pages/SupportPage'
import RxSupportPage from '../pages/RxDashboard'
import OwnerTools from '../pages/OwnerTools'

import constants from '../lib/constants'
import routes from './routes'

const {
	RIGHTS_OWNER,
	RIGHTS_CUSTOMER_SUPPORT,
} = constants.support

const RootRouter = () => {
	const authClaims = useSelector(selectors.authClaims)
	const userSettings = useSelector(selectors.userSettings)
	const allowRouting = userSettings && !userSettings.twoFactor
	const supportUser = authClaims[RIGHTS_OWNER] || authClaims[RIGHTS_CUSTOMER_SUPPORT]

	const home = supportUser ? CSHomePage : HomePage
	return <Router>
		{
			supportUser && allowRouting && <>
				<Route path='/tasks' component={TasksPage} />
				<Route path='/users' component={UsersPage} />
				<Route exact path={routes.support} component={SupportPage} />
				<Route exact path={routes.rxSupport} component={RxSupportPage} />
				<Route exact path={routes.ownerTools} component={OwnerTools} />
			</>
		}
		<Route exact={allowRouting} path={routes.home} component={home} />
	</Router>
}

export default RootRouter
