import React, { useState } from 'react'
import { selectors } from '../../store'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import styled, { css } from 'styled-components'

// lib
import i18n from '../../lib/i18n'
import { useWindowDimensions } from '../../lib/utils/windowDimensions'
import constants from '../../lib/constants'

// component
import { NavLinks, BigLink } from '../atoms/Links'
import { Longbutton, IconButton, BigLinkButton } from '../atoms/Buttons'
import SideSlider from '../molecules/SideSlider'
import Confirmation from '../molecules/Confirmation'
// icon
import logoSrc from '../../assets/images/Logo.png'
import menuSrc from '../../assets/images/icon-hamburger.png'
import keySrc from '../../assets/images/illustrations/flying-key.png'

const {
	RIGHTS_OWNER,
	RIGHTS_CUSTOMER_SUPPORT,
} = constants.support

const Container = styled.div`
	z-index: 100;
	${props => props.height && css`
   	height: ${props.height}px;
  `}
`

const Navbar = styled(Row)`
	background: white;
	padding: 25px 20px;
	width: 100%;
	${props => props.md && css`
    padding: 20px 20px;
  `}
  ${props => props.xs && css`
    padding-top: 45px;
  `}
	${props => props.height && css`
    height: ${props.height}px;
  `}
  ${props => props.width && css`
    width: ${props.width}px;
  `}
	box-shadow: 4px 2px 4px 1px rgba(0, 0, 0, 0.25);
	position: absolute;
  z-index: 101;
`

const HeaderCol = styled(Col)`
	display: flex;
	${props => props.alignment && props.alignment.horizontal && css`
    justify-content: ${props.alignment.horizontal};
  `}
	${props => props.alignment && props.alignment.vertical && css`
    align-items: ${props.alignment.vertical};
  `}
`

const Logo = styled.img`
	height: 20px;
`

export default function MainHeader({
	isInternal,
	showBasic,
	authClaims,
}) {
	const auth = useSelector(selectors.auth)
	const userSettings = useSelector(selectors.userSettings)
	const hideNav = userSettings && userSettings.twoFactor
	const winDimensions = useWindowDimensions()

	const [showSideMenu, setShowSideMenu] = useState(false)
	const [showLogoutDialog, setShowLogoutDialog] = useState(false)

	const {
		isMdOrSmaller,
		isLgOrBigger,
		isSmOrSmaller,
		isXS,
		width,
		isMobile,
	} = winDimensions

	const {
		EMME,
		EMME_CASE,
		HOME,
		SUPPORT,
		RX_SUPPORT,
		SUPPORT_EMAIL,
		OWNER_TOOLS,
	} = constants.links

	const leftSideNav = ['site']

	const internalLinks = [
		{
			text: i18n.home,
			to: HOME,
			key: 'home',
		},
		{
			text: i18n.rx_dashbaord,
			to: RX_SUPPORT,
			key: 'rx_support',
		},
	]

	if (authClaims && (authClaims[RIGHTS_OWNER] || authClaims[RIGHTS_CUSTOMER_SUPPORT])) {
		internalLinks.push({
			text: i18n.customers,
			to: SUPPORT,
			key: 'customer',
		})
	}

	if (authClaims && (authClaims[RIGHTS_OWNER])) {
		internalLinks.push({
			text: i18n.owner_tools,
			to: OWNER_TOOLS,
			key: 'owner',
		})
	}

	const userLinks = [
		{
			text: i18n.link_emme_site,
			href: EMME,
			key: 'site',
		},
		{
			text: i18n.link_case,
			href: EMME_CASE,
			key: 'case',
		},
		{
			text: i18n.contact_us,
			href: SUPPORT_EMAIL,
			key: 'support',
		},
	]

	const basicLinks = []

	const navLinks = isInternal ?
		internalLinks
		:
		showBasic ?
			basicLinks
			:
			userLinks

	const navLinksLeft = hideNav ? [] : navLinks.filter(nav => leftSideNav.includes(nav.key))
	const navLinksRight = hideNav ? [] : navLinks.filter(nav => !leftSideNav.includes(nav.key))

	const logout = () => {
		firebase.auth().signOut()
		setShowLogoutDialog(false)
	}

	if ((!auth || !auth.email) && !showBasic) return <></>

	return <Container height={isXS ? 120 : isMdOrSmaller ? 65 : 85}>
		<Navbar
			height={isXS ? 95 : isMdOrSmaller ? 55 : 76}
			xs={isXS}
			md={isMdOrSmaller}
			width={isMobile ? width + 15 : width + 25}
		>
			{
				isMdOrSmaller ?
					<HeaderCol
						alignment={{
							horizontal: "flex-start"
						}}
					>
						{
							auth.email && <IconButton
								onClick={() => setShowSideMenu(true)}
								src={menuSrc}
								alt={i18n.image.menu_alt}
							/>
						}
					</HeaderCol>
					:
					!isInternal ?
						<>
							<HeaderCol
								alignment={{
									horizontal: "space-between"
								}}
								lg={{ offset: 1, span: 5 }}
							>
								{
									navLinksLeft.map(({
										key,
										text,
										href,
										to,
									}) => {
										return <NavLinks
											key={key}
											href={href}
											to={to}
											target={to ? '_self' : '_blank'}
										>
											{text}
										</NavLinks>
									})
								}
							</HeaderCol>
						</>
						:
						<Col />
			}

			<HeaderCol alignment={{ horizontal: 'center' }}>
				<Logo src={logoSrc} alt={i18n.image.logo_alt} />
			</HeaderCol>
			{
				isLgOrBigger ?
					<HeaderCol
						lg={5}
						alignment={{
							horizontal: "flex-end"
						}}
					>
						{
							navLinksRight.map(({
								key,
								text,
								href,
								to,
							}) => {
								return <NavLinks
									key={key}
									href={href}
									to={to}
									target={to ? '_self' : '_blank'}
									style={{ marginLeft: '10px' }}
								>
									{text}
								</NavLinks>
							})
						}
						{
							auth.email && <Longbutton
								width="60px"
								onClick={() => setShowLogoutDialog(true)}
								style={{ marginLeft: '10px' }}
								narrowButton
							>
								{i18n.logout}
							</Longbutton>
						}
					</HeaderCol>
					:
					<Col />
			}
		</Navbar>
		{
			showSideMenu && auth.email && <SideSlider
				width={isSmOrSmaller ? '100%' : '50%'}
				withOverlay={!isSmOrSmaller}
				onClose={() => setShowSideMenu(false)}
				style={{
					zIndex: 105,
				}}
			>
				{
					navLinks.map(({
						key,
						text,
						href,
						to,
					}) => {
						return <div >
							<BigLink
								key={key}
								to={to}
								href={href}
								target={to ? '_self' : '_blank'}
								style={{
									textAlign: 'center',
								}}
							>
								{text}
							</BigLink>
						</div>
					})
				}
				<BigLinkButton
					onClick={() => {
						setShowLogoutDialog(true)
						setShowSideMenu(false)
					}}
					style={{
						margin: '40px auto',
					}}
				>{i18n.logout}</BigLinkButton>
			</SideSlider>
		}
		{
			showLogoutDialog && <Confirmation
				cancelAction={() => setShowLogoutDialog(false)}
				confirmAction={logout}
				confirmText={i18n.logout}
				iconSrc={keySrc}
				iconAlt={i18n.image.key_alt}
				headerText={i18n.logout_confirm_header}
				infoText={i18n.logout_confirm_text}
			/>
		}
	</Container>
}
