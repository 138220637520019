import { DateTime } from 'luxon'

export const cleanDate = (date) => {
  if (date._convertedPureDate) date = date._convertedPureDate
  if (date._convertedDateTime) date = date._convertedDateTime

  return DateTime.fromISO(date)
}

export const formateDate = (date, format = DateTime.DATE_FULL) => date.toLocaleString(format)
export const formatTime = (time, format = DateTime.TIME_SIMPLE) => time.toLocaleString(format)
export const formatDateTime = (time, format = DateTime.DATETIME_FULL) => time.toLocaleString(format)
export const toDateKey = (date) => date.toISODate()
export const today = DateTime.local()

export const inPast = (dateStr) => {
  return dateStr < new Date().toISOString()
}

export const inFuture = (dateStr) => {
  return dateStr > new Date().toISOString()
}

export const toDateString = (dateTimeStr) => {
  const matcher = dateTimeStr.match(/(.+)T.+/)
  return matcher ? matcher[1] : dateTimeStr
}

export const date1BeforeDate2 = (date1, date2) => {
  const clean1 = new Date(date1)
  const clean2 = new Date(date2)
  return clean1 < clean2
}