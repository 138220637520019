import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import config from "../config";

const firebaseConfig = {
	"apiKey": config.FIREBASE_APIKEY,
	"authDomain": config.FIREBASE_AUTHDOMAIN,
	"databaseURL": config.FIREBASE_DATABASEURL,
	"projectId": config.FIREBASE_PROJECTID,
	"storageBucket": config.FIREBASE_STORAGEBUCKET,
	"messagingSenderId": config.FIREBASE_MESSAGESENDERID,
	"appId": config.FIREBASE_APPID
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
if (process.env.NODE_ENV === 'development') {
	firebase.functions().useFunctionsEmulator('http://localhost:5001');
} else {
	firebase.functions();
}


export default firebase;