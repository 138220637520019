import React from 'react'
import styled from 'styled-components'
import i18n from '../../lib/i18n'
import { useSelector } from 'react-redux'
import {
  selectors,
} from '../../store'

import LabelValuePair from '../../components/molecules/LabelValuePair'

const Container = styled.div`
  padding: 20px;
`

const GeneralAdvice = () => {
  const universalSettings = useSelector(selectors.universalSettings)
  const { android, ios } = universalSettings?.deployedVersions || {}
  return <Container>
    <LabelValuePair
      label={i18n.support.general_android_app_version}
      value={android}
    />
    <LabelValuePair
      label={i18n.support.general_ios_app_version}
      value={ios}
    />
    <LabelValuePair
      label={i18n.support.general_google_service}
      value={i18n.support.general_google_service_url}
      isLink={true}
    />
  </Container>
}

export default GeneralAdvice
