import React from 'react'
import styled from 'styled-components'

import styling from '../../lib/styling'
import i18n from '../../lib/i18n'

import closeSrc from '../../assets/images/icon-close.png'
import logoSrc from '../../assets/images/Logo-white.png'

import { IconButton } from '../atoms/Buttons'
import Overlay from '../atoms/Overlay'
import SideMenuFooter from './SideMenuFooter'

const Slider = styled.div(({
  width,
  top,
}) => {
  return `
    width: ${width};
    height: 100%;
    background: ${styling.colors.terracotta};
    position: absolute;
    bottom: 0;
    top: ${top}px;
    z-index: 100;
    overflow-x: none;
    overflow-y: auto;
  `
})

const SliderHeader = styled.div(({
  width
}) => {
  return `
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    width: ${width};
    z-index: 101;
    background: ${styling.colors.terracotta};
  `
})
const LogoImg = styled.img`
  width: 100px;
`

const SliderBody = styled.div`
  padding: 20px;
  margin-top: 60px;
`

const SliderFooter = styled.div(({
  width
}) => {
  return `
    display: flex;
    padding-bottom: 20px;
    align-items: center;
    justify-content: center;
  `
})

const SideSlider = ({
  onClose,
  width = '50%',
  top = 0,
  children,
  withOverlay = false,
  style = {},
}) => {
  const renderOverlay = () => {
    if (onClose) return <Overlay onClick={onClose} />
    return <Overlay />
  }
  return <>
    {
      withOverlay && renderOverlay()
    }
    <Slider
      width={width}
      top={top}
      style={style}
    >
      <SliderHeader width={width}>
        {
          onClose && <IconButton
            onClick={onClose}
            src={closeSrc}
            alt={i18n.image.close_alt}
            style={{
              position: 'absolute',
              left: '20px'
            }}
          />
        }
        <LogoImg src={logoSrc} alt={i18n.image.logo_alt} />
      </SliderHeader>
      <SliderBody>
        {children}
      </SliderBody>

      <SliderFooter width={width}>
        <SideMenuFooter />
      </SliderFooter>

    </Slider>
  </>
}

export default SideSlider