import React, { useState, useEffect } from 'react'
import 'firebase/auth'
import firebase from 'firebase/app'

import '../../services/firebase'
import i18n from '../../lib/i18n'

import { Longbutton } from '../../components/atoms/Buttons'
import { defaultStatus, HeaderSection, Info } from '.'
import { Link } from '../../components/atoms/Links'


const auth = firebase.auth();

const RecoverEmail = ({
  authStatus,
  oobCode,
  setAuthStatus,
  restoredEmail,
  isDesktop,
}) => {
  const [email, setEmail] = useState(restoredEmail)

  useEffect(() => {
    if (restoredEmail) setEmail(restoredEmail)
  }, [restoredEmail])

  const resetPassword = async () => {
    try {
      setAuthStatus({...defaultStatus, loading: true})
      await auth.applyActionCode(oobCode)
      await auth.sendPasswordResetEmail(email)
      setAuthStatus({...defaultStatus, done: true})
    } catch (e) {}
  }
 

  const renderExpired = () => <>
    <Info>{i18n.auth.recover_email_timeout}</Info>
  </>

  const renderDone = () => <>
    <Info>{i18n.auth.recover_email_done} <Link to={i18n.account.support_email_address}>
      {i18n.auth.customer_service}
    </Link></Info>
  </>

  const renderRecoverEmail = () => <>
    <Info>{i18n.auth.recover_email_success_info}</Info>
    <Longbutton
      onClick={resetPassword}
      maxWidth={isDesktop ? '330px' : null}
    >
      {i18n.auth.recover_email_button}
    </Longbutton>
  </>

  return <>
    <HeaderSection
      isDone={authStatus.done}
      text={authStatus.loggedIn ? 
        i18n.auth.recover_email_success_header(email)
        : 
        authStatus.done ? 
          i18n.auth.recovered_email_success_header(email)
          :
          i18n.auth.recover_email_issue_header
      }
    />

    {
      authStatus.expired && renderExpired()
    }
    {
      authStatus.done && renderDone()
    }
    {
      authStatus.loggedIn && renderRecoverEmail()
    }
  </>

};

export default RecoverEmail
