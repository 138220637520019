import React from 'react'
import styled from 'styled-components'

import styling from '../../../lib/styling'

const ValRowConainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px ${styling.colors.lightgrey} dotted;
`
const ValRowLabel = styled.div`
  color: ${styling.colors.wine};
  font-weight: bold;
  font-size: 12px;
  padding-right: 5px;
  width: 30%;
`
const ValRowText = styled.div`
  font-size: 12px;
  width: 70%;
  overflow-wrap: break-word;
`
const ValRowLink = styled.a`
  font-size: 12px;
  width: 70%;
  overflow-wrap: break-word;
`

const ValRow = (({
  label,
  value,
  linkTo,
  shownSymbol = <></>,
  style = {},
}) => {
  return <ValRowConainer style={style}>
    <ValRowLabel>
      {label}:
    </ValRowLabel>
    {
      linkTo ?
        <ValRowLink href={linkTo} target="_blank">
          {value}
        </ValRowLink>
        :
        <ValRowText>
          {value} {shownSymbol}
        </ValRowText>
    }
  </ValRowConainer>
})

export default ValRow
