import React, { useState } from 'react'
import styled from 'styled-components'

import utils from '../../../lib/utils'

import Dropdown from '../../../components/atoms/Dropdown'

const SelectorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`

const YearMonthSelector = (({
  startYearMonth = {},
  endYearMonth = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
  },
  onSelect,
  selected,
}) => {
  const [yearMonthList, setYearMonthList] = useState([])

  utils.useEffectOnce(() => {
    const list = []

    const lastYear = startYearMonth.year
    const lastMonth = startYearMonth.month
    const currentYear = endYearMonth.year
    const currentMonth = endYearMonth.month
    let year = lastYear

    while (year <= currentYear) {
      let month = year === lastYear ? lastMonth : 1
      const endMonth = year === currentYear ? currentMonth : 12

      while (month <= endMonth) {
        list.push({
          label: `${month}/${year}`,
          value: { year, month },
        })
        month++
      }
      year++
    }
    const sortedList = list.reverse()
    setYearMonthList(sortedList)
    onSelect(sortedList[0].value)
  })

  return <SelectorContainer>
    <Dropdown
      list={yearMonthList}
      selected={selected}
      onChange={(val) => onSelect(val)}
    />
  </SelectorContainer>
})

export default YearMonthSelector