import React from 'react'
import UserCard from './UserCard'

const UserList = ({ users }) => {

	if (!users) {
		return null;
	}
	return (
		<div>
			{users.map((user, i) => {
				return (
					<UserCard user={user} key={i} />
				)
			})}
		</div>
	)
}

export default UserList