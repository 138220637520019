import React from 'react'

import { Alert as BootstrapAlert } from 'react-bootstrap'

export const ALERT_TYPES = {
  WARNING: 'warning',
  SUCCESS: 'success',
}

const Alert = ({
  children,
  type,
  onClose,
}) => {
  if (onClose) {
    return <BootstrapAlert
      variant={type}
      dismissible
      onClose={onClose}
    >
    { children }
  </BootstrapAlert>
  }

  return <BootstrapAlert variant={type}>
    { children }
  </BootstrapAlert>
}

export default Alert